
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { UploadFile } from '../../../services/UploadFile';
import { HtmlValMsg } from '../../../Inputs/TextInput';
import { PostData } from '../../../services/PostData';
import { LoadDetails } from '../../../services/LoadDeatails';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LoadList } from '../../../services/LoadList';
import { LoaderSmall } from '../../..';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const reg = /<(.|\n)*?>/g;

function Galleryform({ popuptype, newsid, loadGallery }) {
    const [Title, setTitle] = useState("")
    const [details, setDetails] = useState("")
    const [contentimg, setContentimg] = useState("")
    const [Image, setImage] = useState("")
    const [isPopupLoaded, setIsPopupLoaded] = useState(false);

    const onAboutDetails = (value) => {
        setDetails(value)
    }

    useEffect(() => {
        clearFields()
        setIsPopupLoaded(true)
    }, []);


    const clearFields = () => {
        setTitle('')
        setImage('')
        setContentimg("")
    }

    const handleImage = (e) => {
        setContentimg(e.target.value)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please select image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    var changedImageUrl = res.filepath
                    setImage(changedImageUrl)
                }
            }).catch()
        } catch (error) {

        }
    }

    const Submit_Save = (e) => {
        if (Title.trim() === "") {
            return toast.error("Please enter title");
        }
        if (reg.test(Title) === true) {
            return toast.error(HtmlValMsg);
        }
        if (Image === "") {
            return toast.error("Please select image");
        }
        else {

            AddImage();
        }
    }

    const AddImage = async () => {
        try {

            const req = {
                NewsId: 0,
                Name: Title.trim(),
                Image: Image
            };
            PostData(req, "image/add").then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    window.$("#galleryformAdd").modal('hide');
                    clearFields();
                    loadGallery();
                }
            })
                .catch()
        } catch (error) {

        }
    }



    return (
        <>
            <div className="modal-dialog" style={{ width: "50%" }}>
                <div className="modal-content" style={{ height: "50%" }}>

                    <div className="modal-header">
                        <button type="button" onClick={clearFields} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">Add Image</h4>
                    </div>
                    {isPopupLoaded === false ? <LoaderSmall />
                        :
                        <div className="modal-body">
                            <form role="form" className="newsform">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Title *</label>
                                        <input maxLength={100} type="text" className="form-control" value={Title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter title" />
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>Image</label>
                                            <input type="file" className="form-control" onChange={(e) => handleImage(e)} value={contentimg} />
                                        </div>
                                        {Image
                                            &&
                                            <div className="col-md-6">
                                                <img src={Image} width="150px" />
                                            </div>
                                        }
                                    </div>

                                </div>
                            </form>
                        </div>

                    }

                    <div className="modal-footer">
                        <button type="button" onClick={clearFields} className="btn btn-default btn-sm pull-left" data-dismiss="modal">Close</button>
                        <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Save}>Add</button>
                    </div>
                </div>

            </div>
        </>

    )
}

export default Galleryform

