import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { CreateSession, LoadSession, UpdateSession } from '../../../services/Event'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from 'moment'
import { HtmlValMsg } from '../../../Inputs/TextInput';
const reg = /<(.|\n)*?>/g;



const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

function Sessionform({ eventid, dateid, date, popuptype, sessionid, loadSubevent }) {

    const [title, setTitle] = useState("")
    const [sessiondate, setSessionDate] = useState("")
    const [starttime, setStartTime] = useState("")
    const [endtime, setEndTime] = useState("")
    const [timing, setTiming] = useState("")
    const [timezone, setTimezone] = useState("")
    const [type, setType] = useState("")
    const [room, setRoom] = useState("")
    const [details, setDetails] = useState("")
    const [sequence, setSequence] = useState("")
    const [webinarurl, setWebinarURL] = useState("")
    const [livestreamurl, setLivestreamURL] = useState("")

    useEffect(() => {
        if (popuptype === "createsession")
            clearFields()
        if (popuptype === "editsession") {
            loaddetails()
        }
    }, [popuptype, sessionid]);

    const loaddetails = () => {
        LoadSession(sessionid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else {
                    setTitle(result.data.Title);
                    setStartTime(result.data.StartTime)
                    setEndTime(result.data.EndTime)
                    setTiming(result.data.Timing)
                    setTimezone(result.data.TimeZone)
                    setType(result.data.Type)
                    setRoom(result.data.Room)
                    setDetails(result.data.Details);
                    setSequence(result.data.Sequence);
                    setWebinarURL(result.data.WebinarURL);
                    setLivestreamURL(result.data.LiveStreamURL);
                    var obj_date = result.data.SessionDate;
                    obj_date = moment(new Date(obj_date)).format("YYYY-MM-DD")
                    setSessionDate(obj_date)
                }
            }
            else {
            }
            //setIsLoaded(true);
        }).catch();
    }

    const clearFields = () => {
        setTitle('')
        setStartTime('')
        setEndTime('')
        setTiming('')
        setTimezone('')
        setType('Select')
        setRoom('')
        setDetails('')
        setSequence('')
        setWebinarURL('')
        setLivestreamURL('')
    }

    const onDetails = (value) => {
        setDetails(value);
    }


    const Submit_Save = (e) => {
        if (title.trim() === "") {
            return toast.error("Please enter title");
        }
        if (reg.test(title) === true) {
            return toast.error(HtmlValMsg);
        }
        if (starttime === "") {
            return toast.error("Please Enter start time");
        }
        if (reg.test(starttime) === true) {
            return toast.error(HtmlValMsg);
        }
        if (endtime === "") {
            return toast.error("Please Enter end time");
        }
        if (reg.test(endtime) === true) {
            return toast.error(HtmlValMsg);
        }
        if (timing.trim() === "") {
            return toast.error("Please enter timing");
        }
        if (reg.test(timing) === true) {
            return toast.error(HtmlValMsg);
        }

        else {
            AddSessions();
        }
    }

    const AddSessions = async () => {

        try {
            const req = {
                dateid: dateid,
                sessiondate: moment(new Date(date)).format("YYYY-MM-DD") + " " + starttime,
                starttime: starttime,
                endtime: endtime,
                timing: timing.trim(),
                timezone: timezone.trim(),
                title: title.trim(),
                type: type,
                sequence: sequence,
                room: room,
                webinarurl: webinarurl,
                livestreamurl: livestreamurl,
                details: details.trim()
            };
            CreateSession(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#sessionform").modal('hide');
                    loadSubevent(eventid);
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        if (title.trim() === "") {
            return toast.error("Please enter title");
        }
        if (reg.test(title) === true) {
            return toast.error(HtmlValMsg);
        }
        if (starttime === "") {
            return toast.error("Please Enter start time");
        }
        if (reg.test(starttime) === true) {
            return toast.error(HtmlValMsg);
        }
        if (endtime === "") {
            return toast.error("Please Enter end time");
        }
        if (reg.test(endtime) === true) {
            return toast.error(HtmlValMsg);
        }
        if (timing.trim() === "") {
            return toast.error("Please enter timing");
        }
        if (reg.test(timing) === true) {
            return toast.error(HtmlValMsg);
        }

        else {

            UpdateSessions();

        }
    }

    const UpdateSessions = async () => {
        try {

            const req = {
                sessionid: sessionid,
                sessiondate: moment(new Date(sessiondate)).format("YYYY-MM-DD") + " " + starttime,
                starttime: starttime,
                endtime: endtime,
                timing: timing.trim(),
                timezone: timezone,
                title: title.trim(),
                type: type,
                sequence: sequence,
                room: room,
                webinarurl: webinarurl,
                livestreamurl: livestreamurl,
                details: details
            };
            UpdateSession(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#sessionform").modal('hide');
                    loadSubevent(eventid);
                }
            })
                .catch()
        } catch (error) {

        }
    }

    useEffect(() => {
        if (room !== "")
            setLivestreamURL("https://saroforums2021.org/#/live-" + room + "/" + eventid)
        else
            setLivestreamURL("")
    }, [room])

    return (
        <>
            <div id="sessionform" className="modal fade" style={{ height: "100%" }}>
                <div className="modal-dialog" style={{ width: "50%" }}>
                    <div className="modal-content" style={{ height: "50%" }}>
                        <div className="modal-header">
                            {popuptype === "editsession" ? <div className="col-md-6"><h3>Edit Session</h3></div> : <div className="col-md-6"><h3>Add Session</h3></div>}
                            {/* <div className="closeright"><a onClick={closepopup} aria-label="Close"><img src="images/close.svg" /></a></div> */}
                            <div className="closeright"><a type="button" data-dismiss="modal" onClick={clearFields} aria-label="Close"><img src="images/close.svg" /></a></div>
                        </div>
                        <form role="form" className="sessionform">
                            <div className="box-body">
                                <div className="form-group">
                                    <label >Title*</label>
                                    <input maxLength={150} type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter Title" />
                                </div>
                                <div className="form-group">
                                    <label >Start Time*</label>
                                    <input type="time" className="form-control" value={starttime} onChange={(e) => setStartTime(e.target.value)} placeholder="Enter Start Time" />
                                </div>
                                <div className="form-group">
                                    <label >End Time*</label>
                                    <input type="time" className="form-control" value={endtime} onChange={(e) => setEndTime(e.target.value)} placeholder="Enter End Time" />
                                </div>
                                <div className="form-group">
                                    <label >Timings*</label>
                                    <input maxLength={50} type="text" className="form-control" value={timing} onChange={(e) => setTiming(e.target.value)} placeholder="Enter Timing" />
                                </div>

                                <div className="form-group" style={{ height: "320px" }}>
                                    <label >Details</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={details}
                                        onChange={onDetails}
                                        placeholder={"Write something..."}
                                        style={{ height: "250px" }}
                                    />
                                </div>

                                {/* <div className="form-group">
                                    <label >Sequence*</label>
                                    <input type="text" className="form-control" value={sequence} onChange={(e) => setSequence(e.target.value)}   placeholder="Enter Sequence" />
                                </div> */}
                                {/* 
                                <div className="form-group">
                                    <label >Type*</label>
                                    <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                                        <option value="Select">Select</option>
                                        <option value="Open">Open</option>
                                        <option value="Closed">Closed</option>
                                        <option value="Invite">Invite</option>
                                    </select>
                                </div> */}

                                {/* <div className="form-group">
                                    <label >Room</label>
                                    <select className="form-control" value={room} onChange={(e) => setRoom(e.target.value)}>
                                        <option value="0">Select Room</option>
                                        <option value="room1">Room 1</option>
                                        <option value="room2">Room 2</option>
                                        <option value="room3">Room 3</option>
                                        <option value="room4">Room 4</option>
                                    </select>
                                </div> */}

                                {/* <div className="form-group">
                                    <label >Zoom Webinar Link*</label>
                                    <input type="text" className="form-control" value={webinarurl} onChange={(e) => setWebinarURL(e.target.value)}   placeholder="Enter Webinar URL" />
                                </div>
                                <div className="form-group">
                                    <label >Livestream URL</label>
                                    <input type="text" className="form-control" value={livestreamurl} onChange={(e) => setLivestreamURL(e.target.value)}   placeholder="Enter Live Stream URL" disabled />
                                </div> */}

                            </div>
                        </form>
                        {popuptype === "editsession" ? <button className="btn btn-primary btn-sm" onClick={Submit_Update}>Update</button>
                            : <button className="btn btn-primary btn-sm" onClick={Submit_Save}>Add</button>}
                    </div>

                </div>
            </div>
        </>

    )
}

export default Sessionform

