import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import SponsorsImage from './SponsorsImage';

export const Sponsors = ({ data }) => {

    const options = {
        margin: 30,
        className: "owl-theme",
        responsiveClass: true,
        // nav: true,
        autoplay: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
            }
        },
    }
    return (
        <>
            {/* {isLoaded === false ?
                <ShimmerEffect type="bigBoxSponsers" text="Our Sponsors" />
                : */}
            <section className="primary_section Sponsors" id="Sponsors">
                {/*Our Sponsors*/}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section_header text-center">
                                <h3>Our Sponsors</h3>
                                {data.length > 0 &&
                                    <p>Thanks to our partners for sponsoring this event!</p>
                                }
                            </div>
                            <div className="uk-section">
                                {data.length === 0 ?
                                    <p className='text-center'>No sponsor found</p>
                                    :
                                    <>

                                        {data.length <= 4 ?

                                            <div className='customOwlCarousel'>
                                                {data.map((item, index) => (
                                                    <SponsorsImage index={index} imageurl={item.Photo.substr(0, 37) === "https://saroforums2021.org/api/static" ? "http://ippf.acolabz.com/api/static" + item.Photo.substr(37) : item.Photo} name={item.Name} />
                                                ))}
                                            </div>
                                            :
                                            <OwlCarousel {...options}>
                                                {data.map((item, index) => (
                                                    <SponsorsImage index={index} imageurl={item.Photo.substr(0, 37) === "https://saroforums2021.org/api/static" ? "http://ippf.acolabz.com/api/static" + item.Photo.substr(37) : item.Photo} name={item.Name} />
                                                ))}
                                            </OwlCarousel>

                                        }
                                    </>

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* } */}
        </>

    )
}
