import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { CreateSubEvent, LoadSubevent, UpdateSubEvent } from '../../../services/Event'


function SubEventform({ eventid, popuptype, subeventid, loadSubevent }) {
    const [title, setTitle] = useState("")
    const [sequence, setSequence] = useState("")
    const reg = /<(.|\n)*?>/g;


    useEffect(() => {
        if (popuptype === "createsubevent")
            clearFields()
        if (popuptype === "editsubevent") {
            loaddetails()
        }
    }, [popuptype]);

    const loaddetails = () => {
        LoadSubevent(subeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else {

                    setTitle(result.data.Name);
                    setSequence(result.data.Sequence);

                }

            }
            else {

            }
            //setIsLoaded(true);
        }).catch();
    }

    const clearFields = () => {
        setTitle('')
        setSequence('')

    }

    const Submit_Save = (e) => {
        
        if (title.trim() === "") {
            return toast.error("Please enter title");
        }
        if (reg.test(title) === true) {
            return toast.error("Html tags are not allowed");
        }
        // if (sequence === "") {
        //     return toast.error("Please enter sequence");
        // }
        // if (reg.test(sequence) === true) {
        //     return toast.error("Html tags are not allowed");
        // }


        else {

            AddSubevent();

        }
    }

    const AddSubevent = async () => {
        try {

            const req = {
                eventid: eventid,
                title: title,
                sequence: sequence
            };
            CreateSubEvent(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    window.$("#subeventform").modal('hide');
                    clearFields();
                    loadSubevent(eventid);
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        if (title.trim() === "") {
            return toast.error("Please enter title");
        }
        if (reg.test(title) === true) {
            return toast.error("Html tags are not allowed");
        }
      
        else {

            Updatesubevent();

        }
    }

    const Updatesubevent = async () => {
        try {

            const req = {
                subeventid: subeventid,
                title: title.trim(),
                sequence: sequence
            };
            UpdateSubEvent(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#subeventform").modal('hide');
                    loadSubevent(eventid);
                }
            })
                .catch()
        } catch (error) {

        }
    }

    return (
        <>
            <div id="subeventform" className="modal fade" style={{ height: "100%" }}>
                <div className="modal-dialog" style={{ width: "50%" }}>
                    <div className="modal-content" style={{ height: "50%" }}>

                        <div className="modal-header">
                            <button type="button" onClick={clearFields} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            {popuptype === "editsubevent" ? <h4 className="modal-title">Update Sub Event</h4> : <h4 className="modal-title">Add Sub Event</h4>}
                        </div>
                        <div className="modal-body">
                            <form role="form" className="subeventform">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Title *</label>
                                        <input maxLength={50} type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} id="exampleInputEmail1" placeholder="Enter Title" />
                                    </div>
                                    {/* <div className="form-group">
                                        <label>Sequence *</label>
                                        <input type="text" className="form-control" value={sequence} onChange={(e) => setSequence(e.target.value)} id="exampleInputPassword1" placeholder="Enter Sequence" />
                                    </div> */}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-sm pull-left" onClick={clearFields} data-dismiss="modal">Close</button>
                            {popuptype === "editsubevent" ? <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Update}>Update Date</button>
                                : <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Save}>Add Sub Event</button>}
                        </div>



                    </div>

                </div>
            </div>
        </>

    )
}

export default SubEventform

