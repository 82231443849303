
import { useState, useEffect } from "react";
import { PaymentPost, RegistrationIfFree } from "../../../../services/Payment"
import StripeCheckout from "react-stripe-checkout"
import { toast } from "react-toastify";
import { ValidateEmail } from "../../../../middleware/ValidateEmail"
import { useNavigate } from "react-router-dom";
import { HtmlValMsg } from "../../../../Inputs/TextInput";
import { LoadList } from "../../../../services/LoadList";
import { el, fi } from "date-fns/locale";
const reg = /<(.|\n)*?>/g;

export const Payment = ({ registrationFormInput }) => {
    const navigate = useNavigate();
    const encryptedToken = localStorage.getItem("token")

    const [isValidated, setIsValidated] = useState(false)

    const { firstname, lastname, email, mobile, address, city, country, designation, organization, agree,isEmailRegister,eventFee  } = registrationFormInput;

    useEffect(() => {
        setIsValidated(false)
        // If form validate == true -- user will see stripe button else Normal button
        if (firstname.trim() && lastname.trim() && ValidateEmail(email) && address.trim() && city.trim() && country && agree)
            return setIsValidated(true)
    }, [registrationFormInput])


    const FormValidation = () => {

        if (!firstname.trim())
            return toast.error('Please enter first name');
        else if (reg.test(firstname) === true)
            return toast.error(HtmlValMsg);
        else if (!lastname.trim())
            return toast.error('Please enter last name');
        else if (reg.test(lastname) === true)
            return toast.error(HtmlValMsg);
        else if (!email)
            return toast.error('Please enter email');
        else if (!ValidateEmail(email))
            return toast.error('Please enter valid email');
        else if (!mobile)
            return toast.error('Please enter mobile number');
        else if (!address.trim())
            return toast.error('Please enter address');
        else if (reg.test(address) === true)
            return toast.error(HtmlValMsg);
        else if (!city.trim())
            return toast.error('Please enter city');
        else if (reg.test(city) === true)
            return toast.error(HtmlValMsg);
        else if (!country)
            return toast.error('Please select country');
        else if (reg.test(designation.trim()) === true)
            return toast.error(HtmlValMsg);
        else if (reg.test(organization.trim()) === true)
            return toast.error(HtmlValMsg);
        else if (!agree)
            return toast.error('Please agree on terms and conditions');
        else if (isEmailRegister)
            return toast.error('Email is already register for this event')
 /// If event is free then call this function else call makePayment function 
        else if (eventFee === 0) {
            RegistrationIfFree(registrationFormInput).then(result => {
                if (result !== undefined) {
                    if (result.data) {
                        navigate("/success", {
                            state: {
                                "PageName": "Registration",
                                "Paid": result.data.Paid,
                                "Name": result.data.Name,
                                "Email": result.data.Email,
                                "Location": result.data.Location,
                                "Time": result.data.Timing,
                                "Amount": result.data.AmountCaptured,
                                "ReceiptUrl": result.data.ReceiptUrl
                            }
                        }
                        )
                    }
                }
            })
        }else{
             localStorage.setItem("email", email) 
             localStorage.setItem("name", firstname + " " + lastname)
             localStorage.setItem("mobile", mobile)
             
             navigate(`/pay/${encryptedToken}`)
        }

    } 

    
        // else {
        //     var data = {
        //         firstname: registrationFormInput.firstname.trim(),
        //         lastname: registrationFormInput.lastname.trim(),
        //         email: registrationFormInput.email.trim(),
        //         mobile: registrationFormInput.mobile.trim(),
        //         address: registrationFormInput.address.trim(),
        //         city: registrationFormInput.city.trim(),
        //         country: registrationFormInput.country,
        //         designation: registrationFormInput.designation.trim(),
        //         organization: registrationFormInput.organization.trim(),
        //         agree: registrationFormInput.agree,
        //         isEventFree: registrationFormInput.isEventFree,
        //         eventFee: registrationFormInput.eventFee,
        //         eventid: registrationFormInput.eventid
        //     }
        //         RegistrationIfFree(data).then(result => {
        //             if (result !== undefined) {
        //                 if (result.data) {
        //                     navigate("/success", {
        //                         state: {
        //                             "PageName": "Registration",
        //                             "Name": result.data.data.Name,
        //                             "Email": result.data.data.Email,
        //                             "Time": result.data.data.Timing,
        //                             "Location": result.data.data.Location,
        //                         }
        //                     }
        //                     )
        //                 }
        //             }
        //         })
        // }
    const makePayment = token => {
        PaymentPost(token, registrationFormInput).then(result => {
            if (result !== undefined) {
                if (result.data) {
                    navigate("/success", {
                        state: {
                            "PageName": "Registration",
                            "Paid": result.data.Paid,
                            "Name": result.data.Name,
                            "Email": result.data.Email,
                            "Location": result.data.Location,
                            "Time": result.data.Timing,
                            "Amount": result.data.AmountCaptured,
                            "ReceiptUrl": result.data.ReceiptUrl
                        }
                    }
                    )
                }
                // toast.success("Registration Successful")
            }
        })

    }
    return (
        <div className="">
            <button onClick={FormValidation} className='btn-lg btn btn-primary radiustheme'>Register</button>
        </div>
    )
}