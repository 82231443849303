import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ExhibitionList, ExhibitionAction } from '../../services/Event';


/* CreatedBy: Mayur
CreatedOn: 10 Jan 2022
Description : Admin Exhibitions
*/
function AdminExhibitions() {
    const navigate = useNavigate();

    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [selectedexhibitionid, setExhibitionid] = useState("");
    const [popuptype, setPopuptype] = useState("");
    const [exhibitionlist, setExhibitionList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [number, setNumber] = useState(0);

    useEffect(() => {
        loadexhibitions();
    }, [offset, number]);


    const openexhibitionform = (e) => {
        localStorage.setItem("popuptype","createexhibition");
        navigate('/admin/exhibition-add')
    }



    const loadexhibitions = () => {
        setIsLoaded(false);
        ExhibitionList(selectedeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setExhibitionList([])
                }
                else {
                    const data = result.data;

                    if (data.length > 0) {
                 
                        setExhibitionList(result.data);

                    }
                }
            }
            else {
                setExhibitionList([])
            }
            setIsLoaded(true);
        }).catch();

    }

    function ChangeStatus(exhibitionid, status) {
        var updatestatus = status ? "Deactivate" : "Activate"
        const req = {
            exhibitionid: exhibitionid,
            action: updatestatus

        };
        ExhibitionAction(req).then(res => {
            if (res !== undefined) {
                toast.success(res.message)
            }
        })
        loadexhibitions();
        setNumber(number + 1);
    }

    function Delete(exhibitionid) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this exhibition?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        const req = {
                            exhibitionid: exhibitionid,
                            action: "Delete"

                        };
                        ExhibitionAction(req).then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                                loadexhibitions();
                            }
                        }).catch()
                        loadexhibitions();
                        setNumber(number + 1);
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadexhibitions()
                }
            ]
        });
    }

    const loadExhibitionDetails = (exhibitionid) => {
        localStorage.setItem("selectedexhibitionid",exhibitionid)
        localStorage.setItem("popuptype","editexhibition");
        navigate("/admin/exhibition-add")
    }

    const ExhibitionMedia=(exhibitionid)=>{
        localStorage.setItem("selectedexhibitionid",exhibitionid)
        navigate("/admin/exhibition-media")
    }

    // useEffect(() => {
    //     if (selectedexhibitionid !== "")
    //         window.$("#exhibitionform").modal('show');

    // }, [selectedexhibitionid])

    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">

                <div className="content-wrapper cms">
                    <section className="content-header">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Exhibition List</h3>
                                <button className="btn btn-sm btn-primary pull-right" onClick={openexhibitionform}><i className="fa fa-plus"></i> Add New</button>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div id="partners" className=" ippftable">
                                    <div className="box-body no-padding table-responsive">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Photo</th>
                                                    <th>BoothLinks</th>
                                                    <th>BoothImage</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                {
                                                    isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                                        :
                                                        <>
                                                            {
                                                                exhibitionlist.length === 0 ?
                                                                    <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                    :
                                                                    exhibitionlist.map((item, i) => (
                                                                        <tr>
                                                                            <td className="titlelink" onClick={(e) => loadExhibitionDetails(item._id)}>{item.Title}</td>
                                                                            <td>{item.ProfilePic !== "" ? <img src={item.Image} width="100px" /> : ""}</td>
                                                                            <td><div>{item.BoothLink!=="" && item.BoothLink!==null?<div><a href={item.BoothLink} target="_blank">Website URL</a><br /></div>:""}{item.BoothEmail!=="" && item.BoothEmail!==null?<div><a style={{color:"blue"}}>{item.BoothEmail}</a><br /></div>:""}{item.BoothFBLink!=="" && item.BoothFBLink!==null?<a href={item.BoothFBLink} target="_blank">FB Link</a>:""}</div></td>
                                                                            <td><img src={item.BoothImage} width="100px"/></td>
                                                                            <td><button onClick={(e)=>ExhibitionMedia(item._id)} className="btn btn-xs btn-primary">Media</button></td>
                                                                            <td><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className={item.IsActive ? "btn btn-xs btn-info b-radius" : "btn btn-xs btn-success b-radius"}>{item.IsActive ? "Active" : "Inactive"}</button></td>
                                                                            <td><button onClick={(e) => Delete(item._id)} className="btn btn-xs btn-danger">Delete</button></td>
                                                                        </tr>

                                                                    ))
                                                            }
                                                        </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>
                    {/* <Footer total={speakerList.length} setOffset={setOffset} perpage={perPage} pageCount={pageCount} /> */}
                </div>

            </div>
           
        </div>


    );

}
export default AdminExhibitions;