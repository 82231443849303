
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { UploadFile } from '../../../services/UploadFile';
import { HtmlValMsg } from '../../../Inputs/TextInput';
import { PostData } from '../../../services/PostData';
import { LoadDetails } from '../../../services/LoadDeatails';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LoadList } from '../../../services/LoadList';
import { LoaderSmall } from '../../..';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const reg = /<(.|\n)*?>/g;

function OrganizersForm({ popuptype, newsid, loadNews }) {
    const [Title, setTitle] = useState("")
    const [designation, setDesignation] = useState("")
    const [details, setDetails] = useState("")
    const [contentimg, setContentimg] = useState("")
    const [Image, setImage] = useState("")
    const [squence, setSquence] = useState("")
    const [isPopupLoaded, setIsPopupLoaded] = useState(false);

    const onAboutDetails = (value) => {
        setDetails(value)
    }

    useEffect(() => {
        if (popuptype === "createNews") {
            clearFields()
            setIsPopupLoaded(true)
        }

        if (popuptype === "editNews") {
            loaddetails(newsid)
        }
    }, [popuptype, newsid]);

    const loaddetails = (id) => {
        setIsPopupLoaded(false)
        if (id !== 0) {
            const url = `organizer/list?newsid=${id}`
            LoadList(url).then(res => {
                const result = res;
                if (result !== undefined) {
                    if (result.data === null) {
                        setTitle("");
                        setDetails("");
                        setImage("");
                    }
                    else {
                        const data = result.data
                       
                        setTitle(data.Title);
                        setDesignation(data.SubTitle);
                        setDetails(data.Details);
                        setSquence(data.Sequence);
                        var changedImageUrl = data.Image
                        setImage(changedImageUrl);
                    }
                }
                else {
                }
            }).catch();

        }
        setIsPopupLoaded(true)
    }

    const clearFields = () => {
        setTitle('')
        setImage('')
        setDetails("")
        setContentimg("")
        setDesignation("")
        setSquence('')
    }

    const handleImage = (e) => {
        setContentimg(e.target.value)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please select image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    var changedImageUrl = res.filepath
                    setImage(changedImageUrl)

                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Save = (e) => {
        if (Title.trim() === "") {
            return toast.error("Please enter title");
        }
        if (reg.test(Title) === true) {
            return toast.error(HtmlValMsg);
        }
        if (designation.trim() === "") {
            return toast.error("Please enter designation");
        }
        if (reg.test(designation) === "") {
            return toast.error(HtmlValMsg);
        }
        if (details === "") {
            return toast.error("Please enter details");
        }
      
        else {

            AddOrganizers();

        }
    }

    const AddOrganizers = async () => {
        try {
            const req = {
                NewsId: 0,
                Title: Title.trim(),
                Details: details,
                Image: Image,
                Designation: designation,
                squence: squence,
            };
            PostData(req, "organizer/add").then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    window.$("#organizersformAdd").modal('hide');
                    clearFields();
                    loadNews();
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        if (Title.trim() === "") {
            return toast.error("Please enter title");
        }
        if (reg.test(Title) === true) {
            return toast.error(HtmlValMsg);
        }
        if (details === "") {
            return toast.error("Please enter details");
        }
      
        else {

            UpdateOrganizers();

        }
    }

    const UpdateOrganizers = async () => {
        try {
            const req = {
                NewsId: newsid,
                Title: Title.trim(),
                Details: details,
                Image: Image,
                Designation: designation,
                Sequence: squence

            };
            PostData(req, "organizer/update").then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#organizersformEdit").modal('hide');
                    loadNews();
                }
            })
                .catch()
        } catch (error) {
        }
    }

    return (
        <>
            <div className="modal-dialog" style={{ width: "50%" }}>
                <div className="modal-content" style={{ height: "50%" }}>

                    <div className="modal-header">
                        <button type="button" onClick={clearFields} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span></button>
                        {popuptype === "editNews" ? <h4 className="modal-title">Update Organizer</h4> : <h4 className="modal-title">Add Organizer</h4>}
                    </div>
                    {isPopupLoaded === false ? <LoaderSmall />
                        :
                        <div className="modal-body">
                            <form role="form" className="newsform">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Name *</label>
                                        <input maxLength={100} type="text" className="form-control" value={Title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter title" />
                                    </div>
                                    <div className="form-group">
                                        <label>Designation *</label>
                                        <input maxLength={100} type="text" className="form-control" value={designation} onChange={(e) => setDesignation(e.target.value)} placeholder="Enter designation" />
                                    </div>

                                    <div className="form-group">
                                        <label>Details *</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={details}
                                            onChange={onAboutDetails}
                                            placeholder={"Write something..."}
                                            style={{ height: "200px" }}
                                        />
                                    </div>

                                    <div className="form-group sequence">
                                        <label>Sequence *</label>
                                        <input maxLength={100} type="number" className="form-control" value={squence} onChange={(e) => setSquence(e.target.value)} placeholder="Enter sequence" />
                                    </div>

                                    <div style={{ marginTop: "60px" }} className="form-group row">
                                        <div className="col-md-6">
                                            <label>Image</label>
                                            <input type="file" className="form-control" onChange={(e) => handleImage(e)} value={contentimg} />
                                        </div>
                                        {Image
                                            &&
                                            <div className="col-md-6">
                                                <img src={Image} width="150px" />
                                            </div>
                                        }

                                    </div>

                                </div>
                            </form>
                        </div>

                    }

                    <div className="modal-footer">
                        <button type="button" onClick={clearFields} className="btn btn-default btn-sm pull-left" data-dismiss="modal">Close</button>
                        {popuptype === "editNews" ? <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Update}>Update</button>
                            : <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Save}>Add</button>}
                    </div>
                </div>

            </div>
        </>

    )
}

export default OrganizersForm

