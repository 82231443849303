import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { ChangePasswordApi } from "../../services/Login";


/* CreatedBy: Mayur
CreatedOn: 10 Jan 2022
Description : Change Events
*/
function ChangePassword() {
    const navigate = useNavigate();
    const [email, setemail] = useState("");
    const [password, setPassword] = useState(localStorage.getItem("password"));
    const [confirm, setConfirm] = useState("");
    const [oldpassword, setOldPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [errors, setError] = useState({});
    const [userid, setUserid] = useState(localStorage.getItem("userid"));
    
    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
                        toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};
        if (oldpassword === "" || oldpassword === null) {
            formIsValid = false;
            errors["oldpassword"] = "Please enter old password";
        }
        if (newpassword === "" || newpassword === null) {
            formIsValid = false;
            errors["newpassword"] = "Please enter new password";
        }
        if (confirm === "" || confirm === null) {
            formIsValid = false;
            errors["confirmpassword"] = "Please enter confirm password";
        }
        if (oldpassword === newpassword) {
            formIsValid = false;
            errors["newpassword"] = "Old password and new password can not be the same";
        }
        if (confirm !== "" && confirm !== null && confirm !== newpassword) {
            formIsValid = false;
            errors["confirmpassword"] = "New and confirm password is not matching";
        }

        setError(errors);
        return formIsValid;
    }

    const clearFields = () => {
        setConfirm("");
        setNewPassword("");
        setOldPassword("");

    }

    const ChangePasswordClick = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            localStorage.setItem("password", newpassword);
            ChangePasswordApi(userid, newpassword, oldpassword).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message);
                    navigate("/admin-events");
                    clearFields();
                }
            })
                .catch();
        }
    }

    return (

        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper ">
                    <section className="content-header">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-6">
                                    <h3>Change Password</h3>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">

                                    <form onSubmit={ChangePasswordClick} className="form-horizontal">
                                        <div className="add-draw">
                                            <div className="form-group">
                                                <label for="inputEmail3" className="col-sm-4 control-label">Old Password <span style={{ color: "red" }}>*</span> </label>
                                                <div className="col-sm-8">
                                                    <input type="password" className="form-control" id="inputEmail3" value={oldpassword} onChange={(e) => setOldPassword(e.target.value)} placeholder="" />
                                                    <p className="errormsg"><span>{errors["oldpassword"]}</span></p>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label for="inputEmail3" className="col-sm-4 control-label">New Password <span style={{ color: "red" }}>*</span> </label>
                                                <div className="col-sm-8">
                                                    <input type="text" className="form-control" id="inputEmail3" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="" />
                                                    <p className="errormsg"><span>{errors["newpassword"]}</span></p>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label for="inputEmail3" className="col-sm-4 control-label">Confirm Password <span style={{ color: "red" }}>*</span> </label>
                                                <div className="col-sm-8">
                                                    <input type="password" className="form-control" id="inputEmail3" value={confirm} onChange={(e) => setConfirm(e.target.value)} placeholder="" />
                                                    <p className="errormsg"><span>{errors["confirmpassword"]}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {/* <button onClick={(e) => clearFields()} className="btn btn-sm btn-theme b-radius pull-right"> Cancel</button> */}
                                            <button type="submit" className="btn btn-sm btn-primary b-radius mr-10 pull-right">Change</button></div>
                                    </form>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="control-sidebar-bg"></div>
            </div>
        </div>



    );
}

export default ChangePassword;