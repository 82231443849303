import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'
import { useGetAllSpeakerQuery } from '../../../../features/LoadSepeakers';
import { ShimmerEffect } from '../../../styleUI/ShimmerEffect';


export const Header = ({ headerLogo, page }) => {
    const location = useLocation()
    const eventId = localStorage.getItem("evnid")
    const [mainHeaderLogoSrc, setMainHeaderLogoSrc] = useState("")
    const [mainHeaderLogoAlt, setMainHeaderLogoAlt] = useState("")
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [isloaded, setIsLoaded] = useState(false)
    const [registrationType, setRegistrationType] = useState("");
    const [googleFormLink, setGoogleFormLink] = useState("");
    const { data, isError, isLoading } = useGetAllSpeakerQuery()



    useEffect(() => {
        setIsLoaded(true)
        if (page === "feature-event" || page === "archive-event") {
            if (headerLogo) {
                setMainHeaderLogoSrc(headerLogo)
                setMainHeaderLogoAlt("feature-logo")
                setIsLoaded(true)
            }
            else {
                setMainHeaderLogoSrc("images/Notfound.png")
                setMainHeaderLogoAlt("not-found")
                setIsLoaded(true)
            }
        }
        setIsLoaded(true)
    }, [headerLogo])


    useEffect(() => {
        setMainHeaderLogoSrc("images/logo.png")
        setMainHeaderLogoAlt("main-logo")
        setIsLoaded(true);
    }, [])
    useEffect(() => {
        if (isLoading === false) {
            if (data) {
                if (data.data.length !== 0) {
                    localStorage.setItem("evnid", data.data[0]._id)
                    setRegistrationType(data.data[0].RegistrationType)
                    setGoogleFormLink(data.data[0].GoogleFormLink)
                }
            }
        } else {

        }

    }, [isLoading])

    return (

        <header>
            {/*Top Navbar Menu*/}
            <nav className="navbar-default navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-header">

                        <div className={page+"-toggleButton"}>
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                        </div>

                        <>

                            {/* if its feature page then headerlogo will work else archiveEventLogo */}
                            <a className="navbar-brand" href="/#/home">
                                <img style={{ display: "none" }} src={mainHeaderLogoSrc} onLoad={(e) => setIsImageLoaded(true)} onError={true} />
                                {isImageLoaded === false || isloaded === false ?
                                    <ShimmerEffect type="HeaderLogo" />
                                    :
                                    <>
                                        {page === "Mainpage" || page === undefined || page === "archive-event-list" ? <img src={mainHeaderLogoSrc} className="logo-icon" alt={mainHeaderLogoAlt} /> :
                                            <img src={mainHeaderLogoSrc} className="logo-icon" alt={mainHeaderLogoAlt} />
                                        }
                                    </>
                                }
                                <span className="logo-text" />
                            </a>
                        </>
                    </div>

                    <div id="navbar" className="navbar-collapse collapse navbar-links">
                        <ul className="nav navbar-nav nav-right">
                            {page === "archive-event" ? "" :
                                <>
                                    <li className="link-li">
                                        <NavLink className={location.pathname === "/home" ? "active" : ""} exact to="/home">Home</NavLink>
                                    </li>
                                    <li className="link-li">
                                        <NavLink exact to="/about">About Us</NavLink>
                                    </li>
                                    <li className="link-li">
                                        <NavLink exact to="/newslist">News & Feeds</NavLink>
                                    </li>
                                    <li className="link-li">
                                        <NavLink exact to="/feature-event">Upcoming</NavLink>
                                    </li>
                                    <li className="link-li">
                                        <NavLink exact to="/archive-events">Past Events</NavLink>
                                    </li>
                                    <li className="link-li">
                                        <NavLink exact to="https://user.emsling.com/" 
                                        target="_blank"
                                        >Quiz</NavLink>
                                    </li>
                                    <li className="link-li"><NavLink to="/contact" >Contact Us</NavLink></li>

                                    {/* <li className="link-btn">
                                        {
                                            registrationType === "Google Form" ?
                                                <a href={googleFormLink} target="_blank" className="btn btntheme">Register</a>
                                                :
                                                <NavLink exact to="/registration" className="btn btntheme">Register</NavLink>
                                        }
                                    </li> */}
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}