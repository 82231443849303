import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";


const SuccesPage = () => {
    const { state } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const toTitleCase = (phrase) => {
        return phrase
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (
        <>
            <Header />

            <div className="top-section breadcrumb about-bd">
                <span className="overlay" />
                <div className="bdsec">
                    <div className="container">
                        <h2 className="mtb-0">{state.PageName}</h2>
                    </div>
                </div>
            </div>

            {state.PageName === "Registration" ?
                <div>
                    <section className="confirmationbox">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box-body">
                                        <img src="images/confirmemail.png " />
                                        <h2>Thanks for registering to the event</h2>
                                        <h4> {toTitleCase(state.Name)}</h4>
                                        <p className="date">{state.Time}</p>
                                        <p className="address">{state.Location}</p>
                                        <hr />
                                        <div className="masg">Event details has been sent in your registered email address.</div>
                                        {state.ReceiptUrl && <a href={state.ReceiptUrl} target="_blank" className="btn btn-primery">DOWNLOAD RECIPT</a>}
                                    </div></div></div></div></section><a>
                    </a>
                </div>
                :
                state.PageName === "Contact" ?
                    <section className="confirmationbox">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box-body">
                                        <img src="images/confirmemail.png " />
                                        <h2>Thanks for contacting us</h2>
                                        <hr />
                                        <div className="masg">We will get back to you soon.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="confirmationbox">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box-body">
                                        <img src="images/confirmemail.png " />
                                        <h2>Thank you</h2>
                                        <hr />
                                        <div className="masg">Thanks for submitting your feedback.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


            }

            <Footer />
        </>
    )
}

export default SuccesPage;

