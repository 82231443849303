

export const Banner = ({ bannerName }) => {
    return (
        <>
            {bannerName === "Home" ?
                <section className="carouseltop Homepage">
                    <div className="carouselsec">
                        <div className="carousel-inner">
                            <img src="images/Homebanner.png" alt="Home Page Banner Images" />
                        </div>
                    </div>
                </section>

                :
                <div className="top-section breadcrumb about-bd">
                    <span className="overlay" />
                    <div className="bdsec">
                        <div className="container">
                            <h2 className="mtb-0">{bannerName}</h2>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}