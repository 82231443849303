import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../../features/Counter";

function AdminTopBar() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const isShowSidebar = useSelector((state) => state.value.sidebar)

    const Signout = () => {
        localStorage.setItem("AccessToken",'')
        localStorage.setItem("selectedeventid",'')
        localStorage.setItem("popuptype",'')
        localStorage.setItem("userid",'')
        localStorage.setItem("usertype",'')
        localStorage.setItem("email",'')
        localStorage.setItem("username",'')
        localStorage.setItem("phone",'')
        localStorage.setItem("landpage",'')
        localStorage.setItem("subtab",'')
        dispatch(decrement())
        navigate("/admin-login");
    }
    return (
        <header className="main-header">
            <a href="#" className="logo">
                {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
                <span className="logo-mini"><b>CMS</b></span>
                {/* <!-- logo for regular state and mobile devices --> */}
                <span className="logo-lg"><b>CMS</b></span>
            </a>

            {/* <!-- Header Navbar: style can be found in header.less --> */}
            <nav className="navbar navbar-static-top">
                {/* <!-- Sidebar toggle button--> */}
                {/* <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </a> */}
                {/* <!-- Navbar Right Menu --> */}
                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        {/* <!-- User Account: style can be found in dropdown.less --> */}
                        <li className="dropdown user user-menu">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                <span className="hidden-xs" id="divEmpName">Admin <i className="fa fa-chevron-down"></i></span>
                            </a>
                            <ul className="dropdown-menu">
                                {/* <!-- User image --> */}
                                <li className="user-header">
                                    <img id="imgProfilePic" className="img-circle" alt="" src="images/avtar.png" />
                                    <p>
                                        <span id="divEmpName2" ></span>
                                        <small id="divOutletName" ></small>
                                        <small id="divMobile" ></small>
                                        <small id="divEmail" ></small>
                                    </p>
                                </li>
                                {/* <!-- Menu Footer--> */}
                                <li className="user-footer">
                                    <div className="pull-left">
                                        <Link to="/admin/changepassword" className="btn btn-default btn-flat">Change Password</Link>
                                    </div>
                                    <div className="pull-right">
                                        <a onClick={Signout} className="btn btn-default btn-flat">Sign Out</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </nav>
        </header>
    );

}
export default AdminTopBar