import React from 'react'
import { AllRouters } from "./routes/routes"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, Slide } from 'react-toastify'
import { Navigate, Route, Routes } from 'react-router-dom';
import PageNotFound from "./components/pages/PageNotFound";

function App() {
  return (
    <>
      <ToastContainer
        transition={Slide}
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AllRouters />
    </>
  );
}
export default App;


