import { useState, useEffect } from "react";
import { PaymentPost, RegistrationIfFree } from "../../../../services/Payment"
import StripeCheckout from "react-stripe-checkout"
import { ValidateEmail } from "../../../../middleware/ValidateEmail"
import { useNavigate, useParams } from "react-router-dom";
import { HtmlValMsg } from "../../../../Inputs/TextInput";
import { LoadList } from "../../../../services/LoadList";
import { Header } from "../../commonSection/Header";
import { Banner } from "../Banner";
import { Footer } from "../../commonSection/Footer";
const reg = /<(.|\n)*?>/g;

export const PaidPaymentPage = () => {
    const navigate = useNavigate();

    const encryptedToken =  localStorage.getItem("evnid")
    const useremail = localStorage.getItem("email");
    const username = localStorage.getItem("name");
    const usermobile = localStorage.getItem("mobile");
    
 
    console.log(encryptedToken)
    const [isloading, setisloading] = useState(false)
    const [objId, setId] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [fees, setFees] = useState('')
    const [mobileNumber, setNumber] = useState('')
    const [eventname, setEventname] = useState('')


    useEffect(() => {
        setEmail(useremail)
        setNumber(usermobile)
        setName(username)

        console.log("username", username)
        console.log("useremail", useremail)
        console.log("usermobile", usermobile)


        setisloading(false)
        LoadList(`registration/details?token=${encryptedToken}&email=${useremail}`).then(result => {
            if (result !== undefined) {
                if (result.data) {
                    const res = result.data.data
                    setName(res.name);
                    setId(res.objId);
                    setEmail(res.email);
                    setFees(res.amount);
                    setNumber(res.mobile);
                    setEventname(res.eventname);
                    setisloading(true)
                }

            }
        }).catch();
    }, [])




    const makePayment = token => {
        PaymentPost(token, encryptedToken,objId,useremail).then(result => {
            if (result !== undefined) {
                if (result.data) {
                    navigate("/success", {
                        state: {
                            "PageName": "Registration",
                            "Paid": result.data.Paid,
                            "Name": result.data.Name,
                            "Email": result.data.Email,
                            "Location": result.data.Location,
                            "Time": result.data.Timing,
                            "Amount": result.data.AmountCaptured,
                            "ReceiptUrl": result.data.ReceiptUrl
                        }
                    })
                }
                // toast.success("Registration Successful")
            }
        })

    }

    return (
        <div className="outerhomepage">
        <Header />
        <Banner bannerName="Payment Page" />

        <section className="registerPage">
            <div className="container">
            {isloading=== false ? "Loading"
            :
            <div>
            <div class="form-group">
                <label for="exampleInputEmail1">Your Name</label>
                <p>{username}</p>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Your Email</label>
                <p>{useremail}</p>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Your mobile</label>
                <p>{usermobile}</p>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Event Name</label>
                <p>{eventname}</p>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Event Fees</label>
                <p>$ {fees}</p>
            </div>
        </div>
        }
             

                <StripeCheckout stripeKey={process.env.REACT_APP_PUBLISHABLE_KEY}
                    token={makePayment}
                    name="Registration Fee"
                    amount={Number(fees) * 100}>
                    <button className="btn-lg btn btn-primary" > Pay </button>
                </StripeCheckout>
            </div>

        </section>

        <Footer />
    </div>
    )
}