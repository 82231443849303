
const VisionMission = ({ type }) => {
    return (
        <>


            <div class="mt-30">
                <div class="row vmflex">
                    <div class="col-md-4">
                        <div className="insight-box vmtextbox">                           
                            <div className="">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                                <h4> Our Vision</h4>
                                <p> Upskilling with networking and fun</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="insight-box vmtextbox">                           
                            <div className="">
                                <i class="fa fa-bullseye" aria-hidden="true"></i>
                                <h4> Our Mission</h4>
                                <p> Provide quality, cost-effective training with the practical tips and trouble shooting from experts.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="insight-box vmtextbox">                           
                            <div className="">
                                <i class="fa fa-trophy" aria-hidden="true"></i>
                                <h4> Our Goal</h4>
                                <p>
                                    To enhance knowledge and capabilities of Emergency medical
                                    practitioners with share of experience of Masters from different
                                    subspecialities and regions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VisionMission;