import { useEffect, useState } from "react"
import { Countries } from "./Countries"
import { Payment } from "./payment/PaymentPage"
import { LoadList } from "../../../services/LoadList"
import { LoaderSmall } from "../../.."
import { useGetAllSpeakerQuery } from "../../../../features/LoadSepeakers"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"


export const RegistrationForm = ({amount}) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const { data, isError, isLoading } = useGetAllSpeakerQuery()
    const [registrationFormInput, SetRegistrationFormInput] = useState({
        firstname: "",
        lastname: "",
        email: '',
        mobile: '',
        address: '',
        city: '',
        country: 'Singapore',
        designation: '',
        organization: '',
        agree: true,
        isEmailRegister: true,
        eventid: localStorage.getItem("evnid"),
        eventFee: amount,


    })


    const { eventid, firstname, lastname, email, mobile, address, city, country, designation, organization, agree, isEmailRegister } = registrationFormInput;


    const onInputChange = e => {
        SetRegistrationFormInput({ ...registrationFormInput, [e.target.name]: e.target.value })
    }
    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        SetRegistrationFormInput((prevState) => {
            return { ...prevState, agree: !agree }
        });
        // Don't miss the exclamation mark
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        const timer = setTimeout(() => {
            checkEmailAlreadyRegister(email, eventid)
        }, 3000);
        return () => clearTimeout(timer);
    }, [email])

    const checkEmailAlreadyRegister = (email, eventid) => {
        LoadList(`check-email?eventid=${eventid}&email=${email}`).then(result => {
            if (result !== undefined) {
                if (result.data) {
                    SetRegistrationFormInput((prevState) => {
                        return { ...prevState, isEmailRegister: false }
                    });
                } else {
                    toast.error("Email is already register for this event")
                    SetRegistrationFormInput((prevState) => {
                        return { ...prevState, isEmailRegister: true }
                    });
                }
            }
        }).catch()
    }



    useEffect(() => {
        setIsLoaded(false)
        if (isLoading === false) {
            if (data) {
                if (data.data.length !== 0) {
                    localStorage.setItem("evnid", data.data[0]._id)
                    LoadList(`web/eventdetails?id=${data.data[0]._id}`).then(result => {
                        if (result !== undefined) {
                            if (!result.data) {
                                // setSessionsData([])
                            }
                            else {
                                SetRegistrationFormInput((prevState) => {
                                    return { ...prevState, eventFee: result.data.EventFee }
                                });
                                SetRegistrationFormInput((prevState) => {
                                    return { ...prevState, isEventFree: result.data.IsEventFree }
                                });

                            }
                        } else {
                            // setSessionsData([])
                        }
                        setIsLoaded(true);
                    }).catch();
                }
            } else {
                toast.error("Sorry no events found")
            }
            setIsLoaded(true);
        }
    }, [isLoading])

  

    const handelFormRegisterUser = e => {
        e.preventDefault();
    }
    return (
        <section className="registerPage">
            <div className="container">
                <div className="row">
                    {isLoaded === false ? <p style={{ height: "600px" }}><LoaderSmall /></p>
                        :
                        <form onSubmit={(e) => handelFormRegisterUser(e)} >
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label  >First Name *</label>
                                    <input maxLength={20} onChange={(e) => onInputChange(e)} value={firstname} name="firstname" type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label  >Last Name *</label>
                                    <input maxLength={30} onChange={(e) => onInputChange(e)} type="text" className="form-control" value={lastname} name="lastname" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Your Email *</label>
                                    <input onChange={(e) => onInputChange(e)} type="text" className="form-control" value={email} name="email" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Phone Number *</label>
                                    <input onChange={(e) => onInputChange(e)} type="text" className="form-control" value={mobile} name="mobile" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label>Address *</label>
                                    <input onChange={(e) => onInputChange(e)} type="text" className="form-control" value={address} name="address" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>City *</label>
                                    <input maxLength={50} onChange={(e) => onInputChange(e)} type="text" className="form-control" value={city} name="city" />
                                </div>
                            </div>
                            <Countries onChange={(e) => onInputChange(e)} value={country} name="country" />
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Organization</label>
                                    <input maxLength={50} onChange={(e) => onInputChange(e)} type="text" className="form-control" value={organization} name="organization" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Designation</label>
                                    <input maxLength={50} onChange={(e) => onInputChange(e)} type="text" className="form-control" value={designation} name="designation" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="checkbox form-control">
                                    <label>
                                        <input checked={agree} onChange={checkboxHandler} type="checkbox" name="agree" /> I agree with the <u><Link to="/termsconditions">terms and conditions</Link></u>.
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mt-20 col-xs-12">
                                <Payment registrationFormInput={registrationFormInput} />
                            </div>
                        </form>
                    }


                </div>

            </div>
        </section>
    )
}