// import { Footer } from "../../dashboard/Footer"
import { Footer } from "../commonSection/Footer"
import { Header } from "../commonSection/Header"
import { Banner } from "./Banner"
import { RegistrationForm } from "./RegistrationForm"
import { useLocation } from "react-router-dom"


const RegistrationPage = () => {
    const location = useLocation()
    const amount = location.state ? location.state.amount : 0
    return (
        <div className="outerhomepage">
            <Header />
            <Banner bannerName="Registration" />
            <RegistrationForm  amount={amount}/>
            <Footer />
        </div>

    )

}

export default RegistrationPage;