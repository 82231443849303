import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateEmail } from "../../middleware/ValidateEmail"
import { TextInput } from "../../Inputs/TextInput";
import { forgotPassService, Loginfunc } from "../../services/Login"
import { increment } from "../../../features/Counter";
import { useDispatch } from "react-redux";


export const LoginFormComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [errors, setError] = useState({ email: '', pass: "" });
    const [IsLoadingBtn, setIsLoadingBtn] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');
    const LoginButtonRef = useRef();
    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (userLoggedInInformation) {
            navigate('/admin-events');
        } else {
            navigate('/admin-login');
        }
    }, [])
    const [loginFormInput, setLoginFormInput] = useState({
        email: "",
        password: "",
    })

    const { email, password } = loginFormInput;

    const onInputChange = e => {
        if (e.target.name === "email") {
            if (email === "" || email === null) {
                setError({ email: "Please enter email address", pass: "" });
            }
            else if (!ValidateEmail(email)) {
                setError({ email: "Please enter valid email address", pass: "" });
            }
            else {
                setError({ email: "", pass: "" });
            }
        }
        if (e.target.name === "password") {
            if (password === "" || password === null) {
                setError({ pass: "Please enter password", email: "" });
            }
            else {
                setError({ pass: "", email: "" });

            }
        }
        setLoginFormInput({ ...loginFormInput, [e.target.name]: e.target.value })
    }


    const onLoginFormSubmit = (e) => {
        e.preventDefault();
        if (!email)
            return toast.error("Please enter email address")

        if (!ValidateEmail(email))
            return toast.error("Please enter valid email")

        if (!password)
            return toast.error("Please enter password")

        LoginButtonRef.current.disabled = true;
        setIsLoadingBtn(true)

        Loginfunc(email, password, LoginButtonRef, setIsLoadingBtn).then(res => {
            if (res !== undefined) {
                if (res.data !== undefined) {
                    //store in localstorage                            
                    localStorage.setItem("userinfo", JSON.stringify(res.data));
                    localStorage.setItem("userid", res.data._id);
                    localStorage.setItem("usertype", res.data.type);
                    localStorage.setItem("email", res.data.email);
                    localStorage.setItem("username", res.data.name);
                    localStorage.setItem("phone", res.data.phone);
                    localStorage.setItem("AccessToken", res.data.token);

                    if (res.data.type === "Admin") {
                        const timer = setTimeout(() => {
                            localStorage.setItem("landpage", "event");
                            localStorage.setItem("subtab", "eventlist")
                            localStorage.setItem("screentype", 'create')
                            navigate('/admin-events');
                            // toast.success('You are successfully signed in...');
                        }, 1000);
                        return () => clearTimeout(timer);
                    }
                }
            } else {

            }
        })
            .catch();
    }

    const forgotPasswordPopUp = () => {
        window.$("#forgotPasswordPopup").modal('show');
    }

    const onEmailSubmit = e => {
        e.preventDefault();
        if (!forgotEmail) {
            return toast.error("Please enter email")
        }
        if (!ValidateEmail(forgotEmail))
            return toast.error("Please enter valid email")

        forgotPassService(forgotEmail).then((res) => {
            if (res !== undefined) {
                if (res.data !== undefined) {
                    setForgotEmail("")
                    toast.success('Password reset link successfully send to your email')
                }
            } else {

            }
        })
    }

    const clearForgotEmail = () => {
        setForgotEmail("")
    }

    return (
        <>
            <form onSubmit={e => onLoginFormSubmit(e)} autoComplete="off" >
                <div className="form-horizontal"></div>
                <div className="form-group ">
                    <TextInput type="text" name="email" value={email} className="form-control" autoComplete={false} placeholder="Email" onChange={(e) => onInputChange(e)} />
                    {/* <input type="text" id="txtEmpno" className="form-control" autoComplete="off" placeholder="Email" onChange={(e) => { handleChange("email", e) }} /> */}
                    <span className="glyphicon glyphicon-user form-control-feedback"></span>
                    <span style={{ color: "red" }}>{errors.email}</span>
                </div>
                <div className="form-group ">
                    <TextInput type="password" value={password} name="password" className="form-control" autoComplete={false} placeholder="Password" onChange={(e) => onInputChange(e)} />

                    <span className="form-control-feedback"></span>
                    <span style={{ color: "red" }}>{errors.pass}</span>
                </div>
                <div className="clear"></div>
                <div className="row">
                    <div className="col-xs-4">
                        <button ref={LoginButtonRef} type="submit" className="btn btn-primary btn-block btn-flat" >
                            {IsLoadingBtn === true ? <><span><i className={IsLoadingBtn === true ? "spinner-border spinner-border-sm" : ""}></i> Loading...</span> </> : "LOGIN"}
                        </button>
                    </div>
                </div>
            </form>
            <div className="login-footer-link" style={{ marginTop: "10px" }}>
                <span className="text1">Forgot Password?</span>&nbsp;
                <a style={{ cursor: "pointer" }} onClick={forgotPasswordPopUp}>Click here</a>&nbsp;
                <span className="text2">to reset</span>
            </div>
            <div id="forgotPasswordPopup" className="modal fade" style={{ height: "100%" }}>
                <div className="modal-dialog" style={{ width: "50%" }}>
                    <div className="modal-content" style={{ height: "50%" }}>
                        <div className="modal-header">
                            <div className="col-md-6">
                                <h3>Forgot Password</h3>
                            </div>
                            <div onClick={clearForgotEmail} className="closeright"><a type="button" data-dismiss="modal" aria-label="Close"><img src="images/close.png" /></a></div>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => onEmailSubmit(e)} role="form">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Email<span style={{ color: "red" }}>*</span></label>
                                        <input type="text" className="form-control" value={forgotEmail} placeholder="Enter your email" onChange={(e) => setForgotEmail(e.target.value)} />
                                    </div>
                                    <button type='submit' className="btn btn-primary btn-sm" >
                                        Send Verification Code
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}