import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { CreateDate, LoadDate, UpdateDate } from '../../../services/Event'
import moment from 'moment'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;


function ScheduleDateform({ eventid, subeventid, datepopuptype, dateid, loadSubevent }) {
    const navigate = useNavigate();
    const [date, setDate] = useState("")
    const [day, setDay] = useState("")

    useEffect(() => {
        if (datepopuptype === "createdate") {
            clearFields()
        }
        if (datepopuptype === "editdate") {
            loaddetails()
        }
    }, [datepopuptype, dateid]);

    const loaddetails = () => {
        LoadDate(dateid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else {
                    var obj = result.data.Date
                    obj = moment(new Date(obj)).format("YYYY-MM-DD")
                    setDate(obj);
                    setDay(result.data.Day);
                }

            }
            else {

            }
            //setIsLoaded(true);
        }).catch();
    }

    const clearFields = () => {
        setDate('')
        setDay('')

    }

    const Submit_Save = (e) => {
        if (date === "") {
            return toast.error("Please enter date");
        }
        if (day === "Select") {
            return toast.error("Please select day");
        }
        else {

            AddDate();

        }
    }

    const AddDate = async () => {
        try {

            const req = {
                subeventid: subeventid,
                date: date,
                day: day
            };
            CreateDate(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#dateform").modal('hide');
                    loadSubevent(eventid);
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        if (date === "") {
            toast.error("Please enter date");
        }
        else if (day === "Select") {
            toast.error("Please select day");
        }
        else {

            Updatedate();

        }
    }

    const Updatedate = async () => {
        try {

            const req = {
                dateid: dateid,
                date: date,
                day: day
            };
            UpdateDate(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#dateform").modal('hide');
                    loadSubevent(eventid);
                }
            })
                .catch()
        } catch (error) {

        }
    }

    return (
        <>
            <div id="dateform" className="modal fade" style={{ height: "100%" }}>
                <div className="modal-dialog" style={{ width: "50%" }}>
                    <div className="modal-content" style={{ height: "50%" }}>

                        <div className="modal-header">
                            <button type="button" onClick={clearFields} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            {datepopuptype === "editdate" ? <h4 className="modal-title">Update Date</h4> : <h4 className="modal-title">Add Date</h4>}
                        </div>
                        <div className="modal-body">
                            <form role="form" className="dateform">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Date *</label>
                                        <input type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} id="exampleInputEmail1" placeholder="Enter Date" />
                                    </div>

                                    <div className="form-group">
                                        <label>Day *</label>
                                        <select className="form-control" value={day} onChange={(e) => setDay(e.target.value)}>
                                            <option value="Select">Select</option>
                                            <option value="Sunday">Sunday</option>
                                            <option value="Monday">Monday</option>
                                            <option value="Tuesday">Tuesday</option>
                                            <option value="Wednesday">Wednesday</option>
                                            <option value="Thursday">Thursday</option>
                                            <option value="Friday">Friday</option>
                                            <option value="Saturday">Saturday</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={clearFields} className="btn btn-default btn-sm pull-left" data-dismiss="modal">Close</button>
                            {datepopuptype === "editdate" ? <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Update}>Update Date</button>
                                : <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Save}>Add Date</button>}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ScheduleDateform

