import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SpeakerCard } from './SepeakersCard';
import { SpeakersModalPopUp } from './SpeakersModalPopup';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;


export const Speakers = ({ data }) => {
    const [visible, setVisible] = useState(4)
    const [imageUrl, setImageUrl] = useState('')
    const [speakername, setSpeakername] = useState('')
    const [designation, setDesignation] = useState('')
    const [aboutSpeaker, setAboutSpeaker] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)

    const loadMoreItem = () => {
        setVisible((prevValue) => prevValue + 4)
    }

    const setDataforPopup = (ImageUrl, Speakername, Designation, AboutSpeaker) => {
        setIsLoaded(false)
        const timer = setTimeout(() => {
            setImageUrl(ImageUrl)
            setSpeakername(Speakername)
            setDesignation(Designation)
            setAboutSpeaker(AboutSpeaker)
            setIsLoaded(true)
        }, 1000);
        return () => clearTimeout(timer);

    }

   
    return (
        <>
            {/* <section style={{ height: "650px", overflowX: "hidden", overflowY: "auto" }} className="secondary_section speakers" id="Speakers"> */}
            <section className="secondary_section speakers" id="Speakers">
                {/*Speakers*/}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {data.length === 0 ?
                                <div className='text-center'>
                                    <h1>
                                        No speaker found
                                    </h1>
                                </div>
                                :
                                <>
                                    <div className="section_header text-center">
                                        <h3>Meet our Conference Speakers</h3>
                                        <p>{data.length} Presentations &amp; Workshops will take place</p>
                                    </div>
                                    <div className="team_members_grid">
                                        {data.slice(0, visible).map((item, index) => (
                                            <SpeakerCard index={index} imagename={item.ProfilePic.substr(0, 37) === "https://saroforums2021.org/api/static" ? API_Base_Url + "static" + item.ProfilePic.substr(37) : item.ProfilePic} speakername={item.Name} designation={item.Designation} onClick={() => setDataforPopup(item.ProfilePic.substr(0, 37) === "https://saroforums2021.org/api/static" ? API_Base_Url + "static" + item.ProfilePic.substr(37) : item.ProfilePic, item.Name, item.Designation, item.Biodata)} />
                                        ))
                                        }
                                    </div>
                                    {visible < data.length &&
                                        <div className="text-center ">
                                            <button className="btn-lg btn btn-primary" onClick={() => loadMoreItem()} title="See all speakers">
                                                View More
                                            </button>
                                        </div>
                                    }
                                </>
                            }

                        </div>
                    </div>
                </div>
            </section>


            <SpeakersModalPopUp imageUrl={imageUrl} isLoaded={isLoaded} speakername={speakername} designation={designation} aboutSpeaker={aboutSpeaker} />

        </>
    )
}


