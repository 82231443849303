import { useEffect, useState } from "react";
import { LoaderSmall } from "../../../../components";
import { LoadList } from "../../../services/LoadList";



{/* Gallery */ }
const GallerySection = () => {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoaded(false)
        loadGallery()
    }, [])

    const onClickRightbtn = () => {

        if (data.length - 1 === photoIndex)
            return setPhotoIndex(0)
        return setPhotoIndex(photoIndex + 1)
    }
    const onClickLeftbtn = () => {
        if (photoIndex === 0)
            return setPhotoIndex(data.length - 1)
        return setPhotoIndex(photoIndex - 1)
    }

    const loadGallery = () => {
        setIsLoaded(false)
        LoadList("image/list").then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    setData([])
                }
                else {
                    setData(result.data)

                }
            } else {
                setData([])
            }
            setIsLoaded(true);
        }).catch()
    }

    return (
        <>
            {data.length === 0 ? "" :
                <section className="paddtop100">
                    <div className="container-fluid">
                        <div className="section-title">
                            <h2>Gallery</h2>
                        </div>
                        <div className="galleryslider">
                            {isLoaded === false ? <LoaderSmall />
                                :
                                <div id="myCarousel2" className="carousel slide" data-ride="carousel">
                                    {/* Wrapper for slides */}
                                    <div className="carousel-inner">
                                        {data.map((item, index) => (
                                            <div className={`item ${index === photoIndex ? 'active' : ''}`}>
                                                <img src={item.Image} alt={item.Image} />
                                              
                                                <div class="carousel-caption" id="captionContent">
                                                    <h3>{item.Name} </h3>                                             
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* Left and right controls */}
                                    <a className="left carousel-control" onClick={() => onClickLeftbtn()} data-slide="prev">
                                        <span className="fa fa-chevron-left" />
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="right carousel-control" onClick={() => onClickRightbtn()} data-slide="next">
                                        <span className="fa fa-chevron-right" />
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            }

                        </div>
                    </div>
                </section>
            }
        </>

    )
}

export default GallerySection;