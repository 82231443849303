import { useEffect, useState } from "react";
import { Footer } from "./commonSection/Footer";
import { Header } from "./commonSection/Header";
import { Banner } from "./registration/Banner";
import { toast } from "react-toastify";
import { ValidateEmail } from "../../middleware/ValidateEmail";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { HtmlValMsg } from "../../Inputs/TextInput";
import  SEO from "../public/commonSection/seo"
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const reg = /<(.|\n)*?>/g;


const ContactUs = () => {
    const navigate = useNavigate()
    const [contactFormInput, SetContactFormInput] = useState({
        name: "",
        email: '',
        subject: '',
        message: "",
        eventid: localStorage.getItem("evnid")
    })


    const { name, email, subject, message } = contactFormInput;


    const onInputChange = e => {
        SetContactFormInput({ ...contactFormInput, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handelFormContact = e => {
        e.preventDefault()
        if (!name)
            return toast.error('Please enter name');
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }
        if (!email)
            return toast.error('Please enter email');
        if (!ValidateEmail(email))
            return toast.error('Please enter valid email');
        if (reg.test(email) === true) {
            return toast.error(HtmlValMsg);
        }
        if (!subject)
            return toast.error('Please enter subject');
        if (reg.test(subject) === true) {
            return toast.error(HtmlValMsg);
        }
        if (!message)
            return toast.error('Please enter message');

        if (reg.test(message) === true) {
            return toast.error(HtmlValMsg);
        }
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
            const response = axios.post(API_Base_Url + 'contactus/add', contactFormInput, headers, { timeout: 2 }).then((response) => {
                if (response.data) {
                    navigate("/success", {
                        state: {
                            "PageName": "Contact"
                        }
                    }
                    )
                }
                // toast.success("Your query successfully stored, we will get back to you soon.")
                // return navigate('/')
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.Error.ErrorMessage !== undefined)
                        toast.error(error.response.data.Error.ErrorMessage);
                }
                if (error.response.status === 500) {
                    if (error.response.data.Error.ErrorMessage !== undefined)
                        toast.error(error.response.data.Error.ErrorMessage);
                }
            });
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }
    return (
        <>
         <SEO
        title='Contact EM Sling - Connect With Emergency Medicine Experts'
        description='Reach out to EM Sling for inquiries, collaborations, or feedback. Our dedicated team is here to support emergency medical practitioners and partners. Let’s advance emergency medicine together. Contact us today.'
        keywords=''/>
        
        <div className="outerhomepage">
            <Header />
            <Banner bannerName="Contact" />
            <section className="registerPage">
                <div className="container">
                    <div className="row">
                        <form onSubmit={(e) => handelFormContact(e)}>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Your Name *</label>
                                    <input type="text" maxLength={50} value={name} onChange={(e) => onInputChange(e)} name="name" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Email *</label>
                                    <input type="text" name="email" onChange={(e) => onInputChange(e)} value={email} className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Subject *</label>
                                    <input maxLength={100} type="text" name="subject" value={subject} onChange={(e) => onInputChange(e)} className="form-control" />
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                    <label>Your Message *</label>
                                    <textarea type="text" name="message" value={message} onChange={(e) => onInputChange(e)} className="form-control" rows={5} />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                <button type="submit" className="btn-lg btn btn-primary radiustheme">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
        </>
    )
}

export default ContactUs;