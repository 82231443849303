import React from 'react'
import './ShimmerEffect.css';

export const ShimmerEffectNew = () => {
    return (
        <div className="shimmer">
            <div className="wrapper">
                <div className="image-card animate"></div>
                <div className="stroke animate title"></div>
                <div className="stroke animate link"></div>
                <div className="stroke animate description"></div>
            </div>
        </div>
    )
}


export const ShimmerEffect = ({ type, text }) => {
    const classes = `shimmer-wrapper Skeleton ${type}`;
    return (
        <>

            {type === "bixWithUsers" ?
                <div className='bixWithUsers'>
                    <div className="section_header text-center">
                        <h1>{text}</h1>
                        <div className='shimmer-wrapper Skeleton speaker'></div>
                        <div className='shimmer-wrapper Skeleton speaker'></div>
                        <div className='shimmer-wrapper Skeleton speaker'></div>
                        <div className='shimmer-wrapper Skeleton speaker'></div>
                    </div>
                </div>
                :
                type === "bigBoxSponsers" ?
                    <div className='bigBoxSponsers'>
                        <div className="section_header text-center">
                            <h1>{text}</h1>
                            <div className='shimmer-wrapper Skeleton Sponsers'></div>
                            <div className='shimmer-wrapper Skeleton Sponsers'></div>
                            <div className='shimmer-wrapper Skeleton Sponsers'></div>
                            <div className='shimmer-wrapper Skeleton Sponsers'></div>
                        </div>
                    </div> :
                    <div className={classes}></div>
            }


        </>
    )
}

