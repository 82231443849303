import { useState } from "react";
import { ShimmerEffect } from "../../../styleUI/ShimmerEffect";


const SponsorsImage = ({ index, imageurl, name }) => {
    const [isImgLoaded, setIsImgLoaded] = useState(false)
    return (
        <>
            <div key={index} className="item">
                <img style={{ display: 'none' }} src={imageurl} alt={name} onLoad={(e) => setIsImgLoaded(true)} onError={true} />
                {isImgLoaded === false ?
                    <ShimmerEffect type="sponsersCard" />
                    :
                    <img src={imageurl} alt={name} />
                }
            </div>
        </>
    )
}

export default SponsorsImage;