import { Footer } from "../commonSection/Footer"
import { Header } from "../commonSection/Header"
import { useEffect, useState } from "react"
import { LoaderSmall } from "../../.."
import BannerSlider from "./BannerSlider"
import NewsSection from "./NewsSection"
import RecentEvents from "./RecentEvents"
import GallerySection from "./GallerySection"
import FeedBackForm from "./FeedBack"
import { useGetAllSpeakerQuery } from "../../../../features/LoadSepeakers"
import SEO from "../commonSection/seo"


const HomePage = () => {
    const { data, isError, isLoading } = useGetAllSpeakerQuery()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    // First Time only show live events
    useEffect(() => {
        window.scrollTo(0, 0);
        if (isLoading === false) {
            if (data) {
                if (data.data.length !== 0) {
                    localStorage.setItem("evnid", data.data[0]._id)
                }
            }
        } else {

        }
    }, [isLoading])


    return (
        <>
        <SEO
        title='EM Sling - Empowering Emergency Medical Professionals'
        description='Welcome to EM Sling, where we unite emergency medical practitioners with the knowledge and expertise of seasoned experts. Discover our programs, events, and resources designed to enhance the capabilities and impact of healthcare professionals in emergency medicine.'
        keywords=''/>
            <div className="outerhomepage">
                {/*  */}
                <Header page="Mainpage" />
                {/* Banner Slider */}
                <BannerSlider />
                {/* News */}
                <NewsSection />
                {/* Past Events */}
                <RecentEvents />
                {/* Gallery */}
                <GallerySection />
                {/* FeedBack Form */}
                <FeedBackForm />
                <Footer type="homePage" />
            </div>

        </>
    )
}

export default HomePage;
