import { toast } from 'react-toastify';
// import { AccessToken } from '../components/AccessToken';
import axios from 'axios';
import { ErrorHandel } from '../middleware/Errors';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
// const API_Base_Url = "http://localhost:3006/api/payments"

export const PaymentPost = async (token, token2,objId,email) => {
    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const data = {
            token, token2,objId,email
        }

        console.log("paymentdata", data)
        const response = await axios.post(API_Base_Url + "payments", data, headers, { timeout: 5 }).then((response) => {
            // const response = await axios.post("http://localhost:3006/api/payments", data, headers, { timeout: 5 }).then((response) => {
            return response
        }
        ).catch(error => {
            if (error.response.status === 400) {
                if (error.response.data.Error.ErrorMessage !== undefined)
                    return toast.error(error.response.data.Error.ErrorMessage);
            }
            if (error.response.status === 404) {
                return toast.error("Sorry url not found");
            }
            if (error.response.status === 500) {
                if (error.response.data.Error.ErrorMessage !== undefined)
                    return toast.error(error.response.data.Error.ErrorMessage);
            }
        });
        return response.data
    } catch (error) {
        return toast.error('Something went wrong , Please check your internet connection.')
    }

};
export const RegistrationIfFree = async (data) => {
    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        const response = await axios.post(API_Base_Url + "web/register", data, headers, { timeout: 5 }).then((response) => {
            // const response = await axios.post("http://localhost:3006/api/web/register", data, headers, { timeout: 5 }).then((response) => {
            return response
        }
        ).catch(error => {
            if (error.response.status === 400) {
                if (error.response.data.Error.ErrorMessage !== undefined)
                    return toast.error(error.response.data.Error.ErrorMessage);
            }
            if (error.response.status === 404) {
                return toast.error("Sorry url not found");
            }
            if (error.response.status === 500) {
                if (error.response.data.Error.ErrorMessage !== undefined)
                    return toast.error(error.response.data.Error.ErrorMessage);
            }
        });
        return response.data
    } catch (error) {
        return toast.error('Something went wrong , Please check your internet connection.')
    }

};
