import React from 'react'
import { Link,useNavigate } from "react-router-dom"

export const DashboardBanner = ({ title, subTitle, eventDate, location, eventImage, RegistrationType, GoogleFormLink,amount }) => {
    const navigate = useNavigate()
    const handleRegisterNow = (e) => {
        e.preventDefault();
        navigate('/registration', { state: { amount: amount } });
    };


    return (
        <>
            <div className="FEcarouselsec">
                {/*Banner*/}
                <div className="featureEventbnr">
                    <img src={eventImage} alt={title} />
                    <div className="container">
                        <div className="row">
                            <div id="hideInMobile" className="caption caption_left caption_fancy  text-left">
                                <div className="inner black_section transparent_film animated fadeInUp">
                                    <h1 className="t1">{title}</h1>
                                    <div className="t2">{location}</div>
                                    <div className="t3">{eventDate}</div>
                                    <p className="desc hidden-xxs">{subTitle}</p>
                                    {/* last updated:- 02 August 2022 */}
                                 
                                    <div>
                                    {RegistrationType === "Google Form" ? (
                                            <a href={GoogleFormLink} target="_blank" className="btn-lg btn btn-primary">Register Now</a>
                                        ) : (
                                            <button onClick={handleRegisterNow} className="btn-lg btn btn-primary">Register Now!</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="ShowInMobile" className="FEcarouselsec">
                {/*Banner*/}
                <div className="featureEventbnr">
                    <div className="container">
                        <div className="row">
                            <div className="caption caption_left caption_fancy  text-left">
                                <div className="inner black_section transparent_film animated fadeInUp">
                                    <h1 className="t1">{title}</h1>
                                    <div className="t2">{location}</div>
                                    <div className="t3">{eventDate}</div>
                                    <p className="desc hidden-xxs">{subTitle}</p>
                                    {/* last updated:- 02 August 2022 */}
                                    <div>
                                        {RegistrationType === "Google Form" ?
                                            <a href={GoogleFormLink} target="_blank" className="btn-lg btn btn-primary">Register Now</a>
                                            :
                                            <Link onClick={(e)=> handleRegisterNow(e)}
                                             className="btn-lg btn btn-primary">Register Now!</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}