import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { LoaderSmall } from '../../..'
import { ForumBox, ForumBox2 } from "./ForumBox"

export const EventAgendaSection = ({ loaded, data }) => {
    const { id } = useParams()
    const location = useLocation()
    const [currentClass, setCurrentClass] = useState("tab-0")
    const [dataSessionDetails, setDataSessionDetails] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [widthSection, setWidthSection] = useState(0)

    useEffect(() => {
        setIsLoaded(false)
        if (location.pathname === '/feature-event' || location.pathname === "/event/" + id) {
            if (data.length !== 0) {
                const timer = setTimeout(() => {
                    setDataSessionDetails(data[0].Details)
                    setWidthSection(12 / data[0].Details.length)
                    setIsLoaded(true)
                }, 1000);
                return () => clearTimeout(timer);
            }
        }
        setIsLoaded(true)
    }, [data, dataSessionDetails, loaded])

    const onClickOnCurrent = (name) => {
        setCurrentClass(crrentVal => name)
    }
    return (
        <>
            <section className="speakers" id="EventAgenda">
                {/*Event Agenda*/}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="section_header text-center">
                                <h3>Event Agenda </h3>
                            </div>
                            <div className="our-partners">
                                {isLoaded === false ? <LoaderSmall /> :
                                    <div className="tab-container mt-30 customTabs">
                                        {dataSessionDetails.length === 0 ? <p className="text-center"> No event agenda found</p>
                                            :
                                            <>
                                                <ul className="tabs">
                                                    {dataSessionDetails.map((item, index) => (
                                                        <li key={index} className={currentClass === "tab-" + index ? `col-md-2 tab-link current` : `col-md-2 tab-link`} onClick={() => onClickOnCurrent("tab-" + index)} >{moment(new Date(item.Date)).format("DD MMMM")}</li>
                                                    ))}
                                                </ul>

                                                {dataSessionDetails.map((item, index) => (
                                                    <>
                                                        {currentClass === "tab-" + index &&
                                                            <div className={currentClass === "tab-" + index ? "tab-content current scrollbar minheight" : "tab-content "}>
                                                                {item.Sessions.length === 0 ?
                                                                    <div className='text-center'>
                                                                        <p>
                                                                            No sessions found
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    <ForumBox data={item.Sessions} title="One" />
                                                                }
                                                            </div>

                                                        }

                                                    </>
                                                ))}
                                            </>

                                        }
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}