import { toast } from 'react-toastify'

const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

// ---------------------Event Apis--------------------------------------------

export const CreateEvent = async (reqdata) => {
    try {
console.log(reqdata,'reqdata')
        const response = await fetch(API_Base_Url + "cms/event/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "title": reqdata.title,
                "subtitle": reqdata.subtitle,
                "timing": reqdata.timing,
                "startdate": reqdata.startdate,
                "location": reqdata.location,
                "enddate": reqdata.enddate,
                "starttime": reqdata.starttime,
                "eventimage": reqdata.eventimage,
                "eventvideo": reqdata.eventvideo,
                "sponsor": reqdata.sponser,
                "sponserlogo": reqdata.sponserlogo,
                "aboutevent": reqdata.aboutevent,
                "attachment": reqdata.attachment,
                "linkurl": reqdata.linkurl,
                "isEventFree": reqdata.isEventFree,
                "eventFee": reqdata.eventFee,
                "footerText": reqdata.footerText,
                "email": reqdata.email,
                "mobile": reqdata.mobile,
                "registration_type":reqdata.registration_type,
                "google_form_link":reqdata.google_form_link,
                'officeAddress': reqdata.officeAddress,
                'twitter': reqdata.twitter,
                'facebook': reqdata.facebook,
                'youtube': reqdata.youtube,
                'linkedin': reqdata.linkedin
            })
            
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {
        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const UpdateEvent = async (reqdata) => {
    try {
        const response = await fetch(API_Base_Url + "cms/event/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.eventid,
                "title": reqdata.title,
                "subtitle": reqdata.subtitle,
                "timing": reqdata.timing,
                "location": reqdata.location,
                "startdate": reqdata.startdate,
                "enddate": reqdata.enddate,
                "starttime": reqdata.starttime,
                "eventimage": reqdata.eventimage,
                "eventvideo": reqdata.eventvideo,
                "sponsor": reqdata.sponsor,
                "sponserlogo": reqdata.sponserlogo,
                "aboutevent": reqdata.aboutevent,
                "attachment": reqdata.attachment,
                "linkurl": reqdata.linkurl,
                "isEventFree": reqdata.isEventFree,
                "eventFee": reqdata.eventFee,
                "email": reqdata.email,
                "mobile": reqdata.mobile,
                'officeAddress': reqdata.officeAddress,
                "registration_type":reqdata.registration_type,
                "google_form_link":reqdata.google_form_link,
                'twitter': reqdata.twitter,
                'facebook': reqdata.facebook,
                'youtube': reqdata.youtube,
                'linkedin': reqdata.linkedin
                
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const LoadEventDetails = async (eventid) => {
    try {
        const response = await fetch(API_Base_Url + "cms/event/load?id=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
            // return window.setTimeout(function () {
            //     localStorage.clear();
            //     window.location.href = "/";
            // }, 1000);
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.Error.ErrorMessage)
            }
            else {

            }

        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const EventAction = async (id, status) => {
    try {
        const response = await fetch(API_Base_Url + "cms/event/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": id,
                "action": status
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

export const EventList = async () => {
    try {
        const response = await fetch(API_Base_Url + "cms/event/list", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        if (response.status === 401) {
            toast.error('Your Session has been expired, Please login again.');
        }
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined) {
                toast.error(result.Error.ErrorMessage)
            }
            else {

            }

        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};


// --------------------------------Schedule Apis---------------------------------
//#region Schedule SubEvent Apis
// Subevent list api
export const SubEventList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/subevent/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Sub Event Create
export const CreateSubEvent = async (reqdata) => {
    try {
        const response = await fetch(API_Base_Url + "cms/subevent/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "title": reqdata.title,
                "sequence": reqdata.sequence
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Sub Event
export const LoadSubevent = async (subeventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/subevent/load?id=" + subeventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Sub Event
export const UpdateSubEvent = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/subevent/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.subeventid,
                "title": reqdata.title,
                "sequence": reqdata.sequence
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Action Sub Event
export const SubEventAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/subevent/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.subeventid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion


//#region  Schedule Date Apis
// Date list api
export const DateList = async (subeventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/schedule/list?subeventid=" + subeventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Date  Create
export const CreateDate = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/schedule/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "subeventid": reqdata.subeventid,
                "date": reqdata.date,
                "day": reqdata.day
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Date
export const LoadDate = async (dateid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/schedule/load?id=" + dateid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Date
export const UpdateDate = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/schedule/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.dateid,
                "date": reqdata.date,
                "day": reqdata.day
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//  Date Action
export const DateAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/schedule/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.dateid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion


//#region  Schedule Session Apis
// Session list api
export const SessionList = async (dateid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/session/list?dateid=" + dateid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Session Create
export const CreateSession = async (reqdata) => {
    try {
        const response = await fetch(API_Base_Url + "cms/session/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "dateid": reqdata.dateid,
                "sessiondate": reqdata.sessiondate,
                "starttime": reqdata.starttime,
                "endtime": reqdata.endtime,
                "timing": reqdata.timing,
                "timezone": reqdata.timezone,
                "title": reqdata.title,
                "type": reqdata.type,
                "sequence": reqdata.sequence,
                "room": reqdata.room,
                "webinarurl": reqdata.webinarurl,
                "livestreamurl": reqdata.livestreamurl,
                "details": reqdata.details
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (response.statusText !== undefined)
                toast.error(response.statusText);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Session
export const LoadSession = async (sessionid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/session/load?id=" + sessionid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Session
export const UpdateSession = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/session/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.sessionid,
                "sessiondate": reqdata.sessiondate,
                "starttime": reqdata.starttime,
                "endtime": reqdata.endtime,
                "timing": reqdata.timing,
                "timezone": reqdata.timezone,
                "title": reqdata.title,
                "type": reqdata.type,
                "sequence": reqdata.sequence,
                "room": reqdata.room,
                "webinarurl": reqdata.webinarurl,
                "livestreamurl": reqdata.livestreamurl,
                "details": reqdata.details
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Delete Session
export const SessionAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/session/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.sessionid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

//#endregion



// --------------------------------Speakers Apis---------------------------------
//#region  Speakers Apis
// Speakers list api
export const SpeakerList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/speaker/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const OrganizersList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/organizer/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Speaker  Create
export const CreateSpeaker = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/speaker/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "name": reqdata.name,
                "organization": reqdata.orgn,
                "designation": reqdata.designation,
                "profilepic": reqdata.profilepic,
                "linkedinurl": reqdata.linkedinurl,
                "twitterurl": reqdata.twitterurl,
                "facebookurl": reqdata.facebookurl,
                "instagramurl": reqdata.instagramurl,
                "biodata": reqdata.biodata,
                "subevent": reqdata.subevent,
                "sequence": reqdata.sequence
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const CreateOrganizer = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/organizer/create", {
            // const response = await fetch("http://localhost:3006/api/cms/organizer/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "name": reqdata.name,
                "profilepic": reqdata.profilepic,
                "biodata": reqdata.biodata
            })
        })
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Speaker
export const LoadSpeaker = async (speakerid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/speaker/load?id=" + speakerid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const LoadOrganizer = async (organizerid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/organizer/load?id=" + organizerid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Speaker
export const UpdateSpeaker = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/speaker/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.speakerid,
                "name": reqdata.name,
                "organization": reqdata.orgn,
                "designation": reqdata.designation,
                "profilepic": reqdata.profilepic,
                "linkedinurl": reqdata.linkedinurl,
                "twitterurl": reqdata.twitterurl,
                "facebookurl": reqdata.facebookurl,
                "instagramurl": reqdata.instagramurl,
                "biodata": reqdata.biodata,
                "subevent": reqdata.subevent,
                "sequence": reqdata.sequence
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const UpdateOrganizer = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/organizer/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.organizerid,
                "name": reqdata.name,
                "profilepic": reqdata.profilepic,
                "biodata": reqdata.biodata,

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Actions Speaker
export const SpeakerAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/speaker/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.speakerid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const OrganizersAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/organizer/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.organizersId,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion



// --------------------------------Partners Apis---------------------------------
//#region  Partners Apis
// Partners list api
export const PartnerList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/partner/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Partners  Create
export const CreatePartner = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/partner/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "name": reqdata.name,
                "photo": reqdata.photo,
                "url": reqdata.url
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Partners
export const LoadPartner = async (partnerid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/partner/load?id=" + partnerid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 500) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Partners
export const UpdatePartner = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/partner/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.partnerid,
                "name": reqdata.name,
                "photo": reqdata.photo,
                "url": reqdata.url
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Actions Partners
export const PartnerAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/partner/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.partnerid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion

// --------------------------------Exhibition Apis---------------------------------
//#region  Exhibition Apis
// Exhibition list api
export const ExhibitionList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Exhibition  Create
export const CreateExhibition = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "title": reqdata.title,
                "description": reqdata.description,
                "image": reqdata.image,
                "video": reqdata.video,
                "boothimage": reqdata.boothimage,
                "boothlink": reqdata.boothlink,
                "boothemail": reqdata.boothemail,
                "boothfblink": reqdata.boothfblink
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Exhibition
export const LoadExhibition = async (exhibitionid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition/load?id=" + exhibitionid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Exhibition
export const UpdateExhibition = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.exhibitionid,
                "title": reqdata.title,
                "description": reqdata.description,
                "image": reqdata.image,
                "video": reqdata.video,
                "boothimage": reqdata.boothimage,
                "boothlink": reqdata.boothlink,
                "boothemail": reqdata.boothemail,
                "boothfblink": reqdata.boothfblink
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Actions Exhibition
export const ExhibitionAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.exhibitionid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion

// --------------------------------Exhibition Media Apis---------------------------------
//#region  Exhibition Media Apis
// Exhibition Media list api
export const ExhibitionMediaList = async (exhibitionid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition_media/list?exhibitionid=" + exhibitionid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Exhibition Media  Create
export const CreateExhibitionMedia = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition_media/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "exhibitionid": reqdata.exhibitionid,
                "type": reqdata.type,
                "title": reqdata.title,
                "url": reqdata.url
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Media Exhibition
export const LoadExhibitionMedia = async (exhibitionmediaid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition_media/load?id=" + exhibitionmediaid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Media Exhibition
export const UpdateExhibitionMedia = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition_media/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.exhibitionmediaid,
                "type": reqdata.type,
                "title": reqdata.title,
                "url": reqdata.url
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Actions Exhibition Media
export const ExhibitionMediaAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/exhibition_media/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.exhibitionmediaid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion


// --------------------------------Side Event Apis---------------------------------
//#region  Side Event Apis
// Side Event list api
export const SideEventList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/sideevent/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Side Event Create
export const CreateSideEvent = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/sideevent/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "type": reqdata.type,
                "title": reqdata.title,
                "url": reqdata.url,
                "thumbimg": reqdata.thumbimg,
                "tags": reqdata.tags
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Side Event
export const LoadSideEvent = async (sideeventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/sideevent/load?id=" + sideeventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Side Event
export const UpdateSideEvent = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/sideevent/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.sideeventid,
                "type": reqdata.type,
                "title": reqdata.title,
                "url": reqdata.url,
                "thumbimg": reqdata.thumbimg,
                "tags": reqdata.tags
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Actions Side Event
export const SideEventAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/sideevent/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.sideeventid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion


// --------------------------------Scribble Tags Apis---------------------------------
//#region  Tags Apis
// Tags list api
export const ScribbleTagsList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribbletag/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Tags  Create
export const CreateScribbleTags = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribbletag/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "title": reqdata.title
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Tags
export const LoadScribbleTags = async (tagid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribbletag/load?id=" + tagid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Tags
export const UpdateScribbleTags = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribbletag/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.tagid,
                "title": reqdata.title

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Actions Tags
export const ScribbleTagsAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribbletag/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.tagid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion

// --------------------------------Scribble Post  Apis---------------------------------
//#region  Scribble Post  Apis
//Scribble Post  list api
export const ScribblePostList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribblepost/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Scribble Post  Create
export const CreateScribblePost = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribblepost/create", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "eventid": reqdata.eventid,
                "title": reqdata.title,
                "post": reqdata.post,
                "postedbyname": reqdata.postedbyname,
                "postedbyemail": reqdata.postedbyemail
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Load Scribble Post 
export const LoadScribblePost = async (postid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribblepost/load?id=" + postid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Update Scribble Post 
export const UpdateScribblePost = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribblepost/update", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.postid,
                "title": reqdata.title,
                "post": reqdata.post,
                "postedbyname": reqdata.postedbyname,
                "postedbyemail": reqdata.postedbyemail
            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};

// Actions Scribble Post 
export const ScribblePostAction = async (reqdata) => {
    try {

        const response = await fetch(API_Base_Url + "cms/scribblepost/action", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "_id": reqdata.postid,
                "action": reqdata.action

            })
        })
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion


//#region  Registration
export const RegistrationList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "cms/event/registrations?eventid=" + eventid, {
            // const response = await fetch("http://localhost:3006/api/cms/event/registrations?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const ContactUsList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "contactus/list", {
            // const response = await fetch("http://localhost:3006/api/cms/event/registrations?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const FeedbackList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "feedback/list", {
            // const response = await fetch("http://localhost:3006/api/cms/event/registrations?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
export const PaymentsList = async (eventid) => {
    try {

        const response = await fetch(API_Base_Url + "payments/list?eventid=" + eventid, {
            // const response = await fetch("http://localhost:3006/api/payments/list?eventid=" + eventid, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            }
        })
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (result.Error !== undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else if (response.status === 404) {
            // if (result.Error !== undefined)
            toast.error("Url Not found");
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
//#endregion