import React from "react";
import { LoginFormComponent } from "./LoginFormComponent";

/*
CreatedBy: Mayur 
CreatedOn: 05 Jan 2022
Description : Admin Login Code
*/
const AdminLogin = () => {

    return (
        <div>
            <div className="login-box cmslogin" >
                <div className="login-logo">
                    <a href="login.aspx"> <b>EM Sling 2022</b></a>
                </div>
                <div className="login-box-body" style={{ border: "1px solid #d2d6de" }}>
                    <p className="login-box-msg">Content Management System <br /><b>Login</b></p>
                    <LoginFormComponent />
                </div>
            </div>
        </div>
    )
}

export default AdminLogin;