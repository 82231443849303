import React from "react";
import { ShimmerEffect } from '../../../styleUI/ShimmerEffect'


export const OrganizersModalPopup = ({ imageUrl, isLoaded, organizerName, biodata }) => {

    return (
        <div className="speaker_disc_model">
            <div className="modal fade modal-fullscreen  footer-to-bottom" id="OrganizerDetailsModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="secondary_section">
                                            <div className="profile_single_photo col-md-4">
                                                <div className="stretchy_wrapper ratio_1-1">
                                                    {isLoaded === false ?
                                                        <ShimmerEffect type="avatarPopup" />
                                                        :
                                                        <img src={imageUrl} alt={organizerName} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="section_header xbold">
                                                    {isLoaded === false ?
                                                        <>
                                                            <ShimmerEffect type="Modaltitle" />
                                                            <ShimmerEffect type="description" />
                                                            <ShimmerEffect type="descriptionSecondLine" />
                                                            <ShimmerEffect type="descriptionThirdLine" />
                                                        </>
                                                        :
                                                        <>
                                                            <h1 className="post_title">{organizerName}</h1>
                                                            <p dangerouslySetInnerHTML={{ __html: biodata }}></p>
                                                        </>
                                                    }
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}