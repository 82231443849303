import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { OrganizersAction, OrganizersList, SpeakerAction } from '../../services/Event';
import Organizersform from "./popup/AddOrganizerPopup";
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../features/Counter";



/* CreatedBy: Mayur
CreatedOn: 20 Apr 2022
Description : Feedback 
*/
function OrganizersTeamMember() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [selectedorganizersId, setOrganizers] = useState("");
    const [popuptype, setPopuptype] = useState("");
    const [organizersList, setOrganizersList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [number, setNumber] = useState(0);
    const eventName = localStorage.getItem("EventName")

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])

    useEffect(() => {
        loadOrganizerss();
    }, [offset, number]);


    const openOrganizersForm = () => {
        setOrganizers("0");
        setPopuptype("createOrganizer");
        localStorage.setItem("popuptype", "createOrganizer");
        window.$("#organizersFormAdd").modal('show');
    }

    const loadOrganizerss = () => {
        setIsLoaded(false);
        OrganizersList(selectedeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setOrganizersList([])
                }
                else {
                    const data = result.data;
                    if (data.length > 0) {
                        setOrganizersList(result.data);
                    }
                }
            }
            else {
                setOrganizersList([])
            }
            setIsLoaded(true);
        }).catch();
    }

    function ChangeStatus(organizerid, status) {
        var updatestatus = status ? "Deactivate" : "Activate"
        const req = {
            organizersId: organizerid,
            action: updatestatus
        }
        OrganizersAction(req).then(res => {
            if (res !== undefined) {
                toast.success(res.message)
            }
        })
        loadOrganizerss();
        setNumber(number + 1);
    }

    function Delete(organizersid) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this Organizer Team Member?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const req = {
                            organizersId: organizersid,
                            action: "Delete"
                        };
                        OrganizersAction(req).then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                            }
                            const timer = setTimeout(() => {
                                loadOrganizerss();
                                setNumber(number + 1);
                            }, 300);
                            return () => clearTimeout(timer);
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadOrganizerss()
                }
            ]
        });
    }


    const loadOrganizersDetails = (organizersId) => {
        setOrganizers(organizersId)
        setPopuptype("editorganizers");
        window.$("#organizersFormUpdate").modal('show');
    }
    const Cancelclick = (e) => {
        navigate("/admin-events")
        localStorage.setItem("screentype", "create");
        dispatch(decrement())
    }

    const gotoEventDetails = (e) => {
        navigate("/admin/event-add")
    }


    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper cms">
                    <section className="content-header">
                        <ol class="breadcrumbCustom">
                            <li><button onClick={Cancelclick}><i class="fa fa-dashboard"></i>Event List</button></li>
                            <li><button onClick={gotoEventDetails}>{eventName} </button></li>
                            <li><button >Organizing Team</button></li>
                        </ol>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box box-primary">
                                    <div className="box-header with-border">
                                        <h3 class="box-title">Organizing Team List</h3>
                                        <button className="btn btn-sm btn-primary pull-right" onClick={(e) => openOrganizersForm()}><i className="fa fa-plus"></i> Add New</button>
                                    </div>
                                    <div className="box-body ">
                                        <div id="speakers" className=" ippftable">
                                            <div className="no-padding table-responsive">
                                                <table className="table table-striped table-bordered">
                                                    <tbody>
                                                        <tr>

                                                            <th style={{ width: "10%" }}>Name</th>
                                                            <th style={{ width: "10%" }}>Photo</th>
                                                            <th style={{ width: "40%" }}>Bio Data</th>

                                                            <th style={{ width: "5%" }}></th>
                                                            <th style={{ width: "5%" }}></th>
                                                        </tr>
                                                        {
                                                            isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                                                :
                                                                <>
                                                                    {
                                                                        organizersList.length === 0 ?
                                                                            <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                            :
                                                                            organizersList.map((item, i) => (
                                                                                <tr>


                                                                                    <td className="titlelink" onClick={() => loadOrganizersDetails(item._id)} >{item.Name}</td>

                                                                                    <td>{item.Photo !== "" ? <img src={item.Photo} width="50px" /> : ""}</td>

                                                                                    <td ><p dangerouslySetInnerHTML={{ __html: item.Biodata.length > 120 ? item.Biodata.slice(0, 120) + " ..." : item.Biodata }}></p></td>

                                                                                    <td><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className={item.IsActive ? "btn btn-xs btn-info b-radius" : "btn btn-xs btn-success b-radius"}>{item.IsActive ? "Active" : "Inactive"}</button></td>
                                                                                    <td><button className="btn btn-xs btn-danger" onClick={(e) => Delete(item._id)} >Delete</button></td>
                                                                                </tr>
                                                                            ))
                                                                    }
                                                                </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    {/* <Footer total={organizersList.length} setOffset={setOffset} perpage={perPage} pageCount={pageCount} /> */}
                </div>

            </div>

            <div id="organizersFormAdd" className="modal fade" style={{ height: "100%" }}>
                <Organizersform eventid={selectedeventid} popuptype={popuptype} organizerid={selectedorganizersId} loadOrganizerss={loadOrganizerss} sequenceno={Number(organizersList.length) + 1} />
            </div>
            <div id="organizersFormUpdate" className="modal fade" style={{ height: "100%" }}>
                <Organizersform eventid={selectedeventid} popuptype={popuptype} organizerid={selectedorganizersId} loadOrganizerss={loadOrganizerss} sequenceno={Number(organizersList.length) + 1} />
            </div>
        </div>


    );

}
export default OrganizersTeamMember;