import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../../features/Counter";


function AdminSideBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()
    const token = localStorage.getItem("token")

    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [loggedIn, setLoggedIn] = useState(token ? true : false)
    const [selectedtab, setSelectedTab] = useState(localStorage.getItem("landpage"));
    // const [selectedtab, setSelectedTab] = useState("");
    const [subtab, setSubTab] = useState(localStorage.getItem("subtab"));
    // const [subtab, setSubTab] = useState("");
    const [number, setNumber] = useState(0);
    const [tab, setTab] = useState("");
    const isShowSidebar = useSelector((state) => state.value.sidebar)

    useEffect(() => {
        
        if (location.pathname === "/admin-events")
            setSubTab("eventlist")
        else if (location.pathname === "/admin/event-add")
            setSubTab("eventlist")
    }, [location.pathname])

    useEffect(() => {
        if (subtab !== "schedules" && subtab !== "speakers" && subtab !== "organisers" && subtab !== "partners" && subtab !== "registrations" && subtab !== "payments") {
            if (localStorage.getItem("screentype") === "edit")
                setSubTab("eventdetails");
        }
    }, [subtab, localStorage.getItem("screentype")])

    //#region Event Tab Actions
    const gotoeventmanagement = () => {
        setSelectedTab("event");
        setTab("event");
    }

    const gotoeventlist = () => {
        setSubTab("eventlist")
        dispatch(decrement())
        navigate("/admin-events")
    }
    const gotoaddevent = () => {

        setSubTab("addnewevent")
        navigate("/admin/event-add")
    }
    const gotoeventdetails = () => {
        setSubTab("eventdetails")
        navigate("/admin/event-add")
    }
    const gotoschedule = () => {
        setSubTab("schedules")
        navigate("/admin/event-schedule")
    }

    const gotospeakers = () => {
        setSubTab("speakers")
        navigate("/admin/event-speakers")
    }

    const gotopartners = () => {
        setSubTab("partners")
        navigate("/admin/event-partners")
    }

    const gotoOrganizerlist = () => {
        localStorage.setItem("subtab", "organisers")
        setSubTab("organisers")
        navigate("/admin/event-organizer")
    }

    const gotoregistration = () => {

        setSubTab("registrations")
        navigate("/admin/event-registration")
    }
    const gotoContacts = () => {
        setSubTab("contact")
        navigate("/admin/events-contacts")
    }
    const gotoNews = () => {
        setSubTab("news")
        navigate("/admin/events-news")
    }
    const gotoGallery = () => {
        setSubTab("gallery")
        navigate("/admin/events-gallery")
    }
    const gotoFeedback = () => {
        setSubTab("feedback")
        navigate("/admin/events-feedback")
    }
    const gotoPayments = () => {
        setSubTab("payments")
        navigate("/admin/events-payments")
    }
    const Cancelclick = (e) => {
        setSubTab("eventlist")
        navigate("/admin-events")
        localStorage.setItem("screentype", "create");
        dispatch(decrement())
    }

    const gotoOrganizers = () =>{
        setSubTab("organizers")
        navigate("/admin/events-organizers")
    }

    return (
        <>
            <aside className="main-sidebar" >
                <section className="sidebar">

                    <ul className="sidebar-menu" data-widget="tree">
                        <li className="header">ADMIN MENU</li>
                        <li className={`${(tab === "event" || selectedtab === "event") ? "treeview active" : "treeview"}`} onClick={(e) => gotoeventmanagement()}>
                            <a style={{ cursor: 'pointer', }}>
                                <span>Event Management</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </a>
                            {
                                isShowSidebar === 0 ? <ul className="treeview-menu">
                                    <li className={`${subtab === "eventlist" ? "active" : ''}`} onClick={(e) => gotoeventlist()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Event List</a></li>
                                    <li className={`${subtab === "addnewevent" ? "active" : ''}`} onClick={(e) => gotoaddevent()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Add New Event</a></li>
                                    <li className={`${subtab === "contact" ? "active" : ''}`} onClick={(e) => gotoContacts()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Contacts</a></li>
                                    <li className={`${subtab === "news" ? "active" : ''}`} onClick={(e) => gotoNews()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> News</a></li>
                                    <li className={`${subtab === "gallery" ? "active" : ''}`} onClick={(e) => gotoGallery()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Gallery</a></li>
                                    <li className={`${subtab === "feedback" ? "active" : ''}`} onClick={(e) => gotoFeedback()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Feedback</a></li>
                                    <li className={`${subtab === "organizers" ? "active" : ''}`} onClick={(e) => gotoOrganizers()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Organizers</a></li>
                                </ul> :
                                    <>
                                        <ul className="treeview-menu">
                                            {/* <li onClick={(e)=>gotoback()}><a style={{ cursor: 'pointer', fontSize: "12px" }}>Back</a></li> */}
                                            <li className={`${subtab === "eventdetails" ? "active" : ''}`} onClick={(e) => gotoeventdetails()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Event Details</a></li>
                                            <li className={`${subtab === "schedules" ? "active" : ''}`} onClick={(e) => gotoschedule()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Schedules/Agenda</a></li>
                                            <li className={`${subtab === "speakers" ? "active" : ''}`} onClick={(e) => gotospeakers()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Speakers</a></li>
                                            <li className={`${subtab === "organisers" ? "active" : ''}`} onClick={(e) => gotoOrganizerlist()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Organizing Team</a></li>
                                            <li className={`${subtab === "partners" ? "active" : ''}`} onClick={(e) => gotopartners()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Sponsors</a></li>
                                            <li className={`${subtab === "registrations" ? "active" : ''}`} onClick={(e) => gotoregistration()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Registrations</a></li>
                                            <li className={`${subtab === "payments" ? "active" : ''}`} onClick={(e) => gotoPayments()}><a style={{ cursor: 'pointer', }}><i className="fa fa-circle-o"></i> Payments</a></li>
                                        </ul>
                                        <div>

                                            <div className="bottomBtn">
                                                <button onClick={(e) => Cancelclick()} className="btn btn-primary">Back to Event List</button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </li>
                    </ul>
                </section>
            </aside>



        </>
    );
}
export default AdminSideBar;