import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { SpeakerList, CreateSpeaker, LoadSpeaker, UpdateSpeaker, SpeakerAction } from '../../services/Event';
import Speakersform from "./popup/AddSpeakerpopup";
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../features/Counter";
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;




function SpeakersAdmin() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [selectedspeakerid, setSpeakerid] = useState("");
    const [popuptype, setPopuptype] = useState("");
    const [speakerList, setSpeakerList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [number, setNumber] = useState(0);
    const eventName = localStorage.getItem("EventName")

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])


    useEffect(() => {
        loadSpeakers();
    }, [offset, number]);


    const openspeakerformAdd = () => {
        setSpeakerid("0");
        setPopuptype("createspeaker");
        localStorage.setItem("popuptype", "createspeaker");
        window.$("#speakerformAdd").modal('show');
    }


    const loadSpeakers = () => {
        setIsLoaded(false);
        SpeakerList(selectedeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setSpeakerList([])
                }
                else {
                    const data = result.data;

                    if (data.length > 0) {
                        setSpeakerList(result.data);

                    }
                }
            }
            else {
                setSpeakerList([])
            }
            setIsLoaded(true);
        }).catch();
        
    }

    function ChangeStatus(speakerid, status) {
        var updatestatus = status ? "Deactivate" : "Activate"
        const req = {
            speakerid: speakerid,
            action: updatestatus
        }
        SpeakerAction(req).then(res => {
            if (res !== undefined) {
                toast.success(res.message)
            }
        })
        loadSpeakers();
        setNumber(number + 1);
    }

    function Delete(speakerid) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this speaker?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        const req = {
                            speakerid: speakerid,
                            action: "Delete"

                        };
                        SpeakerAction(req).then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                                loadSpeakers();
                            }
                        })
                        loadSpeakers();
                        setNumber(number + 1);
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadSpeakers()
                }
            ]
        });
    }

    const loadSpeakerDetails = (speakerid) => {
        setSpeakerid(speakerid)
        setPopuptype("editspeaker");
        window.$("#speakerformUpdate").modal('show');
    }
    const Cancelclick = (e) => {
        navigate("/admin-events")
        localStorage.setItem("screentype", "create");
        dispatch(decrement())
    }

    const gotoEventDetails = (e) => {
        navigate("/admin/event-add")
    }


    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">

                <div className="content-wrapper cms">
                    <section className="content-header">
                        <ol class="breadcrumbCustom">
                            <li><button onClick={Cancelclick}><i class="fa fa-dashboard"></i>Event List</button></li>
                            <li><button onClick={gotoEventDetails} >{eventName} </button></li>
                            <li><button >Speakers</button></li>
                        </ol>
                        <div className="row">
                            <div className="col-md-12">


                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box box-primary">
                                    <div className="box-header with-border">
                                        <h3 class="box-title">Speakers List</h3>
                                        <button className="btn btn-sm btn-primary pull-right" onClick={(e) => openspeakerformAdd()}><i className="fa fa-plus"></i> Add New</button>
                                    </div>
                                    <div className="box-body">
                                        <div id="speakers" className=" ippftable">
                                            <div className="no-padding table-responsive">
                                                <table className="table table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Photo</th>
                                                            <th>Designation</th>
                                                            <th>Subevent</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                        {
                                                            isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                                                :
                                                                <>
                                                                    {
                                                                        speakerList.length === 0 ?
                                                                            <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                            :
                                                                            speakerList.map((item, i) => (
                                                                                <tr>
                                                                                    <td className="titlelink" onClick={() => loadSpeakerDetails(item._id)} >{item.Name}</td>
                                                                                    <td>{item.ProfilePic ? <img src={item.ProfilePic.substr(0, 37) === "https://saroforums2021.org/api/static" ? API_Base_Url + "static" + item.ProfilePic.substr(37) : item.ProfilePic} width="50px" /> : ""}</td>
                                                                                    <td>{item.Designation}</td>
                                                                                    <td>{item.SubEvent}</td>
                                                                                    <td><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className={item.IsActive ? "btn btn-xs btn-info b-radius" : "btn btn-xs btn-success b-radius"}>{item.IsActive ? "Active" : "Inactive"}</button></td>
                                                                                    <td><button className="btn btn-xs btn-danger" onClick={(e) => Delete(item._id)} >Delete</button></td>
                                                                                </tr>

                                                                            ))
                                                                    }
                                                                </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>
                    {/* <Footer total={speakerList.length} setOffset={setOffset} perpage={perPage} pageCount={pageCount} /> */}
                </div>

            </div>
            <div id="speakerformAdd" className="modal fade" style={{ height: "100%" }}>
                <Speakersform eventid={selectedeventid} popuptype={popuptype} speakerid={selectedspeakerid} loadSpeakers={loadSpeakers} sequenceno={Number(speakerList.length) + 1} />
            </div>
            <div id="speakerformUpdate" className="modal fade" style={{ height: "100%" }}>
                <Speakersform eventid={selectedeventid} popuptype={popuptype} speakerid={selectedspeakerid} loadSpeakers={loadSpeakers} sequenceno={Number(speakerList.length) + 1} />
            </div>
        </div>


    );

}
export default SpeakersAdmin;