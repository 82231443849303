import React from "react";
import { useEffect } from "react";
import { ShimmerEffect } from '../../../styleUI/ShimmerEffect'
import $ from 'jquery';

export const NewsPopUp = ({ imageUrl, isLoaded, title, date, details }) => {
    return (
        <div className="speaker_disc_model">
            <div className="modal fade modal-fullscreen  footer-to-bottom" id="ViewNewsPopup" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="secondary_section">
                                    <div className="profile_single_photo col-md-4">
                                        <div className="stretchy_wrapper">
                                            {isLoaded === false ?
                                                <ShimmerEffect type="avatarPopup" />
                                                :
                                                <img style={{ borderRadius: "unset" }} src={imageUrl} alt={title} />
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-8 mt-140">
                                        <div className="newspostdetail">
                                            {isLoaded === false ?

                                                <div className="section_header xbold">
                                                    <ShimmerEffect type="Modaltitle" />
                                                    <ShimmerEffect type="title" />
                                                    <ShimmerEffect type="description" />
                                                    <ShimmerEffect type="descriptionSecondLine" />
                                                    <ShimmerEffect type="descriptionThirdLine" />
                                                </div>
                                                :
                                                <>
                                                    <div className="">
                                                        <h1 className="post_title">{title}</h1>
                                                        <p className="post_subtitle">{date}</p>
                                                    </div>
                                                    <div className="post_desc" style={{ color: "#fff" }} dangerouslySetInnerHTML={{ __html: details }}></div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}