import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

const BannerSlider = () => {
    return (
        <section className="carouseltop">
            {/*Banner*/}
            <div className="bannerslider">
                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to={0} className="active" />
                        <li data-target="#myCarousel" data-slide-to={1} />
                        <li data-target="#myCarousel" data-slide-to={2} />
                    </ol>
                    {/* Wrapper for slides */}
                    <div className="carousel-inner">
                        <div className="item active">
                            <div className='overlay'></div>
                            <img src="images/welcome-background-c.jpg" alt="welcome-background-c"  />
                            <div className="carousel-caption">
                                <h3>WELCOME TO <br/> EM Sling  </h3>
                                {/* <p>Emirates Society of Emergency Medicine </p> */}
                                <div className="carousel-btn"><Link to="/about">About EM Sling</Link></div>
                            </div>
                        </div>
                        <div className="item">
                        <div className='overlay'></div>
                            <img src="images/Homebanner.jpg" alt />
                            <div className="carousel-caption">
                                <h3>WELCOME TO <br/> EM Sling </h3>
                                {/* <p>Emirates Society of Emergency Medicine </p> */}
                                <div className="carousel-btn"><Link to="/newslist">News & Feeds</Link></div>
                            </div>
                        </div>
                        <div className="item">
                        <div className='overlay'></div>
                            <img src="images/Homebanner.jpg" alt />
                            <div className="carousel-caption">
                                <h3>WELCOME TO <br/> EM Sling  </h3>
                                {/* <p>Emirates Society of Emergency Medicine </p> */}
                                <div className="carousel-btn"><Link to="/archive-events">Archive Events</Link></div>
                            </div>
                        </div>
                    </div>
                    {/* Left and right controls */}
                    <a className="left carousel-control" href="#myCarousel" data-slide="prev" >
                        <span className="fa fa-chevron-left" />
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="right carousel-control" href="#myCarousel" data-slide="next">
                        <span className="fa fa-chevron-right" />
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </section>

    )
}

export default BannerSlider;