import { useEffect, useState } from "react";
import { LoadList } from "../../../services/LoadList";
import { Link } from "react-router-dom";
import moment from "moment";
import { NewsPopUp } from "./NewsPopup";

{/* Begin News */ }
const NewsSection = () => {
    const [isloaded, setIsLoaded] = useState(false)
    const [newList, setNewList] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [title, setTitle] = useState("")
    const [details, setDetails] = useState("")
    const [image, setImage] = useState("")
    const [postedOn, setPostedOn] = useState("")


    useEffect(() => {
        LoadNewsList()
    }, [])

    const LoadNewsList = () => {
        setIsLoaded(false)
        const url = "news/list"
        LoadList(url).then(res => {
            const result = res;
            if (result !== undefined) {
                if (!result.data) {
                    return setNewList([])
                }
                else {
                    const data = result.data;
                    setTotalData(data.length)
                    return setNewList(data)
                }
            }
            else {
                setNewList([])
            }
            setIsLoaded(true);
        }).catch();
    }
    const viewMoreDeatails = (title, details, image, postedOn) => {
        window.$("#ViewNewsPopup").modal('show');
        setTitle(title)
        setDetails(details)
        setImage(image)
        setPostedOn(postedOn)
    }

    return (
        <>
            {newList.length > 0 ?
                <section className="padding100">
                    <div className="container-fluid">
                        <div className="section-title">
                            <h2>News & Feeds</h2>
                        </div>
                        <div className="insight-list">
                            {newList.length === 0 ? <div className="text-center">No news found</div>
                                :
                                <div className="row">
                                    {newList.slice(0, 3).map((item, index) => (
                                        <>
                                            <div className="col-md-4">
                                                <div className="insight-box">
                                                    <div className="widget-imgbox">
                                                        <img src={item.Image} width="100%" alt="EM Sling News"/>
                                                    </div>
                                                    <div className="widget-textbox">
                                                        <div className="widget-date"><i className="fa fa-calendar" /> {moment(new Date(item.PostedOn)).format("DD MMM,YYYY hh:MM A")}</div>
                                                        <h4 className="widget-title">{item.Title.length > 45 ? item.Title.slice(0, 45) + "..." : item.Title}</h4>
                                                        {/* <p>{item.Details}</p> */}
                                                        <button onClick={() => viewMoreDeatails(item.Title, item.Details, item.Image, item.PostedOn)} className="viewmore">View More</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            }
                        </div>
                        {newList.length > 3 &&
                            <div className="text-center">
                                <Link to="/newslist" className="btn btn-primary">View All</Link>
                            </div>
                        }
                    </div>

                    <NewsPopUp imageUrl={image} isLoaded={true} title={title} date={moment(new Date(postedOn)).format("MMM d,YYYY")} details={details} />
                    


                </section>
                :
                ""
            }

        </>

    )
}

export default NewsSection;