import React from "react";
import { ShimmerEffect } from '../../../styleUI/ShimmerEffect'


export const SpeakersModalPopUp = ({ imageUrl, isLoaded, speakername, designation, aboutSpeaker }) => {

    return (
        <div className="speaker_disc_model">
            <div className="modal fade modal-fullscreen  footer-to-bottom" id="SpeakerDetailsModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div className="modal-body">                          
                            <div className="row">
                                <div className="secondary_section">
                                    <div className="profile_single_photo col-md-4">
                                        <div className="stretchy_wrapper ratio_1-1">
                                            {isLoaded === false ?
                                                <ShimmerEffect type="avatarPopup" />
                                                :
                                                <img src={imageUrl} alt={speakername} />
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        {isLoaded === false ?

                                            <div className="section_header xbold">
                                                <ShimmerEffect type="Modaltitle" />
                                                <ShimmerEffect type="title" />
                                                <ShimmerEffect type="description" />
                                                <ShimmerEffect type="descriptionSecondLine" />
                                                <ShimmerEffect type="descriptionThirdLine" />
                                            </div>
                                            :
                                            <>
                                                <div className="section_header xbold">
                                                    <h1 className="post_title">{speakername}</h1>
                                                    <p className="post_subtitle">{designation}</p>

                                                </div>
                                                <div style={{ color: "#fff" }} dangerouslySetInnerHTML={{ __html: aboutSpeaker }}></div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>                               
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}