import React from 'react'
import { DashboardBanner } from "./Banner"
import { TriangularDownhill, TriangularUphill } from "./BlueBoxesSection"
import { WelcomeSection } from "./WelcomeSection"
import { Link } from 'react-scroll'


export const Section = ({ title, subTitle, eventDate, aboutEvent, location, eventImage, RegistrationType, GoogleFormLink,amount }) => {
    return (
        <section className=" ">

            {/* Dashboard Banner */}
            <DashboardBanner eventImage={eventImage} RegistrationType={RegistrationType} GoogleFormLink={GoogleFormLink} title={title} subTitle={subTitle} eventDate={eventDate} aboutEvent={aboutEvent} location={location} amount={amount} />

            <div className="boxes_section elevate no_cols_padding ">
                <div className="container">
                    <div className="row">
                        <Link activeclassName="active" to="EventAgenda" spy={true} >
                            <TriangularDownhill imagename="CONFERENCE-HALL_w-1.webp" title="Event Agenda" subtitle="" />
                        </Link>

                        <Link to="Speakers" spy={true} >
                            <TriangularUphill imagename="MANAGEMET_w-1.webp" title="Speakers" subtitle="" />
                        </Link>

                        <Link to="Sponsors" spy={true}>
                            <TriangularDownhill imagename="CREATIVE-BRIEF_w-1.webp" title="Sponsors" subtitle="" />
                        </Link>

                        <Link to="OrgTeam" spy={true} >
                            <TriangularUphill imagename="COMMENT_w-1.webp" title="Organizing Team" subtitle="" />
                        </Link>
                    </div>
                </div>
            </div>
            <div id='Welcomsection'>
                <WelcomeSection aboutEvent={aboutEvent} title={title} />
            </div>
        </section>
    )
}