export const ValidateEmail = (inputText) => {   
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (inputText.match(mailformat)) {
        return true;
    }
    else
        return false;
}


var numberPattern = /\d+/g;


export const validateIsNumber = (s) => {

    var rgx = /^[0-9]*\.?[0-9]*$/;

    return s.match(rgx);
}