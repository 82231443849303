import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { UploadFile } from '../../services/UploadFile';
import { CreateEvent, UpdateEvent, LoadEventDetails } from '../../services/Event';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from 'moment';
import { HtmlValMsg } from '../../Inputs/TextInput';
import { validateIsNumber } from '../../middleware/ValidateEmail';
import { useSelector, useDispatch } from "react-redux";

import { decrement } from "../../../features/Counter";
import { compose } from '@reduxjs/toolkit';
const reg = /<(.|\n)*?>/g;





/* CreatedBy: Mayur
CreatedOn: 10 Jan 2022
Description : Add Events
*/
function AddEvent() {
    const navigate = useNavigate();
    const [screentype, setScreentype] = useState(localStorage.getItem("screentype"));
    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [errors, setError] = useState({});
    const [eventtitle, setEventTitle] = useState("");
    const [eventsubtitle, setEventSubTitle] = useState("");
    const [eventtiming, setEventTiming] = useState("");
    const [eventLocation, setEventLocation] = useState("");
    const [eventfromdate, setEventfromDate] = useState("");
    const [eventtodate, setEventToDate] = useState("");
    const [eventstarttime, setEventStartTime] = useState("");
    const [eventimage, setEventImage] = useState("");
    const [eventvideo, setEventVideo] = useState("");
    const [eventsponser, setEventSponser] = useState("");
    const [sponserlogo, setEventSponserLogo] = useState("");
    const [aboutevent, setEventAbout] = useState("");
    const [ismessage, setDirectorMessage] = useState("");
    const [mdname, setMDName] = useState("");
    const [mddesig, setMDDesig] = useState("");
    const [mdtext, setMDText] = useState("");
    const [mdimage, setMDImage] = useState("");
    const [attachment, setAttachment] = useState("");
    const [linkurl, setLinkURL] = useState("");
    const [isPopupLoaded, setIsPopupLoaded] = useState(false);
    const [isEventFree, setIsEventFree] = useState(true);
    const [eventFee, setEventFee] = useState(0);
    const [footerText, setFooterText] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");


    // New Change add google form link if required

    const [registationType, setRegistationType] = useState("");
    const [googleFormLink, setGoogleFormLink] = useState("");
    const [officeAddress, setOfficeAddress] = useState("");
    const [twitter, setTwitter] = useState("");
    const [facebook, setFacebook] = useState("");
    const [youtube, setYoutube] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [checkedImageSize, setCheckedImageSize] = useState(false)
    const [checkedImageSize1, setCheckedImageSize1] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
        if (screentype !== "create") {
            loadEventDetails()
            setIsPopupLoaded(true)
        }
        else {
            setIsPopupLoaded(true)
        }

    }, []);

    const clearFields = () => {

        setEventTitle('');
        setEventSubTitle('');
        setEventTiming('');
        setEventLocation('');
        setEventfromDate('');
        setEventToDate('');
        setEventImage('');
        setIsEventFree(true)
        setEventVideo('');
        setEventSponser('');
        setEventSponserLogo('');
        setEventAbout('');
        setDirectorMessage('');
        setMDName('');
        setMDText('');
        setMDImage('');
        setAttachment('');
        setLinkURL('');
        localStorage.setItem("screentype", "create");
        localStorage.setItem('selectedeventid', '');
    }

    const onAboutEvent = (value) => {
        setEventAbout(value);
    }




    const checkedDimenstions = (selectedFile) => {
        // Check Dimension of Image
        var img = new Image();
        var objectURL = URL.createObjectURL(selectedFile[0]);
        img.src = objectURL;
        img.onload = function () {
            var sizes = {
                width: this.width,
                height: this.height
            };
            // URL.revokeObjectURL(this.src);

            if (sizes.width > 1919) {
                setCheckedImageSize(currentState => {

                })
            } else {
                setCheckedImageSize(currentState => {

                })
            }
        }
    }



    const handleEventBannerImage = (e) => {

        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {
            }

            // 
            else {
                setEventImage("")
                setIsPopupLoaded(true)
                return toast.error('Please select image only');
            }



            setIsPopupLoaded(false)
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setEventImage(res.filepath)
                    setIsPopupLoaded(true)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const handleEventVideo = (e) => {
        setIsPopupLoaded(false)
        try {
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "mp4" || ext === "MP4") {

            }
            else {
                setEventVideo("")
                setIsPopupLoaded(true)
                return toast.error('Please Select Video only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setEventVideo(res.filepath)
                    setIsPopupLoaded(true)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const handleOrganizerLogo = (e) => {

        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {
            }
            else {
                setEventSponserLogo("")
                setIsPopupLoaded(true)
                return toast.error('Please Select Image only');
            }

            setIsPopupLoaded(false)
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setEventSponserLogo(res.filepath)
                    setIsPopupLoaded(true)
                }
            })
                .catch()
        } catch (error) {

        }
    }


    const loadEventDetails = () => {
        setLoaded(false)
        LoadEventDetails(selectedeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                }
                else {
                    console.log(result.data, 'result.data')
                    var sd = result.data.StartDate
                    sd = moment(new Date(sd)).format("YYYY-MM-DD")

                    var ed = result.data.EndDate
                    ed = moment(new Date(ed)).format("YYYY-MM-DD")
                    setEventTitle(result.data.Title);
                    setEventSubTitle(result.data.SubTitle);
                    setEventTiming(result.data.Timing);
                    setEventLocation(result.data.Location);
                    setEventfromDate(sd);
                    setEventToDate(ed);
                    setEventStartTime(result.data.StartTime);
                    setEventImage(result.data.EventImage);
                    setEventSponser(result.data.Sponsor);
                    setEventSponserLogo(result.data.SponsorLogo);
                    setEventAbout(result.data.AboutEvent);
                    setLinkURL(result.data.LinkURL);
                    setIsEventFree(result.data.IsEventFree)
                    setEventFee(result.data.EventFee)
                    setRegistationType(result.data.RegistrationType)
                    setGoogleFormLink(result.data.GoogleFormLink)
                    setFooterText(result.data.FooterText)
                    setMobile(result.data.Mobile)
                    setEmail(result.data.Email)
                    setOfficeAddress(result.data.OfficeAddress)
                    setTwitter(result.data.Twitter)
                    setFacebook(result.data.Facebook)
                    setYoutube(result.data.Youtube)
                    setLinkedin(result.data.Linkedin)
                    let obj = result.data.Title;
                    setEventTitle(obj);
                    setLoaded(true);
                }
            }

        }).catch();

    }

    const SaveBtnClick = (e) => {
        if (eventtitle === "" || eventtitle === null) {
            return toast.error("Please enter event title");
        }
        if (reg.test(eventtitle) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventsubtitle === "" || eventsubtitle === null) {
            return toast.error("Please enter event subtitle")
        }
        if (reg.test(eventsubtitle) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventtiming === "" || eventtiming === null) {
            return toast.error("Please enter event timing")
        }
        if (reg.test(eventtiming) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventLocation === "" || eventLocation === null) {
            return toast.error("Please enter event location")
        }
        if (reg.test(eventtiming) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventfromdate === "" || eventfromdate === null) {
            return toast.error("Please enter event start date")
        }
        if (reg.test(eventfromdate) === true) {
            return toast.error(HtmlValMsg);
        }

        if (eventtodate === "" || eventtodate === null) {
            return toast.error("Please enter event end date")
        }

        if (reg.test(eventtodate) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventstarttime === "" || eventstarttime === null) {
            return toast.error("Please enter event start time")
        }

        if (eventimage === "" || eventimage === null) {
            return toast.error("Please select event image ")
        }
        if (sponserlogo === "" || sponserlogo === null) {
            return toast.error("Please select organization's logo")
        }
        if (aboutevent === "" || aboutevent === null) {
            return toast.error("Please enter about the event")
        }
        else {
            AddEvent();
        }
    }

    const AddEvent = async () => {
        try {
            var req = {};
            req = {
                title: eventtitle,
                subtitle: eventsubtitle,
                location: eventLocation,
                timing: eventtiming,
                startdate: moment(eventfromdate).format("YYYY-MM-DD"),
                enddate: moment(eventtodate).format("YYYY-MM-DD"),
                starttime: eventstarttime,
                eventimage: eventimage,
                eventvideo: eventvideo,
                sponsor: eventsponser,
                sponserlogo: sponserlogo,
                aboutevent: aboutevent,
                attachment: attachment,
                linkurl: linkurl,
                isEventFree: isEventFree,
                eventFee: eventFee,
                mobile: mobile,
                email: email,
                registration_type: registationType,
                google_form_link: registationType === "Google Form" ? googleFormLink : "",
                officeAddress: officeAddress,
                twitter: twitter,
                facebook: facebook,
                youtube: youtube,
                linkedin: linkedin
            }

            CreateEvent(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message);
                    clearFields();
                    navigate("/admin-events");
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const UpdateBtnClick = (e) => {
        if (eventtitle === "" || eventtitle === null) {
            return toast.error("Please enter event title");
        }
        if (reg.test(eventtitle) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventsubtitle === "" || eventsubtitle === null) {
            return toast.error("Please enter event subtitle")
        }
        if (reg.test(eventsubtitle) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventtiming === "" || eventtiming === null) {
            return toast.error("Please enter event timing")
        }
        if (reg.test(eventtiming) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventLocation === "" || eventLocation === null) {
            return toast.error("Please enter event location")
        }
        if (reg.test(eventtiming) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventfromdate === "" || eventfromdate === null) {
            return toast.error("Please enter event start date")
        }
        if (reg.test(eventfromdate) === true) {
            return toast.error(HtmlValMsg);
        }

        if (eventtodate === "" || eventtodate === null) {
            return toast.error("Please enter event end date")
        }

        if (reg.test(eventtodate) === true) {
            return toast.error(HtmlValMsg);
        }
        if (eventstarttime === "" || eventstarttime === null) {
            return toast.error("Please enter event start time")
        }

        if (eventimage === "" || eventimage === null) {
            return toast.error("Please select event image ")
        }
        if (sponserlogo === "" || sponserlogo === null) {
            return toast.error("Please select organization's logo")
        }
        if (aboutevent === "" || aboutevent === null) {
            return toast.error("Please enter about the event")
        }
        // if()
        else {
            UpdateEventClick();
        }
    }

    const UpdateEventClick = async () => {
        try {

            var req = {};
            req = {
                eventid: selectedeventid,
                title: eventtitle.trim(),
                subtitle: eventsubtitle,
                timing: eventtiming.trim(),
                location: eventLocation.trim(),
                startdate: moment(eventfromdate).format("YYYY-MM-DD"),
                enddate: moment(eventtodate).format("YYYY-MM-DD"),
                starttime: eventstarttime,
                eventimage: eventimage,
                eventvideo: eventvideo,
                sponsor: eventsponser,
                sponserlogo: sponserlogo,
                aboutevent: aboutevent,
                attachment: attachment,
                linkurl: linkurl,
                isEventFree: isEventFree,
                eventFee: eventFee,
                mobile: mobile.trim(),
                email: email.trim(),
                registration_type: registationType,
                google_form_link: registationType === "Google Form" ? googleFormLink : "",
                officeAddress: officeAddress.trim(),
                twitter: twitter.trim(),
                facebook: facebook.trim(),
                youtube: youtube.trim(),
                linkedin: linkedin.trim()
            }
            console.log(req)
            UpdateEvent(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    navigate("/admin-events");
                }
            })
                .catch()
        } catch (error) {

        }
    }


    const Cancelclick = (e) => {
        navigate("/admin-events")
        localStorage.setItem("screentype", "create");
        dispatch(decrement())
    }




    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper cms">
                    <section className="content-header">

                        {screentype === "edit" ?
                            <ol class="breadcrumbCustom">
                                <li><button onClick={Cancelclick}  ><i class="fa fa-dashboard"></i> Event List</button></li>
                                <li><button >{loaded === false ? "Loading..." : eventtitle}</button></li>
                                <li><button> Event Details</button></li>
                            </ol>
                            :
                            <h1>Add Event</h1>
                        }

                    </section>

                    {/* <!-- Main content --> */}
                    <section className="content">
                        <div ID="panelfiltergridview">
                            <div className="row" id="panelSearch">
                                <div className="col-md-12">
                                    <div className="box box-primary">
                                        <div className="box-header with-border">

                                        </div>
                                        <div className="form-horizontal">
                                            <div className="box-body">
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Title<span style={{ color: "red" }}>*</span></label>
                                                        <input maxLength={70} type="text" id="txttitle" className="form-control" value={eventtitle} onChange={(e) => { setEventTitle(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Sub Title<span style={{ color: "red" }}>*</span></label>
                                                        <input maxLength={150} type="text" id="txtsubtitle" className="form-control" value={eventsubtitle} onChange={(e) => setEventSubTitle(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Display Timings<span style={{ color: "red" }}>*</span></label>
                                                        <input type="text" id="txttiming" MaxLength="100" className="form-control" value={eventtiming} onChange={(e) => setEventTiming(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Location<span style={{ color: "red" }}>*</span></label>
                                                        <input type="text" id="txtlocation" MaxLength="50" className="form-control" value={eventLocation} onChange={(e) => setEventLocation(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-4">
                                                        <label>Start Date<span style={{ color: "red" }}>*</span></label>
                                                        <input type="date" id="txtstartdate" MaxLength="100" className="form-control" value={eventfromdate} onChange={(e) => setEventfromDate(e.target.value)} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label>End Date<span style={{ color: "red" }}>*</span></label>
                                                        <input type="date" id="txtenddate" className="form-control" value={eventtodate} onChange={(e) => setEventToDate(e.target.value)} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label>Start Time<span style={{ color: "red" }}>*</span></label>
                                                        <input type="text" id="txtstarttime" MaxLength="5" className="form-control" value={eventstarttime} onChange={(e) => setEventStartTime(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-6">
                                                        <label>Event Image <span style={{ color: "red" }}>*</span></label>
                                                        <input type="file" className="form-control" accept="image/*" onChange={(e) => handleEventBannerImage(e)} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className='imgforevent'> <img src={eventimage} /></div>

                                                    </div>
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-6">
                                                        <label>Organizer Logo <span style={{ color: "red" }}>*</span></label>
                                                        <input type="file" id="txtlogo" className="form-control" accept="image/*" onChange={(e) => handleOrganizerLogo(e)} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className='imgforevent'> <img src={sponserlogo} /></div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12" style={{ height: "320px" }}>
                                                    <div className="col-sm-12">
                                                        <label>About Event<span style={{ color: "red" }}>*</span></label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={aboutevent}
                                                            onChange={onAboutEvent}
                                                            placeholder={"Write something about event..."}
                                                            style={{ height: "250px" }}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-6">
                                                        <label>Event Type</label>
                                                        <div className="col-sm-12">
                                                            <input type="radio" name="ismessage" checked={isEventFree ? true : false} onChange={() => setIsEventFree(true)} />
                                                            <label style={{ marginLeft: "15px" }}>&nbsp; Free </label>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <input type="radio" name="ismessage" checked={isEventFree ? false : true} onChange={() => setIsEventFree(false)} />
                                                            <label style={{ marginLeft: "15px" }}>&nbsp; Paid </label>
                                                        </div>
                                                        {isEventFree === false
                                                            &&
                                                            <div className="col-sm-9">
                                                                <label>Fee (in $)</label>
                                                                <input type="text" maxLength={6} onChange={(e) => setEventFee(e.target.value)} value={eventFee} className="form-control" placeholder='Please enter amount' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>


                                                <div lassName="form-group col-sm-12" style={{ paddingTop: "10px" }}>

                                                    <div className="form-group col-md-12">
                                                        <div className="col-sm-6">
                                                            <label>Registration Through</label>
                                                            <select onChange={(e) => (setRegistationType(e.target.value))} maxLength={10} className="form-control" value={registationType}>
                                                                <option value="Website" >Website</option>
                                                                <option value="Google Form" >Google Form</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {registationType === "Google Form" &&
                                                        <div className="col-sm-6">
                                                            <label>Google Form Link</label>
                                                            <input type="text" placeholder="Google form link" className="form-control" value={googleFormLink} onChange={(e) => setGoogleFormLink(e.target.value)} />
                                                        </div>
                                                    }
                                                </div>

                                                <div className="form-group col-sm-12" style={{ paddingTop: "10px" }}>
                                                    <div className="col-sm-12" style={{ paddingBottom: "20px" }}>
                                                        <h4>Contact</h4>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <div className="col-sm-6">
                                                            <label>Mobile Number</label>
                                                            <input type="text" maxLength={10} className="form-control" value={mobile} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label>Email</label>
                                                            <input type="text" maxLength={100} className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <div className="col-sm-6">
                                                            <label>Website</label>
                                                            <input type="text" id="linkurl" maxLength={200} className="form-control" value={linkurl} onChange={(e) => setLinkURL(e.target.value)} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label>Office Address</label>
                                                            <input type="text" maxLength={100} className="form-control" value={officeAddress} onChange={(e) => setOfficeAddress(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-12" style={{ paddingTop: "10px" }}>
                                                    <div className="col-sm-12" style={{ paddingBottom: "20px" }}>
                                                        <h4>Social Links</h4>
                                                    </div>
                                                    <div className="form-group col-sm-12">
                                                        <div className="col-sm-6">
                                                            <label>Twitter</label>
                                                            <input type="text" className="form-control" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label>Facebook</label>
                                                            <input type="text" className="form-control" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-sm-12">
                                                        <div className="col-sm-6">
                                                            <label>Youtube</label>
                                                            <input type="text" className="form-control" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label>Linkedin</label>
                                                            <input type="text" className="form-control" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="box-footer">
                                            <div>
                                                {isPopupLoaded ?
                                                    <>{

                                                        screentype === "create"
                                                            ?
                                                            <button onClick={SaveBtnClick} className="btn btn-primary b-radius pull-right">Save</button>
                                                            :
                                                            screentype === "edit"
                                                                ?
                                                                <button onClick={UpdateBtnClick} className="btn btn-primary b-radius pull-right">Update</button> :
                                                                ""
                                                    }
                                                    </>
                                                    : <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>}

                                                <button onClick={Cancelclick} className="btn btn-danger b-radius "> Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section >
                </div >
            </div >
        </div >
    );
}
export default AddEvent;