import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { UploadFile } from '../../../services/UploadFile';
import { SubEventList, CreateOrganizer, LoadOrganizer, UpdateOrganizer } from '../../../services/Event'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { HtmlValMsg } from '../../../Inputs/TextInput';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const reg = /<(.|\n)*?>/g;


function Organizersform({ eventid, popuptype, organizerid, loadOrganizerss, sequenceno }) {
    const [name, setName] = useState("")
    const [orgn, setOrgn] = useState("")
    const [profilepic, setProfilepic] = useState("")
    const [biodata, setBioData] = useState("")

    const [contentimg, setContentimg] = useState("")


    const onProfile = (value) => {
        setBioData(value);
    }

    useEffect(() => {
        if (popuptype === "createOrganizer") {
            clearFields()
        }
        if (popuptype === "editorganizers") {
            loaddetails()
        }
    }, [popuptype, organizerid]);


    const loaddetails = () => {
        LoadOrganizer(organizerid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data) {
                    setName(result.data.Name);
                    setProfilepic(result.data.Photo)
                    setBioData(result.data.Biodata);
                }
            }
            else {

            }
            // setIsLoaded(true);
        }).catch();
    }

    const clearFields = () => {
        setName('')
        setProfilepic('')
        setContentimg('')
        setBioData('')
    }



    const handleProfilepic = (e) => {
        setContentimg(e.target.value)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please Select Image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    var changedPhotoUrl = res.filepath
                    setProfilepic(changedPhotoUrl)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Save = (e) => {
        e.preventDefault()
        if (name.trim() === "") {
            return toast.error("Please enter organizer name ");
        }
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }

        else {

            AddOrganizer();

        }
    }

    const AddOrganizer = async () => {
        try {

            const req = {
                eventid: eventid,
                name: name.trim(),
                biodata: biodata,
                profilepic: profilepic,
            };

            CreateOrganizer(req).then(res => {
                if (res !== undefined) {
                    clearFields();
                    window.$("#organizersForm").modal('hide');
                    toast.success(res.data.message)
                    loadOrganizerss();
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        e.preventDefault()
        if (name.trim() === "") {
            return toast.error("Please enter organizer name ");
        }
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }

        else {
            Update_Organizer();
        }
    }

    const Update_Organizer = async () => {

        try {
            const req = {
                organizerid: organizerid,
                name: name.trim(),
                profilepic: profilepic,
                biodata: biodata,
            };
            UpdateOrganizer(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#organizersForm").modal('hide');
                    loadOrganizerss();
                }
            })
                .catch()
        } catch (error) {

        }
    }



    return (
                <div className="modal-dialog" style={{ width: "50%" }}>
                    <div className="modal-content" style={{ height: "50%" }}>
                        <div className="modal-header">
                            {popuptype === "editorganizers" ? <div className="col-md-6"><h3>Edit Organizer</h3></div> : <div className="col-md-6"><h3>Add Organizer</h3></div>}
                            {/* <div className="closeright"><a onClick={closepopup} aria-label="Close"><img src="images/close.svg" /></a></div> */}
                            <div className="closeright"><a type="button" onClick={clearFields} data-dismiss="modal" aria-label="Close"><img src="images/close.svg" /></a></div>
                        </div>
                        
                        <form onSubmit={popuptype === "editorganizers" ? e => Submit_Update(e) : e => Submit_Save(e)} role="form" className="organizersForm">
                            <div className="box-body">
                                <div className="form-group">
                                    <label >Name<span style={{ color: "red" }}>*</span></label>
                                    <input maxLength={150} type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} id="exampleInputEmail1" placeholder="Enter Name" />
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label >Photo<span style={{ color: "red" }}>*</span></label>
                                        <input type="file" className="form-control" value={contentimg} onChange={(e) => handleProfilepic(e)} placeholder="Seelect Profile Pic" />
                                    </div>
                                    <div className="form-group">

                                        <img src={profilepic} width="100px" />
                                    </div>
                                </div>
                                <div className="form-group" style={{ height: "320px" }}>
                                    <label >Bio Data *</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={biodata}
                                        onChange={onProfile}
                                        placeholder={"Write something..."}
                                        style={{ height: "250px" }}
                                    />
                                </div>



                            </div>
                            {popuptype === "editorganizers" ? <button type='submit' className="btn btn-primary btn-sm" >Update</button>
                                : <button type='submit' className="btn btn-primary btn-sm" >Add</button>}
                        </form>
                    </div>

                </div>

    )
}

export default Organizersform

