import React, { useEffect } from "react"
import { Footer } from "../commonSection/Footer";
import { Header } from "../commonSection/Header";
import { Banner } from "./Banner";

const TermsandConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="outerhomepage">
            <Header />
            <Banner bannerName="Terms and Conditions" />

            <section className="about-sec padding100">
                <div class="container">
                    <p>
                    Ad elit nulla pariatur Lorem sunt anim irure consectetur id. Non proident nulla nostrud id aliqua dolore magna duis. Ex elit sit occaecat tempor proident ullamco proident velit adipisicing dolore occaecat. Ex enim pariatur incididunt et officia cupidatat fugiat fugiat officia commodo.

                    Sint tempor nulla consequat eu Lorem cupidatat adipisicing adipisicing tempor aliqua. Elit do qui quis cillum labore ut est laboris anim. Cupidatat irure proident mollit magna consequat eu commodo. Nulla ea ut ad duis amet. Cillum ea magna do adipisicing quis anim ipsum ullamco sit laborum. Eiusmod dolor ex ad nulla ullamco. Excepteur ut elit Lorem commodo veniam sit et sit.

                    Ipsum consequat do adipisicing non ullamco commodo adipisicing sit fugiat. Incididunt excepteur ex exercitation excepteur veniam ullamco. In in dolor laboris aute velit laborum laboris adipisicing mollit excepteur quis pariatur aliqua.
                    </p>
                    <p>
                    Ullamco duis pariatur consequat qui do pariatur veniam. Anim nulla adipisicing minim dolor. Nostrud ex ipsum ipsum id ex.

                    Deserunt ipsum minim incididunt velit culpa officia sint dolore ut deserunt tempor officia adipisicing est. Dolor aliquip do qui magna velit minim. Fugiat elit fugiat ut excepteur esse laboris aute. Veniam elit cupidatat voluptate non incididunt fugiat dolor. Laborum ea aute qui consectetur et consequat aute reprehenderit labore tempor pariatur tempor irure amet. Ut tempor dolore Lorem veniam.

                    Velit sint Lorem adipisicing esse sint ea reprehenderit duis amet cupidatat do enim. Aute nulla ex duis aute culpa aliqua. Irure nisi adipisicing amet ad ipsum nulla eiusmod. Ex excepteur officia adipisicing cupidatat tempor cupidatat proident consequat. Ipsum sint velit ad sit.

                    Cupidatat commodo quis aute laborum. Tempor amet officia ex exercitation consectetur eu dolor ullamco ea qui anim amet. Nostrud aliquip anim officia aute elit minim occaecat excepteur quis ad consequat. Incididunt fugiat laborum sunt cupidatat. Sit eiusmod dolore fugiat culpa ullamco amet exercitation ad.
                    
                    </p>
                    <p>
                    Magna tempor consequat excepteur aliquip amet ullamco duis adipisicing reprehenderit excepteur mollit. Qui duis amet mollit cupidatat. Quis id exercitation duis amet exercitation in ea ut et dolor est sit. Deserunt fugiat amet velit in cillum sunt.

                    Pariatur est ut reprehenderit consectetur commodo esse. Ea quis nulla mollit ipsum ea incididunt minim ex ea ea exercitation magna. Aliquip et culpa sit exercitation voluptate aliqua sunt occaecat laboris est laboris aute. Excepteur voluptate deserunt duis Lorem consectetur commodo anim sunt occaecat id est non. Nostrud consectetur minim aliquip voluptate aliqua magna mollit commodo excepteur sint incididunt ipsum enim. Reprehenderit aliquip velit anim occaecat incididunt laborum qui nisi consequat occaecat. Officia eu et eu occaecat minim consequat laborum dolor ut mollit.

                    Excepteur dolore eu veniam minim aliqua exercitation aliqua. Sunt duis non amet occaecat elit deserunt laboris qui proident mollit reprehenderit in nisi sit. Sit aliqua excepteur incididunt enim voluptate cupidatat mollit duis ipsum ut. Commodo in aliquip enim culpa deserunt nulla quis excepteur cupidatat excepteur est magna elit. Voluptate incididunt occaecat nostrud aliqua cupidatat laborum mollit ut ea anim sunt fugiat exercitation laboris. Excepteur esse id sunt culpa pariatur nostrud eiusmod.
                    </p>
                </div>
            </section>
            <Footer />
        </div>

    )
}

export default TermsandConditions;