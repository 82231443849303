import React, { useEffect } from 'react'
import { useState } from 'react';
import { ShimmerEffect } from '../../../styleUI/ShimmerEffect';
export const ForumBox = ({ data, title }) => {
    return (
        <div className="forumbox">
            {
                data.map((item, index) => (
                    <div key={index}>
                        <ForumBoxListItem title={item.Title} subtitle={item.Details} time={item.Timing} />
                    </div>
                ))
            }
        </div>
    )
}

const ForumBoxListItem = ({ title, subtitle, time }) => {
    return (
        <div className="forumboxlist">
            <div className="forumboxleft">
                <h4 className="forumheading">{title}</h4>
                <p className="forumtext" dangerouslySetInnerHTML={{ __html: subtitle }}></p>
            </div>
            <div className="forumboxright">
                <span className="tabtime"><i className="fa fa-clock-o" /> {time}</span>
                <br />
                <div className="toolbar1" />
            </div>
        </div>
    )
}
