import { toast } from 'react-toastify'

const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

export const UploadFile = async (reqdata) => {
    try {
        
        const response = await fetch(API_Base_Url + "cms/upload",reqdata)
        const result = await response.json();
        if (response.ok) {
            toast.error(result);
            return result;
        }
        else if (response.status === 400) {
            if(result.Error!==undefined)
                toast.error(result.Error.ErrorMessage);
        }
        else {

        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }
};
