import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

export const speakersApi = createApi({
    reducerPath: 'speakersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_Base_Url
    }),
    endpoints: (builder) => ({
        getAllSpeaker: builder.query({
            query: () => ({
                url: 'web/feature',
                method: 'GET'
            })
        })
    })
})

export const { useGetAllSpeakerQuery } = speakersApi