const WelcomeSection = ({ }) => {
    return (
        <section className="about-sec padding100">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <div className="about-header">
                            <h2>About EM Sling</h2>
                            <p>
                                Singapore EM Sling was formed by a couple of  Emergency medicine physicians ,over a meal
                                and a few drinks. These buddies decided that the Emergency fraternity needed
                                an opportunity to meet and mingle, to exchange tips and tricks and not just
                                to Singapore but international Emergency Medicine community as well .</p>
                                <p>
                                Going strong since 2019 and not about to let Covid-19 get us down, Singapore
                                EM Sling has transformed over time with more activities, virtual events  and
                                Hybrid events with social live and virtual learning and networking in future.
                            </p>
                        </div>
                        {/* <div className="about-body">
                            <p>&nbsp; &nbsp; Singapore EM Sling was formed by a couple of  Emergency medicine physicians ,over a meal
                                and a few drinks. These buddies decided that the Emergency fraternity needed
                                an opportunity to meet and mingle, to exchange tips and tricks and not just
                                to Singapore but international Emergency Medicine community as well .
                                Going strong since 2019 and not about to let Covid-19 get us down, Singapore
                                EM Sling has transformed over time with more activities, virtual events  and
                                Hybrid events with social live and virtual learning and networking in future.</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

    )
}


export default WelcomeSection