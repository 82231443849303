import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import { LoadList } from "../../../services/LoadList";
import Galleryform from "./Form";
import { PostData } from "../../../services/PostData";


/* CreatedBy: Mayur
CreatedOn: 18 Jan 2022
Description : Registration 
*/
function GalleryList() {
    const navigate = useNavigate()
    const pagination = useRef();
    const [GalleryList, setGalleryList] = useState([]);
    const [galleryListPaginate, setGalleryListPaginate] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(0);
    const [popuptype, setPopuptype] = useState("");
    const [imageId, setImageid] = useState("");

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }else{
            loadGallery();
        }
    }, [])

    useEffect(() => {
        const dataNew = GalleryList
        const slice = dataNew.slice(offset, offset + perPage);
        if (dataNew.length > 0) {
            setGalleryListPaginate(slice)
            setPageCount(Math.ceil(dataNew.length / perPage));
        }
    }, [offset, number,GalleryList]);


    const openNewsForm = (id) => {
        setImageid(id);
        setPopuptype("AddImage");
        window.$("#galleryformAdd").modal('show');
    }


    const loadGallery = () => {
        setIsLoaded(false);
        const url = "image/list"
        LoadList(url).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setGalleryList([])
                    setGalleryListPaginate([])
                }
                else {
                    const data = result.data;
                    setGalleryList(data);
                    setTotalData(data.length)
                    const slice = data.slice(offset, offset + perPage);
                    if (data.length > 0) {
                        setGalleryListPaginate(slice)
                        setPageCount(Math.ceil(data.length / perPage));
                    }
                    setIsLoaded(true);
                }
            }
            else {
                setGalleryList([])
                setGalleryListPaginate([])
            }
        }).catch();

    }



    function DeleteItem(id) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this news?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        const req = {
                            _id: id,
                            action: "delete"

                        };
                        PostData(req, "image/action").then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                                const timer = setTimeout(() => {
                                    loadGallery();
                                }, 1000);
                                return () => clearTimeout(timer);
                            }
                        }).catch()
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadGallery()
                }
            ]
        });
    }

    const setPage = ({ selected }) =>{
        setOffset(perPage * selected)
        setNumber(selected);
    }
    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper cms">
                    <section className="content-header">
                        <h1>Gallery</h1>                        
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 class="box-title">Gallery List</h3>
                                <button className="btn btn-sm btn-primary pull-right" onClick={(e) => openNewsForm(0)}><i className="fa fa-plus"></i> Add Image</button>
                            </div>
                            <div className="box-body">
                                <div id="ActiveEvent" className=" ippftable">
                                    <div className="box-body no-padding table-responsive">
                                        <table className="table table-striped table-bordered">                                           
                                            <tbody>
                                            <tr>
                                                    <th style={{ width: "10%" }}>Sr. No.</th>
                                                    <th style={{ width: "20%" }}>Title</th>
                                                    <th style={{ width: "30%" }}>Image</th>
                                                    <th style={{ width: "30%" }}>Posted On</th>
                                                    <th style={{ width: "5%" }}>Delete</th>
                                                </tr>
                                                {
                                                    isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" width="100px" /></div>
                                                        :
                                                        <>
                                                            {
                                                                galleryListPaginate.length === 0 ?
                                                                    <tr><td colSpan='5'>No record found.</td></tr>
                                                                    :
                                                                    galleryListPaginate.map((item, i) => (
                                                                        <tr>
                                                                            <td>{(i + 1) + offset}</td>
                                                                            <td>{item.Name}</td>
                                                                            <td>{item.Image &&
                                                                                <>
                                                                                    <img src={item.Image} style={{ width: "140px" }} />
                                                                                </>}
                                                                            </td>
                                                                            <td>{moment(new Date(item.PostedOn)).format("DD MMM YYYY hh:mm")}</td>
                                                                            <td><button className="btn btn-xs btn-danger" onClick={(e) => DeleteItem(item._id)} >Delete</button></td>
                                                                        </tr>
                                                                    ))
                                                            }
                                                        </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            {isLoaded === true &&
                                <>
                                    {totalData > 10 &&
                                        <div className='col-md-4'>
                                            <ReactPaginate
                                                ref={pagination}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={4}
                                                marginPagesDisplayed={0}
                                                onPageChange={setPage}
                                                forcePage={number}
                                                activeClassName= "active"
                                                containerClassName="pagination"
                                                pageLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                previousLinkClassName="page-link"
                                                pageClassName="page-item"
                                                breakClassName="page-item"
                                                nextClassName="page-item"
                                                previousClassName="page-item"
                                                breakLabel="..."
                                                previousLabel={<>&#x3C;</>}
                                                nextLabel={<>&#x3E;</>}
                                            />
                                        </div>
                                    }
                                </>

                            }

                        </div>
                    </section>

                </div>

            </div>

            <div id="galleryformAdd" className="modal fade" style={{ height: "100%" }}>
                <Galleryform popuptype={popuptype} imageId={0} loadGallery={loadGallery} />
            </div>


        </div>
    );
}
export default GalleryList;