import React from 'react'

export const TriangularDownhill = ({ imagename, title, subtitle }) => {
    return (

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 skincolored_section sep_triangular_downhill_top sep_triangular_downhill_bottom">
            <div className="separator_top"><div /></div>
            <div className="wpb_wrapper">
                {/* <div className="teaser_box text-center boxed " onClick="goToByScroll('EventAgenda')"> */}

                <div className="teaser_box text-center boxed " >
                    <div className="figure">
                        <img src={"images/" + imagename} alt="Event Agenda Icon" />
                    </div>
                    <div className="content text-center">
                        <div className="hgroup">
                            <h4 className="neutralize_links">{title}</h4>
                            <p>{subtitle}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="separator_bottom"><div /></div>
        </div>

    )
}
export const TriangularUphill = ({ imagename, title, subtitle }) => {
    return (

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 sep_triangular_uphill_top sep_triangular_uphill_bottom vc_custom">
            <div className="separator_top"><div /></div>
            <div className="wpb_wrapper">
                {/* <div className="teaser_box text-center boxed" onClick="goToByScroll('Speakers')"> */}
                <div className="teaser_box text-center boxed">
                    <div className="figure">
                        <img src={"images/" + imagename} alt="Speakers Icon" />
                    </div>
                    <div className="content text-center">
                        <div className="hgroup">
                            <h4 className="neutralize_links">{title}</h4>
                            <p>{subtitle}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="separator_bottom"><div /></div>
        </div>

    )
}