import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import { ContactUsList, PaymentsList, paymentList } from '../../services/Event';
import moment from 'moment'
import format from "date-fns/format";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../features/Counter";

/* CreatedBy: Mayur
CreatedOn: 18 Jan 2022
Description : Registration 
*/
function AdminPaymentsList() {
    const navigate = useNavigate()
    const pagination = useRef();
    const dispatch = useDispatch()
    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [paymentList, setPaymentsList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(0);

    const [exportdata, setExportData] = useState([])
    const [exportdataall, setExportDataAll] = useState([])
    const fileName = "registrationdata"; // here enter filename for your excel file
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const eventName = localStorage.getItem("EventName")


    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])


    useEffect(() => {
        loadPayments();
    }, [offset, number]);


    const loadPayments = () => {
        setIsLoaded(false);
        PaymentsList(selectedeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data) {
                    const data = result.data;
                    setTotalData(data.length)
                    const slice = data.slice(offset, offset + perPage);
                    if (data.length > 0) {
                        var tempdata = data.map(item => {
                            return {
                                "Name": item.Name,
                                "Email": item.Email,
                                "Mobile": item.Mobile,
                                "Country": item.Country,
                                "City": item.City,
                                "Address": item.Address,
                                "Registration Fees": item.RegistrationFees,
                                "Message": item.Message,
                                "Posted On Date": format(new Date(item.CreatedOn), "dd MMM yyyy"),
                            }
                        })
                        setExportData(tempdata)

                        setPaymentsList(slice);
                        setPageCount(Math.ceil(data.length / perPage));

                    }
                }
            }
            else {
                setPaymentsList([])
            }
        }).catch();
        setIsLoaded(true);
    }

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const setPage = ({ selected }) =>
        setOffset(perPage * selected)

    const Cancelclick = (e) => {
        navigate("/admin-events")
        localStorage.setItem("screentype", "create");
        dispatch(decrement())
    }

    const gotoEventDetails = (e) => {
        navigate("/admin/event-add")
    }

    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper cms">
                    <section className="content-header">
                        <ol class="breadcrumbCustom">
                            <li><button onClick={Cancelclick}><i class="fa fa-dashboard"></i>Event List</button></li>
                            <li><button onClick={gotoEventDetails}>{eventName} </button></li>
                            <li><button >Payments</button></li>
                        </ol>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box box-primary">
                                    <div className="box-header with-border">
                                        <h3 class="box-title">Payments List</h3>
                                        {totalData !== 0 &&
                                            <button onClick={(e) => exportToCSV(exportdata, fileName)} className="btn btn-sm btn-info pull-right"><img src="images/export.png " width="20px" className="mr-10" />Export</button>
                                        }
                                    </div>
                                    <div className="box-body ">
                                        <div id="ActiveEvent" className=" ippftable">
                                            <div className="no-padding table-responsive">
                                                <table className="table table-striped table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                            <th>Posted On</th>
                                                            <th>Name/Email</th>
                                                            <th>Mobile</th>
                                                            <th>Designation/Organization</th>
                                                            <th>Registration Fees</th>
                                                        </tr>
                                                        {
                                                            isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" width="100px" /></div>
                                                                :
                                                                <>
                                                                    {
                                                                        paymentList.length === 0 ?
                                                                            <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                            :
                                                                            paymentList.map((item, i) => (
                                                                                <tr>
                                                                                    <td>{(i + 1) + offset}</td>
                                                                                    <td>{moment(new Date(item.CreatedOn)).format("DD MMM YYYY hh:mm")}</td>
                                                                                    <td>{item.Name}<br />{item.Email}</td>
                                                                                    <td>{item.Mobile}</td>
                                                                                    <td>{item.Designation} <br />
                                                                                        {item.Organization}
                                                                                    </td>
                                                                                    <td>{item.RegistrationFees}</td>
                                                                                </tr>

                                                                            ))
                                                                    }
                                                                </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-footer">
                                        {isLoaded === true &&
                                            <>
                                                {totalData > 10 &&

                                                    <ReactPaginate
                                                        ref={pagination}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={4}
                                                        marginPagesDisplayed={0}
                                                        onPageChange={setPage}
                                                        // previousLabel={<i className="fa fa-angle-left"></i>}
                                                        activeClassName="active"
                                                        containerClassName="pagination regpagination"
                                                        // nextLabel={<i className="fa fa-angle-right"></i>}
                                                        pageLinkClassName="page-link"
                                                        breakLinkClassName="page-link"
                                                        nextLinkClassName="page-link"
                                                        previousLinkClassName="page-link"
                                                        pageClassName="page-item"
                                                        breakClassName="page-item"
                                                        nextClassName="page-item"
                                                        previousClassName="page-item"
                                                        breakLabel="..."
                                                        // breakClassName={"break-me"}
                                                        // pageRangeDisplayed={3}
                                                        previousLabel={<>&#x3C;</>}
                                                        nextLabel={<>&#x3E;</>}
                                                    />

                                                }


                                            </>

                                        }


                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                </div >

            </div >
        </div >
    );
}
export default AdminPaymentsList;