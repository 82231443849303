import { toast } from 'react-toastify';
// import { AccessToken } from '../components/AccessToken';
import axios from 'axios';
import { ErrorHandel } from '../middleware/Errors';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;



export const LoadList = async (url) => {

    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'authorization': window.localStorage.getItem('AccessToken') ? window.localStorage.getItem('AccessToken') : window.sessionStorage.getItem('AccessToken')
            }
        }
        const response = await axios.get(`${API_Base_Url + url}`, headers).then((response) => {
            return response
        }
        ).catch(error => {
            if (error.response.status === 400) {
                // if (error.response.data.Error.ErrorMessage === 'You already paid for this event') {
                //     return toast.error(error.response.data.Error.ErrorMessage)
                // }
                if (error.response.data.Error.ErrorMessage) {
                    if(error.response.data.Error.ErrorMessage === 'You already paid for this event'){
                        window.location = '#/home';
                    }
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
            else if (error.response.status === 401) {
                if (error.response.data.Error.ErrorMessage) {
                    return toast.error("sorry..! You are not authorised ")
                }
            }
            else if (error.response.status === 404) {
                if (error.response.data.Error.ErrorMessage) {
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
            else if (error.response.status === 500) {
                if (error.response.data.Error.ErrorMessage) {
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
        });
        return response.data
    } catch (error) {
        return toast.error('Something went wrong , Please check your internet connection.')
    }
}

