import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { ValidateEmail } from "../../middleware/ValidateEmail";
import { LoadList } from "../../services/LoadList";
import { ShimmerEffect } from "../../styleUI/ShimmerEffect";
import { Footer } from "./commonSection/Footer";
import { Header } from "./commonSection/Header";
import  SEO from "../public/commonSection/seo"


const ArchiveEvents = ({ archiveEventsData }) => {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoaded(false)
        loadAllEventsList()
    }, [])

    const loadAllEventsList = () => {
        LoadList(`/cms/event/list`).then(result => {

            if (result !== undefined) {
                if (!result.data) {
                    setData([])
                }
                else {
                    const archiveData = result.data.filter(e => e.IsActive === false)
                    setData(archiveData)
                }
            } else {
                setData([])
            }
            setIsLoaded(true);
        }).catch();
    }


    return (
        <div className="outerhomepage">
            <Header page="archive-event-list" />
            <div className="top-section breadcrumb about-bd">
                <span className="overlay" />
                <div className="bdsec">
                    <div className="container">
                        <h2 className="mtb-0">Past Events</h2>
                    </div>
                </div>
            </div>


            <section className="archive_event">
                {isLoaded === false ?
                    <>
                        <div className="container">
                            <ShimmerEffect type="card" />
                            <ShimmerEffect type="card" />
                            <ShimmerEffect type="card" />
                            <ShimmerEffect type="card" />
                            <ShimmerEffect type="card" />
                            <ShimmerEffect type="card" />
                        </div>

                    </>
                    :
                    <div className="container">

                        {data.map((item, index) => (
                            <PostInfoCard id={item._id} Title={item.Title} SubTitle={item.SubTitle} img={item.EventImage} date={item.StartDate} timing={item.Timing} url={item.LinkURL} location={item.Location} />
                        ))}
                    </div>
                }
            </section>

            <Footer />
        </div>
    )
}

export default ArchiveEvents;


const PostInfoCard = ({ id, Title, SubTitle, img, date, timing, url, location }) => {
    const date1 = moment(new Date(date)).format("MMM d,Y")

    const toDetailsOfAchiveEvents = (_id) => {
        window.open(`#/event/${id}/`, "_blank");
    }
    return (
        <>
        <SEO
        title='Past Events Archive - Celebrating Emergency Medicine Milestones'
        description='Explore our archive of past events to witness the evolution of emergency medicine. Each event showcases the advancements, learnings, and expert collaborations that have shaped the current landscape of emergency medical practices.'
        keywords='Advancing Emergency Medical Practice Through Global Expertise'/>
      
        <div className="post_grid">
            <div className="row">
                <div className="col-md-4">
                    <div className="post_figure">
                        <img src={img} alt={location} />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="post_headings">
                        <h2>{Title}</h2>
                        <p>{SubTitle}</p>
                    </div>
                    <div className="post_body">
                        <div className="post_info">
                            <div className="mb-10">
                                <span><i className="fa fa-calendar"></i>{date1}</span>
                                <span><i className="fa fa-clock-o"></i>{timing}</span>
                            </div>
                            {location
                                &&
                                <div>
                                    <span><i className="fa fa-map-marker"></i>{location}</span>
                                </div>
                            }

                            <div className="mt-20">
                                <button onClick={() => { toDetailsOfAchiveEvents(id) }} className="btn-lg btn btn-primary radiustheme">
                                    View Detail
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
