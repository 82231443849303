import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ValidateEmail, validateIsNumber } from "../../../middleware/ValidateEmail"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { HtmlValMsg } from "../../../Inputs/TextInput";
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const reg = /<(.|\n)*?>/g;


const FeedBackForm = () => {
    const navigate = useNavigate()
    const [feedbackFormInput, setFeedbackFormInput] = useState({
        name: "",
        email: '',
        mobile: '',
        feedback: "",
    })


    const { name, email, mobile, feedback } = feedbackFormInput;


    const onInputChange = e => {
        setFeedbackFormInput({ ...feedbackFormInput, [e.target.name]: e.target.name === "mobile" ? validateIsNumber(e.target.value)[0] : e.target.value })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handelFormFeedback = e => {
        e.preventDefault()
        if (!name)
            return toast.error('Please enter name');
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }
        if (!email)
            return toast.error('Please enter email');
        if (!ValidateEmail(email))
            return toast.error('Please enter valid email');
        if (reg.test(email) === true) {
            return toast.error(HtmlValMsg);
        }
        if (mobile) {
            if (reg.test(mobile) === true) {
                return toast.error(HtmlValMsg);
            }
            if (mobile.length !== 10) {
                return toast.error('Please enter 10 digit mobile number');
            }
            if (Number(mobile[0]) === 0)
                return toast.error('Please enter valid mobile number');
        }

        if (!feedback)
            return toast.error('Please enter feedback');

        if (reg.test(feedback) === true) {
            return toast.error(HtmlValMsg);
        }
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
            const response = axios.post(API_Base_Url + 'feedback/add', feedbackFormInput, headers, { timeout: 2 }).then((response) => {
                if (response.data) {
                    navigate("/success", {
                        state: {
                            "PageName": "Feedback"
                        }
                    }
                    )
                }
                // toast.success("Your query successfully stored, we will get back to you soon.")
                // return navigate('/')
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.Error.ErrorMessage !== undefined)
                        toast.error(error.response.data.Error.ErrorMessage);
                }
                if (error.response.status === 500) {
                    if (error.response.data.Error.ErrorMessage !== undefined)
                        toast.error(error.response.data.Error.ErrorMessage);
                }
            });
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }
    return (
        <>
            <section className="padding100">
                <div className="container">
                    <div className="section-title">
                        <h2>Feedback Form</h2>
                    </div>
                    <form onSubmit={(e) => handelFormFeedback(e)} className="FeedbackForm">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="form-group">
                                <label>Your Name *</label>
                                <input type="text" maxLength={50} value={name} onChange={(e) => onInputChange(e)} name="name" className="form-control" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="form-group">
                                <label>Email *</label>
                                <input type="text" name="email" onChange={(e) => onInputChange(e)} value={email} className="form-control" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>Mobile</label>
                                <input maxLength={10} type="text" name="mobile" value={mobile} onChange={(e) => onInputChange(e)} className="form-control" placeholder="optional" />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>Feedback/Suggestion *</label>
                                <textarea type="text" name="feedback" value={feedback} onChange={(e) => onInputChange(e)} className="form-control" rows={5} />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <button type="submit" className="btn-lg btn btn-primary radiustheme">Submit</button>
                        </div>
                        </div>
                    </form>
                </div>

            </section>

        </>
    )
}

export default FeedBackForm;