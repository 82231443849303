import React, { useState } from 'react'
import { OrganizersModalPopup } from './OrganizersModalPopup';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

export const OrganizingTeam = ({ data }) => {
    const [imageUrl, setImageUrl] = useState('')
    const [organizerName, setorganizerName] = useState('')

    const [biodata, setBiodata] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)

    const setDataforPopup = (ImageUrl, organizerName, biodata) => {
        setIsLoaded(false)
        const timer = setTimeout(() => {
            setImageUrl(ImageUrl)
            setorganizerName(organizerName)
            setBiodata(biodata)
            setIsLoaded(true)
        }, 1000);
        return () => clearTimeout(timer);
    }

    return (
        <>
            <section className="speakers" id="OrgTeam">
                {/*Organizing Team*/}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section_header text-center">
                                <h3>Organizing Team</h3>
                            </div>
                            {data.length === 0 ?
                                <div className='text-center'>
                                    <p>
                                        No team found
                                    </p>
                                </div>

                                :
                                <div className="team_members_grid">

                                    {data.map((item, index) => (
                                        <TeamMemberCard username={item.Name} imagename={item.Photo} onClick={() => setDataforPopup(item.Photo, item.Name, item.Biodata)} />
                                    ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <OrganizersModalPopup imageUrl={imageUrl} isLoaded={isLoaded} organizerName={organizerName} biodata={biodata} />
        </>
    )
}


const TeamMemberCard = ({ imagename, username, onClick }) => {
    return (

        // <a data-toggle="modal" data-target="#OrganizerDetailsModal" onClick={onClick}>
            <div className="team_member">
                <div className="team_photo">
                <a data-toggle="modal" data-target="#OrganizerDetailsModal" onClick={onClick}>
                    <img src={imagename} alt={imagename} className='spkphoto' />
                    </a>
                </div>
                <div className="team_detail">
                    <h4>{username}</h4>
                    {/* <a href="#" className="mt-10">View</a> */}
                </div>
            </div>
        // </a>
    )
}