import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadList } from "../../../services/LoadList";


{/* Begin Recent Event */ }
const RecentEvents = () => {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoaded(false)
        loadAllEventsList()
    }, [])

    const loadAllEventsList = () => {
        LoadList(`/cms/event/list`).then(result => {

            if (result !== undefined) {
                if (!result.data) {
                    setData([])
                }
                else {
                    const recentEvents = result.data.filter(e => new Date(e.StartDate) < new Date())
                    const sortedEvents = recentEvents.sort((a, b) => new Date(b.StartDate) - new Date(a.StartDate))

                    setData(sortedEvents)

                }
            } else {
                setData([])
            }
            setIsLoaded(true);
        }).catch();
    }
    return (
        <>
            {data.slice(0, 3).length > 0 ?
                <section className="recent-event padding100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h2>Recent Events</h2>
                                </div>
                            </div>
                        </div>
                        <div className="insight-list">
                            <div className="row">
                                {data.slice(0, 3).map((item, index) => (
                                    <div className="col-md-4">
                                        <div className="insight-box">
                                            <div className="widget-imgbox">
                                                <img src={item.EventImage} width="100%" />
                                            </div>
                                            <div className="widget-textbox">
                                                <div className="widget-date"><i className="fa fa-calendar" /> {item.Timing}</div>
                                                <h4 className="widget-title">{item.Title.length > 50 ? item.Title.slice(0, 50) + "..." : item.Title}</h4>
                                                <p>{item.SubTitle.length > 90 ? item.SubTitle.slice(0, 90) + "..." : item.SubTitle}</p>
                                                <Link to={`/event/${item._id}`} target="_blank" className="recentviewmore">View More</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </section>
                : ""}
        </>
    )
}

export default RecentEvents;
