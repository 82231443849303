import { toast } from 'react-toastify';
import axios from 'axios';


const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

export const PostData = async (data, url) => {
    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'authorization': AccessToken || ""
            }
        }
        const response = await axios.post(`${API_Base_Url + url}`, data, headers, { timeout: 5 }).then((response) => {
            return response
        }
        ).catch(error => {
            if (error.response.status === 400) {
                if (error.response.data.Error.ErrorMessage) {
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
            else if (error.response.status === 404) {
                if (error.response.data.Error.ErrorMessage) {
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
            else if (error.response.status === 500) {
                if (error.response.data.Error.ErrorMessage) {
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
        });
        return response.data
    } catch (error) {
        return toast.error('Something went wrong , Please check your internet connection.')
    }

};
