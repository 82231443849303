import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import { LoadList } from "../../../services/LoadList";
import Newsform from "./Add";
import { PostData } from "../../../services/PostData";


/* CreatedBy: Mayur
CreatedOn: 18 Jan 2022
Description : Registration 
*/
function OrganizersListFunc() {
    const navigate = useNavigate()
    const pagination = useRef();
    const [organizersList, setOrganizersList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(0);
    const [popuptype, setPopuptype] = useState("createNews");
    const [newsid, setNewsid] = useState("");

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])

    useEffect(() => {
        loadNews();
    }, [offset, number]);


    const loadNews = () => {
        setIsLoaded(false);
        const url = "organizer/list"
        LoadList(url).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setOrganizersList([])
                }
                else {
                    const data = result.data;
                    console.log(result, 'result.data')
                    if (data) {
                        setTotalData(data.length)
                        const slice = data.slice(offset, offset + perPage);
                        if (data.length > 0) {
                            setOrganizersList(slice);
                            setPageCount(Math.ceil(data.length / perPage));
                        }
                    }
                }
            }
            else {
                setOrganizersList([])
            }
            setIsLoaded(true);
        }).catch();

    }


    const ChangeStatus = (id, status) => {
        const req = {
            _id: id,
            action: status ? "deactivate" : "activate"
        }
        PostData(req, "organizer/action").then(res => {
            if (res !== undefined) {
                toast.success(res.data.message)
                loadNews();
            }
        })
            .catch()
    }

    function DeleteItem(id) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this Organizer?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        const req = {
                            _id: id,
                            action: "delete"

                        };
                        PostData(req, "organizer/action").then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                                loadNews();
                                setNumber(number + 1);
                            }
                        })
                            .catch()
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadNews()
                }
            ]
        });
    }


    const openOrganizersForm = (id) => {
        setNewsid(id);
        setPopuptype("createNews");
        window.$("#organizersformAdd").modal('show');
    }
    const loadNewsDetails = (id) => {
        setNewsid(id);
        setPopuptype("editNews");
        window.$("#organizersformEdit").modal('show');
    }
    const setPage = ({ selected }) =>
        setOffset(perPage * selected)
    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper cms">
                    <section className="content-header">
                        <h1>Organizers</h1>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box box-primary">
                                    <div className="box-header with-border">

                                        <button className="btn btn-sm btn-primary pull-right" onClick={(e) => openOrganizersForm(0)}><i className="fa fa-plus"></i> Add Organizer</button>
                                    </div>
                                    <div className="box-body">
                                        <div id="ActiveEvent" className=" ippftable">
                                            <div className="box-body no-padding table-responsive">
                                                <table className="table table-striped table-bordered">

                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "5%" }}>Sr.No.</th>
                                                            <th style={{ width: "20%" }}>Name</th>
                                                            <th style={{ width: "20%" }}>Designation</th>
                                                            <th style={{ width: "45%" }}>Details</th>
                                                            <th style={{ width: "10%" }}>Image</th>
                                                            <th style={{ width: "10%" }}>Sequence</th>
                                                            <th style={{ width: "5%" }}>Status</th>
                                                            <th style={{ width: "5%" }}>Delete</th>
                                                        </tr>
                                                        {
                                                            isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" width="100px" /></div>
                                                                :
                                                                <>
                                                                    {
                                                                        organizersList.length === 0 ?
                                                                            <tr><td colSpan='5'>No record found.</td></tr>
                                                                            :
                                                                            organizersList.map((item, i) => (
                                                                                <tr>
                                                                                    <td>{(i + 1) + offset}</td>
                                                                                    <td className="titlelink" onClick={() => loadNewsDetails(item._id)} >{item.Title}</td>
                                                                                    <td >{item.SubTitle}</td>
                                                                                    <td
                                                                                    dangerouslySetInnerHTML={{ __html: item.Details.length > 120 ? item.Details.slice(0, 120) + "..." : item.Details }}></td>
                                                                                    <td>{item.Image &&
                                                                                        <>
                                                                                            <img src={item.Image} style={{ width: "140px" }} />
                                                                                        </>}
                                                                                    </td>
                                                                                    <td>{item.Sequence}</td>

                                                                                    <td><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className={item.IsActive ? "btn btn-xs btn-info b-radius" : "btn btn-xs btn-success b-radius"}>{item.IsActive ? "Active" : "Inactive"}</button></td>
                                                                                    <td><button className="btn btn-xs btn-danger" onClick={(e) => DeleteItem(item._id)} >Delete</button></td>
                                                                                </tr>

                                                                            ))
                                                                    }
                                                                </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLoaded === true &&
                                <>
                                    {totalData > 10 &&
                                        <div className='col-md-4'>
                                            <ReactPaginate
                                                ref={pagination}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={4}
                                                marginPagesDisplayed={0}
                                                onPageChange={setPage}
                                                // previousLabel={<i className="fa fa-angle-left"></i>}
                                                activeClassName="active"
                                                containerClassName="pagination"
                                                // nextLabel={<i className="fa fa-angle-right"></i>}
                                                pageLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                previousLinkClassName="page-link"
                                                pageClassName="page-item"
                                                breakClassName="page-item"
                                                nextClassName="page-item"
                                                previousClassName="page-item"
                                                breakLabel="..."
                                                // breakClassName={"break-me"}
                                                // pageRangeDisplayed={3}
                                                previousLabel={<>&#x3C;</>}
                                                nextLabel={<>&#x3E;</>}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </section>
                </div>
            </div>
            <div id="organizersformAdd" className="modal fade" style={{ height: "100%" }}>
                <Newsform popuptype={popuptype} newsid={0} loadNews={loadNews} />
            </div>
            <div id="organizersformEdit" className="modal fade" style={{ height: "100%" }}>
                <Newsform popuptype={popuptype} newsid={newsid} loadNews={loadNews} />
            </div>
        </div>
    );
}
export default OrganizersListFunc;