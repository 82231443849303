


export const TextInput = ({ type, placeholder, onChange, value, autoComplete, name, className }) => {
    return (
        <>
            <input type={type} name={name} value={value} className={className} autoComplete={!autoComplete ? "off" : "on"} placeholder={placeholder} onChange={onChange} />
        </>
    )
}


export const HtmlValMsg = "Html tags are not allowed"