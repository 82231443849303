import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import { ContactUsList, FeedbackList, feedbackList } from '../../services/Event';
import moment from 'moment'
import format from "date-fns/format";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";


/* CreatedBy: Mayur
CreatedOn: 08 Feb 2022
Description : Feedback 
*/
function FeedbackAdmin() {
    const navigate = useNavigate()
    const pagination = useRef();
    const [feedbackList, setFeedbackList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(0);

    const [exportdata, setExportData] = useState([])
    const [exportdataall, setExportDataAll] = useState([])
    const fileName = "registrationdata"; // here enter filename for your excel file
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
                        toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])

    useEffect(() => {
        loadFeedbackList();
    }, [offset, number]);


    const loadFeedbackList = () => {
        setIsLoaded(false);
        FeedbackList().then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setFeedbackList([])
                }
                else {
                    const data = result.data;
                    setTotalData(data.length)
                    const slice = data.slice(offset, offset + perPage);
                    if (data.length > 0) {
                        var tempdata = data.map(item => {
                            return {
                                "Name": item.Name,
                                "Email": item.Email,
                                "Subject": item.Mobile,
                                "Message": item.Feedback,
                                "Posted On Date": format(new Date(item.PostedOn), "dd MMM yyyy"),
                            }
                        })
                        setExportData(tempdata)
                        if (data.length > 0) {
                            setFeedbackList(slice);
                            setPageCount(Math.ceil(data.length / perPage));
                        }
                    }
                }
            }
            else {
                setFeedbackList([])
            }
            setIsLoaded(true);
        }).catch();

    }

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const setPage = ({ selected }) =>
        setOffset(perPage * selected)

    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper cms">
                    <section className="content-header">
                        <h1>Feedback</h1>                        
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="box box-primary">
                            <div className="box-header with-border">
                            <h3 class="box-title">Feedback List</h3>
                            {totalData !== 0 &&
                                        <button onClick={(e) => exportToCSV(exportdata, fileName)} className="btn btn-sm btn-info pull-right"><img src="images/export.png " width="20px" className="mr-10" />Export</button>
                                    }
                            </div>
                            <div className="box-body">
                                <div id="ActiveEvent" className=" ippftable">
                                    <div className="box-body no-padding table-responsive">
                                        <table className="table table-striped table-bordered">                                            
                                            <tbody>
                                                <tr>
                                                    <th style={{width:"10%"}}>Sr.No.</th>
                                                    <th style={{width:"15%"}}>Posted On</th>
                                                    <th style={{width:"15%"}}>Name</th>
                                                    <th style={{width:"20%"}}>Email</th>
                                                    <th style={{width:"10%"}}>Mobile</th>
                                                    <th style={{width:"30%"}}>Feedback</th>
                                                </tr>
                                                {
                                                    isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" width="100px" /></div>
                                                        :
                                                        <>
                                                            {
                                                                feedbackList.length === 0 ?
                                                                    <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                    :
                                                                    feedbackList.map((item, i) => (
                                                                        <tr>
                                                                            <td>{(i + 1) + offset}</td>
                                                                            <td>{moment(new Date(item.PostedOn)).format("DD MMM YYYY hh:mm")}</td>
                                                                            <td>{item.Name}</td>
                                                                            <td>{item.Email}</td>
                                                                            <td>{item.Mobile}<br /></td>
                                                                            <td>{item.Feedback}</td>
                                                                        </tr>

                                                                    ))
                                                            }
                                                        </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                            {isLoaded === true &&
                                <>
                                    {totalData > 10 &&
                                        <div className='col-md-4'>
                                            <ReactPaginate
                                                ref={pagination}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={4}
                                                marginPagesDisplayed={0}
                                                onPageChange={setPage}
                                                // previousLabel={<i className="fa fa-angle-left"></i>}
                                                activeClassName="active"
                                                containerClassName="pagination"
                                                // nextLabel={<i className="fa fa-angle-right"></i>}
                                                pageLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                previousLinkClassName="page-link"
                                                pageClassName="page-item"
                                                breakClassName="page-item"
                                                nextClassName="page-item"
                                                previousClassName="page-item"
                                                breakLabel="..."
                                                // breakClassName={"break-me"}
                                                // pageRangeDisplayed={3}
                                                previousLabel={<>&#x3C;</>}
                                                nextLabel={<>&#x3E;</>}
                                            />
                                        </div>
                                    }


                                </>

                            }

                        </div>
                    </section>

                </div>

            </div>
        </div>
    );
}
export default FeedbackAdmin;