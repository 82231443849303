import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { UploadFile } from '../../../services/UploadFile';
import { CreatePartner, LoadPartner, UpdatePartner } from '../../../services/Event'
import { HtmlValMsg } from '../../../Inputs/TextInput';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const reg = /<(.|\n)*?>/g;

function Partnerform({ eventid, popuptype, partnerid, loadpartners }) {
    const [name, setName] = useState("")
    const [photo, setPhoto] = useState("")
    const [url, setURL] = useState("")
    const [contentimg, setContentimg] = useState("")



    useEffect(() => {
        if (popuptype === "createpartner")
            clearFields()
        if (popuptype === "editpartner") {
            loaddetails()
        }
    }, [popuptype, partnerid]);

    const loaddetails = () => {
        LoadPartner(partnerid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else {

                    setName(result.data.Name);
                    var changedPhotoUrl = result.data.Photo
                    setPhoto(changedPhotoUrl);
                    // setURL(result.data.URL);

                }

            }
            else {

            }
            //setIsLoaded(true);
        }).catch();
    }

    const clearFields = () => {
        setName('')
        setPhoto('')
        // setURL('')
        setContentimg("")

    }


    const handlePhoto = (e) => {
        setContentimg(e.target.value)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please Select Image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    var changedPhotoUrl = res.filepath
                    setPhoto(changedPhotoUrl)

                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Save = (e) => {
        if (name.trim() === "") {
            return toast.error("Please enter sponsor name");
        }
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }
        if (photo === "") {
            return toast.error("Please select photo/logo");
        }
        else {

            Addpartner();

        }
    }

    const Addpartner = async () => {
        try {

            const req = {
                eventid: eventid,
                name: name.trim(),
                photo: photo,
                url: url
            };
            CreatePartner(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    window.$("#partnerform").modal('hide');
                    clearFields();
                    loadpartners();
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        if (name.trim() === "") {
            return toast.error("Please enter sponsor name");
        }
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }
        if (photo === "") {
            return toast.error("Please select photo/logo");
        }
        else {

            Updatepartner();

        }
    }

    const Updatepartner = async () => {
        try {

            const req = {
                partnerid: partnerid,
                name: name.trim(),
                photo: photo,
                url: url
            };
            UpdatePartner(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#partnerform").modal('hide');
                    loadpartners();
                }
            })
                .catch()
        } catch (error) {

        }
    }

    return (
        <div className="modal-dialog" style={{ width: "50%" }}>
            <div className="modal-content" style={{ height: "50%" }}>

                <div className="modal-header">
                    <button type="button" onClick={clearFields} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                    {popuptype === "editpartner" ? <h4 className="modal-title">Update Sponsor</h4> : <h4 className="modal-title">Add Sponsor</h4>}
                </div>
                <div className="modal-body">
                    <form role="form" className="partnerform">
                        <div className="box-body">
                            <div className="form-group">
                                <label>Name *</label>
                                <input maxLength={50} type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} id="exampleInputEmail1" placeholder="Enter name" />
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label>Photo *</label>
                                    <input type="file" className="form-control" onChange={(e) => handlePhoto(e)} value={contentimg} />
                                </div>
                                <div className="col-md-6">
                                    <img src={photo.substr(0, 37) === "https://saroforums2021.org/api/static" ? "http://ippf.acolabz.com/api/static" + photo.substr(37) : photo} width="150px" />
                                </div>
                            </div>

                            {/* <div className="form-group">
                                        <label>URL</label>
                                        <input type="text" className="form-control" value={url} onChange={(e) => setURL(e.target.value)} id="exampleInputEmail1" placeholder="Enter URL" />
                                    </div> */}
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={clearFields} className="btn btn-default btn-sm pull-left" data-dismiss="modal">Close</button>
                    {popuptype === "editpartner" ? <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Update}>Update Partner</button>
                        : <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Save}>Add Partner</button>}
                </div>



            </div>

        </div>

    )
}

export default Partnerform

