import React, { useEffect, useState } from "react"
import { Header } from "../commonSection/Header"
import { EventAgendaSection } from "./EventAgendaSection"
import { Section } from "./Section"
import { Speakers } from "./Speakers"
import { Sponsors } from "./Sponsors"
import { OrganizingTeam } from "./OrganizingTeam"
import { LoadList } from "../../../services/LoadList"
import { useGetAllSpeakerQuery } from "../../../../features/LoadSepeakers"
import { ShimmerEffect } from "../../../styleUI/ShimmerEffect"
import { Footer } from "../commonSection/Footer"
import { Link } from "react-router-dom"
import LoaderSmall from "../../../styleUI/Loader"
import { Accordion } from "@material-ui/core"
import { accordianDataFaqData } from "../data/FaqData"
import SEO from "../commonSection/seo"


/*
CreatedBy: Mayur 
CreatedOn: 04 Jan 2022
Description : Landing Page Code
*/

const FeatureEvent = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [speakersData, setSpeakersData] = useState([])
    const [organizersData, setOrganizersData] = useState([])
    const [sessionsData, setSessionsData] = useState([])
    const [sponsersData, setSponsersData] = useState([])
    const [eventID, setEventID] = useState("")
    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [eventDate, setEventDate] = useState("")
    const [aboutEvent, setAboutEvent] = useState("")
    const [location, setLocation] = useState("")
    const [headerLogo, setHeaderLogo] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [address, setAddress] = useState("")
    const [twitter, setTwitter] = useState("")
    const [facebook, setFacebook] = useState("")
    const [youtube, setYoutube] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [eventImage, setEventImage] = useState("")
    const [ifFeatureEvent, setIfFeatureEvent] = useState(true)
    const [registrationType, setRegistrationType] = useState("")
    const [googleFormLink, setGoogleFormLink] = useState("")
    const [amount, setAmount] = useState(0)



    const { data, isError, isLoading } = useGetAllSpeakerQuery()


    // First Time only show live events
    useEffect(() => {
        window.scrollTo(0, 0);
        if (isLoading === false) {
            setIsLoaded(true)
            if (data) {
                console.log("data.....", data)
                if (data.data.length !== 0) {
                    localStorage.setItem("evnid", data.data[0]._id)
                    setIfFeatureEvent(true)
                    setEventID(data.data[0]._id)
                    setHeaderLogo(data.data[0].SponsorLogo)
                    loadSepeakersList(data.data[0]._id);
                    loadSessionsList(data.data[0]._id);
                    loadSponsersList(data.data[0]._id);
                    loadOrganizersList(data.data[0]._id);
                    setTitle(data.data[0].Title)
                    setLocation(data.data[0].Location)
                    setEventDate(data.data[0].Timing)
                    setSubTitle(data.data[0].SubTitle)
                    setAboutEvent(data.data[0].AboutEvent)
                    setRegistrationType(data.data[0].RegistrationType)
                    setGoogleFormLink(data.data[0].GoogleFormLink)
                    setAddress(data.data[0].OfficeAddress)
                    setMobile(data.data[0].Mobile)
                    setEmail(data.data[0].Email)
                    setWebsite(data.data[0].LinkURL)
                    setTwitter(data.data[0].Twitter)
                    setFacebook(data.data[0].Facebook)
                    setYoutube(data.data[0].Youtube)
                    setLinkedin(data.data[0].Linkedin)
                    setEventImage(data.data[0].EventImage)
                    setAmount(data.data[0].EventFee)
                } else {
                    setIfFeatureEvent(false)
                }
            }
        } else {
            setIsLoaded(true)
        }
    }, [isLoading])

    useEffect(() => {
        if (eventID !== "") {
            loadSepeakersList(eventID);
            loadSessionsList(eventID);
            loadSponsersList(eventID);
            loadOrganizersList(eventID);
        }
    }, [eventID])




    /* CreatedBy: Mayur
    CreatedOn: 05 Jan 2022
    Description :load Speakers
    */
    const loadSepeakersList = (eventID) => {
        LoadList(`web/speakers?eventid=${eventID}`).then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    setSpeakersData([])
                }
                else {
                    setSpeakersData(result.data)
                    console.log("speakers data", result.data)
                }
            } else {
                setSpeakersData([])
            }
            setIsLoaded(true);
        }).catch();
    }
    const loadOrganizersList = (eventID) => {
        LoadList(`web/organizers?eventid=${eventID}`).then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    setOrganizersData([])
                }
                else {
                    const filter = result.data.filter(e => e.IsActive === true)
                    setOrganizersData(filter)
                }
            } else {
                setOrganizersData([])
            }
            setIsLoaded(true);
        }).catch();
    }

    /* CreatedBy: Mayur
    CreatedOn: 05 Jan 2022
    Description :load Events 
    */
    const loadSessionsList = (eventID, name) => {
        LoadList(`web/eventdetails?id=${eventID}`).then(result => {
            console.log("event details", result)

            if (result !== undefined) {
                if (!result.data) {
                    setSessionsData([])
                }
                else {
                    setSessionsData(result.data.Schedules)
                }
            } else {
                setSessionsData([])
            }
            setIsLoaded(true);
        }).catch();
    }

    const loadSponsersList = (eventID) => {
        LoadList(`cms/partner/list?eventid=${eventID}`).then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    setSponsersData([])
                }
                else {
                    const filter = result.data.filter(e => e.IsActive === true)
                    setSponsersData(filter)
                }
            } else {
                setSponsersData([])
            }
            setIsLoaded(true);
        }).catch();

    };



    const AccordionHtml = ({ itemid, question, answer, collapseItem }) => {
        return (
            <div className="panel panel-default">
                <div className="panel-heading" role="tab" id={itemid}>
                    <h5 className="panel-title">
                        <a data-toggle="collapse" data-parent="#faq" href={"#" + collapseItem} aria-expanded="false" aria-controls={collapseItem}>
                            {question}
                        </a>
                    </h5>
                </div>
                <div id={collapseItem} className="panel-collapse collapse" role="tabpanel" aria-labelledby={itemid}>
                    <div className="panel-body">
                        {answer}
                    </div>
                </div>
            </div>
        )
    }



    /* CreatedBy: Mayur
    CreatedOn: 05 Jan 2022
    Description :Dashboard Html 
    */


    return (
        <>
        <SEO
        title='Upcoming Featured Event - Elevating Emergency Medicine Practice'
        description='Join our featured event to engage with top experts and peers in emergency medicine. Gain unparalleled insights and advance your practice through innovative discussions and networking opportunities designed for healthcare professionals.'
        keywords=''/>
            {ifFeatureEvent ?
                <div className="outerhomepage">
                    <Header headerLogo={headerLogo} page="feature-event" RegistrationType={registrationType} GoogleFormLink={googleFormLink} />
                    <div id="sectionField">
                        <Section RegistrationType={registrationType} GoogleFormLink={googleFormLink} eventImage={eventImage} title={title} location={location} subTitle={subTitle} eventDate={eventDate} aboutEvent={aboutEvent} amount={amount}/>
                    </div>
                    {/* CreatedBy: Mayur
                        CreatedOn: 05 Jan 2022
                        Description : Event Agenda Section
                    */}

                    {isLoading === true
                        ?
                        <ShimmerEffect type="bixWithUsers" text="Event Agenda" />
                        :
                        <EventAgendaSection data={sessionsData} />
                    }


                    {/* CreatedBy: Mayur
                        CreatedOn: 05 Jan 2022
                        Description : Speakers
                */}
                    {isLoading === true
                        ?
                        <ShimmerEffect type="bixWithUsers" text="Meet our Conference Speakers" />
                        :
                        <Speakers data={speakersData} />
                    }
                    {/* CreatedBy: Mayur
                CreatedOn: 09 August 2022
                Description : Our Sponsors
              */}

                    {/*Event Accordian Faq*/}
                    <section className="speakers" id="EventAgenda">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="section_header text-center">
                                        <h3>Frequently Ask Questions </h3>
                                    </div>
                                    <div className="our-partners">
                                        <div className="minheight">
                                            <div class="container-fluid">
                                                <div id="faq" role="tablist" aria-multiselectable="true">
                                                    {accordianDataFaqData.map((item, index) => (
                                                        <AccordionHtml key={"AccordionKey" + index} question={item.question} answer={item.answer} itemid={index + "-item"} collapseItem={index + "-collapseItem"} />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* CreatedBy: Mayur
                CreatedOn: 05 Jan 2022
                Description : Our Sponsors
              */}
                    {isLoading === true ?

                        <ShimmerEffect type="bigBoxSponsers" text="Our Sponsors" />
                        :
                        <Sponsors isLoaded={isLoaded} data={sponsersData} />
                    }


                    {/* CreatedBy: Mayur
                CreatedOn: 05 Jan 2022
                Description : Organizing Team
              */}
                    {isLoading === true ?

                        <ShimmerEffect type="bixWithUsers" text="Organizing Team" />
                        :
                        <OrganizingTeam data={organizersData} />
                    }
                    {isLoading === true ? "Loading..."
                        :
                        <>
                            <Footer type="" footerLogo={headerLogo} mobile={mobile} email={email} website={website} address={address} twitter={twitter} facebook={facebook} youtube={youtube} linkedin={linkedin} />
                        </>
                    }
                </div>
                :
                <>
                    <div className="container">
                        <div className="text-center" style={{ paddingTop: "20px" }}>
                            <h1>"Sorry, No feature events are active now.</h1>
                            <h3>Please check in sometime...." </h3>
                            <Link to="/" className="btn btn-primary">Home Page</Link>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default FeatureEvent;