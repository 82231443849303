import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { UploadFile } from '../../../services/UploadFile';
// import { UploadFile } from '../../services/UploadFile';
import { CreateSideEvent, LoadSideEvent, UpdateSideEvent } from '../../../services/Event'
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;


function SideEventform({ eventid, popuptype, sideeventid, loadsideevents }) {
    const [type, setType] = useState("")
    const [title, setTitle] = useState("")
    const [url, setURL] = useState("")
    const [thumbimg, setThumbimg] = useState("")
    const [tags, setTags] = useState("")
    const [isPopupLoaded, setIsPopupLoaded] = useState(false);
    const [contentimg, setContentImg] = useState("");
    const [contentfile, setContentFile] = useState("");


    useEffect(() => {
        if (popuptype === "createsideevent"){
            setIsPopupLoaded(true)
             clearFields()
        }
        if (popuptype === "editsideevent") {
            loaddetails()
            setIsPopupLoaded(true)
        }
    }, [popuptype, sideeventid]);

    const loaddetails = () => {
        LoadSideEvent(sideeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else {

                    setType(result.data.Type);
                    setTitle(result.data.Title);
                    setURL(result.data.URL);
                    setThumbimg(result.data.ThumbImage)
                    setTags(result.data.Tags)

                }

            }
            else {

            }

        }).catch();
    }

    const clearFields = () => {
        setThumbimg('')
        setTags('')
        setType('')
        setTitle('')
        setURL('')
        setThumbimg('')
     
        setTags('')
        setContentFile('')
        setContentImg('')
    }


    const handleFile = (e) => {
        setContentFile(e.target.value)
        setIsPopupLoaded(false)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            // if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            // }
            // else {
            //     return toast.error('Please Select Image only');
            // }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setURL(res.filepath)
                    setIsPopupLoaded(true)

                }
            })
                .catch()
        } catch (error) {

        }
    }

    const handleThumImg = (e) => {
        setIsPopupLoaded(false)
        setContentImg(e.target.value)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please Select Image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setThumbimg(res.filepath)
                    setIsPopupLoaded(true)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Save = (e) => {
        if (type === "Select") {
            toast.error("Please Select Media Type");
        }
        else if (title === "") {
            toast.error("Please Enter Title");
        }
        else if (url === "") {
            toast.error("Please Select File");
        }
        else {

            Addsideevent();

        }
    }

    const Addsideevent = async () => {
        try {

            const req = {
                eventid: eventid,
                type: type,
                title: title,
                url: url,
                thumbimg: thumbimg,
                tags: tags
            };
            CreateSideEvent(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#sideeventform").modal('hide');
                    loadsideevents();
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        if (type === "Select") {
            toast.error("Please Select Media Type");
        }
        else if (title === "") {
            toast.error("Please Enter Title");
        }
        else if (url === "") {
            toast.error("Please Select File");
        }
        else {

            Updatesideevents();

        }
    }

    const Updatesideevents = async () => {
        try {

            const req = {
                sideeventid: sideeventid,
                type: type,
                title: title,
                url: url,
                thumbimg: thumbimg,
                tags: tags
            };
            UpdateSideEvent(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#sideeventform").modal('hide');
                    loadsideevents();
                }
            })
                .catch()
        } catch (error) {

        }
    }

    return (
        <>
            <div id="sideeventform" className="modal fade" style={{ height: "100%" }}>
                <div className="modal-dialog" style={{ width: "50%" }}>
                    <div className="modal-content" style={{ height: "50%" }}>

                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            {popuptype === "editsideevent" ? <h4 className="modal-title">Update Side Event</h4> : <h4 className="modal-title">Add Side Event</h4>}
                        </div>
                        <div className="modal-body">
                            <form role="form" className="sideeventform">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label> Type</label>
                                        <select className="form-control" value={type} onChange={(e) => setType(e.target.value)} >
                                            <option value="Select">Select</option>
                                            <option value="Photo">Photo </option>
                                            <option value="Video">Video </option>
                                            <option value="Document">Document </option>
                                            <option value="Presentation">Presentation</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label>Title</label>
                                        <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} id="exampleInputEmail1" placeholder="Enter Title" />
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>Upload File</label>
                                            <input type="file" className="form-control" onChange={(e) => handleFile(e)} id="exampleInputPassword1" value={contentfile} />
                                        </div>
                                        <div className="col-md-6">
                                            <br /><br />
                                            <a href={url} style={url !== "" && url !== " " ? { visibility: "visible" } : { visibility: "hidden" }} target="_blank">View</a>
                                            {/* <img src={photo} width="150px" /> */}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>Thumb Image</label>
                                            <input type="file" className="form-control" onChange={(e) => handleThumImg(e)} id="exampleInputPassword1"  value={contentimg}/>
                                        </div>
                                        <div className="col-md-6">

                                            <img src={thumbimg} width="150px" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <input type="text" className="form-control" value={tags} onChange={(e) => setTags(e.target.value)} id="exampleInputEmail1" placeholder="Enter Tag" />
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-sm pull-left" data-dismiss="modal">Close</button>
                            {/* {popuptype === "editsideevent" ? <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Update}>Update Side Event</button>
                                    : <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Save}>Add Side Event</button>} */}
                            {isPopupLoaded ? <>
                                {popuptype === "editsideevent" ? <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Update}>Update Side Event</button>
                                    : <button className="btn btn-primary btn-sm pull-right" onClick={Submit_Save}>Add Side Event</button>}</> : <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>}

                        </div>



                    </div>

                </div>
            </div>
        </>

    )
}

export default SideEventform

