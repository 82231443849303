import React, { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {  SideEventAction, SideEventList } from '../../services/Event';
import SideEventform from './popup/AddSideEvent'



function SideEvents() {

    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [selectedsideeventid, setSideEventid] = useState("");
    const [popuptype, setPopuptype] = useState("");
    const [sideeventList, setSideEventList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [number, setNumber] = useState(0);

    useEffect(() => {
        loadsideevents();
    }, [offset, number]);


    const opensideform = () => {
        setPopuptype("createsideevent");
        window.$("#sideeventform").modal('show');
    }

    const loadsideevents = () => {
        setIsLoaded(false);
        SideEventList(selectedeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setSideEventList([])
                }
                else {
                    const data = result.data;
                    if (data.length > 0) {
          
                        setSideEventList(result.data);
                    }
                }
            }
            else {
                setSideEventList([])
            }
            setIsLoaded(true);
        }).catch();
    }

    function ChangeStatus(sideeventid, status) {
        var updatestatus = status ? "Deactivate" : "Activate"
        const req = {
            sideeventid: sideeventid,
            action: updatestatus

        };
        SideEventAction(req).then(res => {
            if (res !== undefined) {
                toast.success(res.message)
            }
        })
        loadsideevents();
        setNumber(number + 1);
    }

    function Delete(sideeventid) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this event?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        const req = {
                            sideeventid: sideeventid,
                            action: "Delete"

                        };
                     SideEventAction(req).then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                                loadsideevents();
                            }
                        }).catch()
                        loadsideevents();
                        setNumber(number + 1);
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadsideevents()
                }
            ]
        });
    }

    const loadSideEventDetails = (sideeventid) => {
        setSideEventid(sideeventid)
        setPopuptype("editsideevent");
    }

    useEffect(() => {
        if (selectedsideeventid !== "")
            window.$("#sideeventform").modal('show');
  
    }, [selectedsideeventid])

    return (
        <div className="hold-transition skin-blue sidebar-mini">

            <div className="wrapper">

                <div className="content-wrapper cms">
                    <section className="content-header">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Side Event List</h3>
                                <button className="btn btn-sm btn-primary pull-right" onClick={opensideform}><i className="fa fa-plus"></i> Add New</button>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div id="partners" className=" ippftable">
                                    <div className="box-body no-padding table-responsive">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Type</th>
                                                    <th>URL</th>
                                                    <th>Thumb Image</th>
                                                    <th>Tags</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                {
                                                    isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                                        :
                                                        <>
                                                            {
                                                                sideeventList.length === 0 ?
                                                                    <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                    :
                                                                    sideeventList.map((item, i) => (
                                                                        <tr>
                                                                            <td className="titlelink" onClick={(e) => loadSideEventDetails(item._id)}>{item.Title}</td>
                                                                            <td>{item.Type}</td>
                                                                            <td><a href={item.URL} target="_blank">View</a></td>
                                                                            <td><img src={item.ThumbImage} width="100px"/></td>
                                                                            <td>{item.Tags}</td>
                                                                            <td><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className={item.IsActive ? "btn btn-xs btn-info b-radius" : "btn btn-xs btn-success b-radius"}>{item.IsActive ? "Active" : "Inactive"}</button></td>
                                                                            <td><button onClick={(e) => Delete(item._id)} className="btn btn-xs btn-danger">Delete</button></td>
                                                                        </tr>

                                                                    ))
                                                            }
                                                        </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </section>
                    {/* <Footer total={speakerList.length} setOffset={setOffset} perpage={perPage} pageCount={pageCount} /> */}
                </div>

            </div>
            <SideEventform eventid={selectedeventid} popuptype={popuptype} sideeventid={selectedsideeventid} loadsideevents={loadsideevents} />

        </div>


    );

}
export default SideEvents;