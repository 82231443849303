import moment from "moment"
import { useEffect, useState } from "react"
import { LoadList } from "../../services/LoadList"
import { ShimmerEffect } from "../../styleUI/ShimmerEffect"
import { Footer } from "./commonSection/Footer"
import { Header } from "./commonSection/Header"
import { NewsPopUp } from "./homepage/NewsPopup"
import  SEO from "../public/commonSection/seo"

const NewsViewAll = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [newsList, setNewsList] = useState([])
    const [title, setTitle] = useState("")
    const [details, setDetails] = useState("")
    const [image, setImage] = useState("")
    const [postedOn, setPostedOn] = useState("")
    
    useEffect(() => {
        window.scrollTo(0, 0);
        LoadNewsList()
    }, [])


    const LoadNewsList = () => {
        setIsLoaded(false)
        const url = "news/list"
        LoadList(url).then(res => {
            const result = res;
            if (result !== undefined) {
                if (!result.data) {
                    return setNewsList([])
                }
                else {
                    const data = result.data;
                    setIsLoaded(true);
                    return setNewsList(data)
                }
            }
            else {
                setNewsList([])
            }
        }).catch();

    }

    const onDetailsOpen = (id, Title, Details, Image, PostedOn) => {
        setTitle(Title)
        setDetails(Details)
        setImage(Image)
        setPostedOn(PostedOn)
        window.$("#ViewNewsPopup").modal('show');
    }
    return (
        <>
        <SEO
        title='EM Sling News - Advancing Emergency Medical Practice Through Global Expertise'
        description='Discover the latest advancements and shared experiences in emergency medicine. Stay informed with news from masters across various subspecialties and regions, aimed at enhancing the knowledge and capabilities of emergency medical practitioners.'
        keywords='Advancing Emergency Medical Practice Through Global Expertise'/>
     
        <div className="outerhomepage">
           
            <Header RegistrationType={localStorage.getItem("RegistrationType")} GoogleFormLink={localStorage.getItem("GoogleFormLink")} />
            <div>

                <div className="top-section breadcrumb about-bd">
                    <span className="overlay" />
                    <div className="bdsec">
                        <div className="container">
                            <h2 className="mtb-0">News</h2>
                        </div>
                    </div>
                </div>
                <section className="newsevent padding100">
                    {isLoaded === false ?
                        <>
                            <div className="container">
                                <ShimmerEffect type="card" />
                                <ShimmerEffect type="card" />
                                <ShimmerEffect type="card" />
                                <ShimmerEffect type="card" />
                                <ShimmerEffect type="card" />
                                <ShimmerEffect type="card" />
                            </div>

                        </>
                        :
                        <div className="container">
                            {newsList.map((item, index) => (
                                <div className="post_grid">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <div className="post_figure">
                                                <img src={item.Image} alt={item.Image} />
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="post_headings">
                                                <h2>{item.Title.length > 65 ? item.Title.slice(0, 65) + "..." : item.Title}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: item.Details.length > 160 ? item.Details.slice(0, 160) + "..." : item.Details }}>
                   
                                                    
                                                </div>
                                            </div>
                                            <div className="post_body">
                                                <div className="post_info">
                                                    <div className="mb-10">
                                                        <span><i className="fa fa-calendar" />{item.PostedOn&&moment(new Date(item.PostedOn)).format("MMM D,YYYY")}</span>
                                                    </div>
                                                    <div className="mt-20">
                                                        <a className="btn btn-primary" onClick={() => onDetailsOpen(item._id, item.Title, item.Details, item.Image, item.PostedOn)} >
                                                            View Detail
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}

                        </div>

                    }

                </section>
            </div>

            <NewsPopUp popupId="AllNewsListPopup" imageUrl={image} isLoaded={true} title={title} date={moment(new Date(postedOn)).format("MMM d,YYYY")} details={details} />


            <Footer />


        </div>
        </>
    )
}
export default NewsViewAll