import { Link, useLocation, useNavigate } from "react-router-dom"
import moment from "moment";
import { useState, useEffect } from "react";
import { LoadList } from "../../../services/LoadList";
import { ShimmerEffect } from "../../../styleUI/ShimmerEffect";

export const Footer = ({ type, footerLogo, mobile, email, website, address, twitter, facebook, youtube, linkedin }) => {
    const location = useLocation()
    const [isLoaded, setIsLoaded] = useState(false)
    const [homeImage, setHomeImage] = useState(false)

    useEffect(() => {
        setIsLoaded(true);
        if (type === "archive-details" || type === "feature-event") {
            setHomeImage(false)
        } else {
            setHomeImage(true)
        }
    }, [])

    const loadAllEventsList = () => {
        LoadList(`cms/event/list`).then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    // setData([])
                }
                else {
                    const archiveData = result.data.filter(e => e.IsActive === false)
                    // setData(archiveData)
                }
            } else {
                // setData([])
            }
            setIsLoaded(true);
        }).catch();
    }
    return (
        <div>
            {/* Footer */}
            {type === "archive-details" || type === "feature-event" ?

                <footer className="footer_top" id="AboutFooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-6">
                                <div className="latest_news footerleft">

                                    <div className="social ">
                                        {twitter &&
                                            <a href={twitter.slice(0, 7) === "http://" ? twitter : "http://" + twitter} target="_blank" title="Twitter"><i className="fa fa-twitter" />
                                            </a>
                                        }
                                        {facebook &&
                                            <a href={facebook.slice(0, 8) === "https://" ? facebook : "https://" + facebook} target="_blank" title="Facebook"><i className="fa fa-facebook" />
                                            </a>
                                        }
                                        {youtube &&
                                            <a href={youtube.slice(0, 7) === "http://" ? youtube : "http://" + youtube} target="_blank" title="Youtube"><i className="fa fa-youtube" />
                                            </a>
                                        }
                                        {linkedin
                                            &&
                                            <a href={linkedin.slice(0, 7) === "http://" ? linkedin : "http://" + linkedin} target="_blank" title="LinkedIn"><i className="fa fa-linkedin" />
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2" />
                            <div className="col-lg-5 col-md-6 col-sm-6">
                                <div className="footerleft">
                                    <div className="">

                                        {/* Mobile */}
                                        {mobile &&
                                            <div className="contact_detail"><i className="fa fa-phone" /><span>{mobile}</span></div>
                                        }
                                        {/* Email */}
                                        {email
                                            &&
                                            <div className="contact_detail"><i className="fa fa-envelope" /><span>{email}</span></div>
                                        }

                                        {/* Website Link */}
                                        {website &&
                                            <div className="contact_detail">
                                                <a href={website} target="_blank">
                                                    <i className="fa fa-globe" />
                                                    <span style={{ color: "white" }}>
                                                      
                                                            {website}
                                                        
                                                    </span>
                                                </a>
                                            </div>
                                        }

                                        {address &&
                                            <div className="contact_detail">
                                                <i className="fa fa-map-marker" />
                                                <span style={{ color: "white" }}>{address}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                :
                <footer className="footer_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-6">
                                <div className="footerleft">
                                    <div className="footer_logo">
                                        <img src="images/footer-logo.png" alt />
                                        <div className="latest_news footerleft">
                                            <div className="social">
                                                {/* <a href="#" title="Twitter"><i className="fa fa-twitter" /></a> */}
                                                <a href="https://www.facebook.com/singaporeemsling" target="_blank" title="Facebook"><i className="fa fa-facebook" /></a>
                                                <a href="https://www.instagram.com/singaporeemsling/?hl=en" target="_blank" title="Instagram"><i className="fa fa-instagram" /></a>
                                                {/* <a href="#" title="LinkedIn"><i className="fa fa-linkedin" /></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2" />
                            <div className="col-lg-5 col-md-6 col-sm-6">
                                <div className="latest_news footerleft">
                                    <div className="contact_detail"><i className="fa fa-phone" /><span>(+65) 68078211</span></div>
                                    <div className="contact_detail"><i className="fa fa-envelope" /><span>singaporeemsling@gmail.com</span></div>
                                    <div className="contact_detail"><i className="fa fa-globe" /><span>www.emsling.com</span></div>
                                    <div className="contact_detail">
                                       <i className="fa fa-map-marker" />
                                        <span>
                                            Singapore EM Sling<br/>
                                            Acute and Emergency Medicine Centre<br />
                                            Khoo Teck Puat Hospital <br />
                                            90, Yishun Central, <br /> 
                                            Singapore 768828
                                        </span>
                                    </div>

                                  
                                    <div className="contact_detail">
                                        <a href="https://emsling.com/#/admin-login"
                                        style={{ color: "white" }} target="blank">
                                            <i className="fa fa-user" />
                                            <span>
                                            EM Sling CMS
                                            </span>
                                        </a>
                                        </div>
                                        <div className="contact_detail">
                                      

                                        <a href="https://admin.emsling.com/" style={{ color: "white" }} 
                                        target="blank"
                                        >
                                            <i className="fa fa-question" />
                                            <span>EM Sling Quiz  CMS</span>  
                                        </a>
                                        </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            }


            {/*Bottom Footer Bar*/}
            {
                type === "archive-details" || type == "feature-event" ? "" :
                    <div className="copyright dark_section" >
                        <div className="dark_section transparent_film">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        Copyright © 2022 Singapore EM Sling
                                    </div>
                                    <div className="col-sm-6 col-md-6 text-right">
                                        Designed by <a href="https://acompworld.com/" target="_blank" style={{ color: '#45aaff' }}>Acompworld</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div >
    )
}


// const PastEventsListItem = ({ id, title, Timing, eventDate, EventImage }) => {
//     const date = moment(new Date(eventDate)).format("ddd, MMM D")
//     const toDetailsOfAchiveEvents = (id) => {
//         window.open(`#/event/${id}`, "_blank");
//     }
//     return (
//         <li className="footermedia">
//             <a style={{ cursor: "pointer" }} onClick={() => { toDetailsOfAchiveEvents(id) }} className="media-photo"><img className={classes.imgClass} src={EventImage || "/"} alt={title} /></a>
//             <h5 className="media-heading">
//                 <a style={{ cursor: "pointer" }} onClick={() => { toDetailsOfAchiveEvents(id) }}  >{title}</a>
//                 <div className="mt-10">{Timing} <br />{date}</div>
//             </h5>
//         </li>
//     )

// }