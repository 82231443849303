import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { UploadFile } from '../../services/UploadFile';
import { SubEventList, SubEventAction, DateAction, SessionAction } from '../../services/Event';
import ScheduleSubevent from './popup/ScheduleSubevent';
import ScheduleDate from './popup/ScheduleDate';
import ScheduleSession from './popup/ScheduleSession';
import moment from 'moment';
import Sessionform from './popup/ScheduleSession';
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../features/Counter";

function Schedule() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [screentype, setScreentype] = useState(localStorage.getItem("screentype"));
    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [subeventid, setSubEventid] = useState("");
    const [selecteddateid, setDateid] = useState("");
    const [selectedsessionid, setSessionid] = useState("")
    const [popuptype, setPopuptype] = useState("");
    const [datepopuptype, setDatePopuptype] = useState("");
    const [selecteddate, setDate] = useState("");
    const [errors, setError] = useState({});
    const [subeventList, setSubEventList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [number, setNumber] = useState(0);
    const [sessionDateid, setSessionDateid] = useState("");
    const eventName = localStorage.getItem("EventName")

    useEffect(() => {
        loadSubevent(selectedeventid);
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, []);

    const loadSubevent = (eventid) => {
        setIsLoaded(false);
        SubEventList(eventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setSubEventList([])
                }
                else {
                    const data = result.data;

                    // const slice = data.slice(offset, offset + perPage);
                    if (data.length > 0) {
                        setSubEventList(data);
                        // setPageCount(Math.ceil(data.length / perPage));
                    }
                }
            }
            else {
                setSubEventList([])
            }
            setIsLoaded(true);
        }).catch();

    }


    const EditDate = (dateid) => {
        setDateid(dateid)
        setDatePopuptype("editdate");
        window.$("#dateform").modal('show');
    }

    const DeleteDate = (dateid) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this date?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const req = {
                            dateid: dateid,
                            action: "Delete"

                        };
                        DateAction(req).then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                                loadSubevent(selectedeventid);
                            }
                        }).catch();
                        const timer = setTimeout(() => {
                            loadSubevent(selectedeventid);
                            setNumber(number + 1);
                        }, 300);
                        return () => clearTimeout(timer);

                    }
                },
                {
                    label: 'No',
                    onClick: () => loadSubevent(selectedeventid)
                }
            ]
        });

    }

    const EditSession = (sessionid) => {
        setSessionid(sessionid)
        setPopuptype("editsession");
        window.$("#sessionform").modal('show');
    }


    function DeleteSession(sessionid) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this session?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        const req = {
                            sessionid: sessionid,
                            action: "Delete"

                        };
                        SessionAction(req).then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                            }
                        }).catch();
                        const timer = setTimeout(() => {
                            loadSubevent(selectedeventid);
                            setNumber(number + 1);
                        }, 300);
                        return () => clearTimeout(timer);
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadSubevent(selectedeventid)
                }
            ]
        });
    }


    const opensubeventform = () => {
        setPopuptype("createsubevent");
        window.$("#subeventform").modal('show');
    }

    const opendateformpopup = (id) => {
        setSubEventid(id)
        setDatePopuptype("createdate");
        window.$("#dateform").modal('show');
    }


    const opensessionformpopup = (id, date) => {
        setSessionDateid(id)
        setDate(date)
        setPopuptype("createsession");
        window.$("#sessionform").modal('show');

    }
    const Cancelclick = (e) => {
        navigate("/admin-events")
        localStorage.setItem("screentype", "create");
        dispatch(decrement())
    }
    const gotoEventDetails = (e) => {
        navigate("/admin/event-add")
    }


    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">

                <div className="content-wrapper cms">
                    <section className="content-header">
                        <ol class="breadcrumbCustom">
                            <li><button onClick={Cancelclick}><i class="fa fa-dashboard"></i>Event List</button></li>
                            <li><button onClick={gotoEventDetails}> {eventName} </button></li>
                            <li><button >Schedules/Agenda</button></li>
                        </ol>
                        <button className="btn btn-sm btn-primary pull-right" onClick={(e) => opensubeventform()}><i className="fa fa-plus"></i> Add New Subevent</button>
                        <div style={{ clear: 'both' }}></div>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                        :
                                        <>
                                            {
                                                subeventList.length === 0 ?
                                                    <tr><td colSpan='5'>No Record Found.</td></tr>
                                                    :
                                                    subeventList.map((item, i) => (
                                                        <div className="box box-primary">
                                                            <div className="box-header with-border">
                                                                <h3 className="box-title fttitle">{item.Title}</h3>
                                                                <button className="btn btn-warning btn-sm  pull-right" onClick={(e) => opendateformpopup(item._id)}><i className="fa fa-plus"></i> Add Date</button>

                                                            </div>
                                                            {/* <!-- /.box-header --> */}
                                                            {/* <!-- form start --> */}
                                                            <div className="box-body">
                                                                {item.Details.map((obj_item, j) => (
                                                                    <div>
                                                                        <div className="session-title">
                                                                            <h3 className="eventdate">
                                                                                {moment(new Date(obj_item.Date)).format("DD MMM YYYY")}, {obj_item.Day}
                                                                                <span className="hovericon">
                                                                                    <a onClick={(e) => EditDate(obj_item._id)}><i className="fa fa-edit"></i></a>
                                                                                    <a onClick={(e) => DeleteDate(obj_item._id)}><i className="fa fa-trash"></i></a>
                                                                                </span>
                                                                            </h3>
                                                                            <button className="btn btn-primary  btn-sm  pull-right" onClick={(e) => opensessionformpopup(obj_item._id, obj_item.Date)}><i className="fa fa-plus"></i> Add Session</button>
                                                                            <div style={{ clear: "both" }}></div>
                                                                        </div>
                                                                        <table className="table table-striped table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{ width: "150px" }}>Timing</th>
                                                                                    <th style={{ width: "350px" }}>Title</th>
                                                                                    <th style={{ width: "350px" }}>Details</th>
                                                                                    <th style={{ width: "150px" }}>Start Time</th>
                                                                                    <th style={{ width: "150px" }}>End Time</th>
                                                                                    <th style={{ width: "150px" }}>Timings</th>
                                                                                    <th style={{ width: "100px", }}>Action</th>
                                                                                </tr>
                                                                                {obj_item.Sessions.map((obj_session, k) => (
                                                                                    <tr>
                                                                                        <td>{obj_session.Timing}</td>
                                                                                        <td>{obj_session.Title}</td>
                                                                                        <td><p dangerouslySetInnerHTML={{ __html: obj_session.Details }}></p></td>
                                                                                        <td>{obj_session.StartTime}</td>
                                                                                        <td>{obj_session.EndTime}</td>
                                                                                        <td>{obj_session.Timing}</td>
                                                                                        <td>
                                                                                            <button onClick={(e) => EditSession(obj_session._id)} className="mr-10"><i className="fa fa-edit"></i></button>
                                                                                            <button onClick={(e) => DeleteSession(obj_session._id)}><i className="fa fa-trash "></i></button>
                                                                                        </td>

                                                                                    </tr>
                                                                                ))}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </section>
                    {/* <Footer total={speakerList.length} setOffset={setOffset} perpage={perPage} pageCount={pageCount} /> */}
                </div>

            </div>
            <Sessionform eventid={selectedeventid} dateid={sessionDateid} date={selecteddate} popuptype={popuptype} sessionid={selectedsessionid} loadSubevent={loadSubevent} />
            <ScheduleSubevent eventid={selectedeventid} popuptype={popuptype} subeventid={subeventid} loadSubevent={loadSubevent} />
            <ScheduleDate eventid={selectedeventid} datepopuptype={datepopuptype} subeventid={subeventid} dateid={selecteddateid} loadSubevent={loadSubevent} />
            <ScheduleSession eventid={selectedeventid} popuptype={popuptype} sessionid={selectedsessionid} date={selecteddate} dateid={selectedsessionid} loadSubevent={loadSubevent} />
        </div>
    );
}
export default Schedule