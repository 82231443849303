import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { UploadFile } from '../../../services/UploadFile';
import { SubEventList, CreateSpeaker, LoadSpeaker, UpdateSpeaker } from '../../../services/Event'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { HtmlValMsg } from '../../../Inputs/TextInput';
import { LoaderSmall } from '../../..';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL;
const reg = /<(.|\n)*?>/g;



function Speakersform({ eventid, popuptype, speakerid, loadSpeakers, sequenceno }) {
    const [name, setName] = useState("")
    const [orgn, setOrgn] = useState("")
    const [desig, setDesig] = useState("")
    const [profilepic, setProfilepic] = useState("")
    const [biodata, setBioData] = useState("")
    const [sequence, setSequence] = useState(sequenceno)
    const [linkedinurl, setLinkedinURL] = useState("")
    const [twitterurl, setTwitterURL] = useState("")
    const [facebookurl, setFacebookURL] = useState("")
    const [instagramurl, setInstagramURL] = useState("");
    const [subeventlist, setSubeventList] = useState("");
    const [subevent, setSubevent] = useState("");
    const [contentimg, setContentimg] = useState("")
    const [isDataLoaded, setIsDataLoaded] = useState(false)


    const onProfile = (value) => {
        setBioData(value);
    }

    useEffect(() => {
        setIsDataLoaded(false)
        loadSubEventlist();
        if (popuptype === "createspeaker") {
            setIsDataLoaded(true)
            clearFields()
        }
        if (popuptype === "editspeaker") {
            clearFields()
            setIsDataLoaded(false)
            loaddetails()
        }
    }, [popuptype, speakerid]);

    const loadSubEventlist = () => {
        SubEventList(eventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else {
                    setSubeventList(result.data)
                }

            }
            else {

            }
            //setIsLoaded(true);
        }).catch();
    }

    const loaddetails = () => {

        LoadSpeaker(speakerid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else {

                    setName(result.data.Name);
                    setOrgn(result.data.Organization)
                    setDesig(result.data.Designation);
                    var changedPhotoUrl = result.data.ProfilePic.substr(0, 37) === "https://saroforums2021.org/api/static" ? API_Base_Url + "static" + result.data.ProfilePic.substr(37) : result.data.ProfilePic
                    setProfilepic(changedPhotoUrl)
                    setSequence(result.data.Sequence);
                    setBioData(result.data.Biodata);
                    setLinkedinURL(result.data.LinkedinURL);
                    setTwitterURL(result.data.TwitterURL);
                    setFacebookURL(result.data.FacebookURL)
                    setInstagramURL(result.data.InstragramURL)
                    setSubevent(result.data.SubEvent)
                    setIsDataLoaded(true)
                }

            }
            else {

            }
            //setIsLoaded(true);
        }).catch();
    }

    const clearFields = () => {
        setName('')
        setOrgn('')
        setDesig('')
        setProfilepic('')
        setSequence(sequenceno)
        setLinkedinURL('')
        setTwitterURL('')
        setFacebookURL('')
        setInstagramURL('')
        setSubevent('Select')
        setContentimg('')
        setBioData("")
    }



    const handleProfilepic = (e) => {
        setContentimg(e.target.value)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please Select Image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    var changedPhotoUrl = res.filepath.substr(0, 37) === "https://saroforums2021.org/api/static" ? API_Base_Url + "static" + res.filepath.substr(37) : res.filepath
                    setProfilepic(changedPhotoUrl)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Save = (e) => {
        if (name.trim() === "") {
            return toast.error("Please enter name");
        }
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }
        if (subevent === "Select") {
            return toast.error("Please select sub event");
        }
        if (profilepic === "") {
            return toast.error("Please select photo");
        }

        else {

            AddSpeakers();

        }
    }

    const AddSpeakers = async () => {
        try {

            const req = {
                eventid: eventid,
                name: name.trim(),
                subevent: subevent,
                orgn: orgn,
                designation: desig.trim(),
                profilepic: profilepic,
                linkedinurl: linkedinurl.trim(),
                twitterurl: twitterurl.trim(),
                facebookurl: facebookurl.trim(),
                instagramurl: instagramurl.trim(),
                biodata: biodata,
                sequence: sequence
            };

            CreateSpeaker(req).then(res => {

                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    loadSpeakers();
                    window.$("#speakerform").modal('hide');
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Submit_Update = (e) => {
        if (name.trim() === "") {
            toast.error("Please enter name");
        }
        if (reg.test(name) === true) {
            return toast.error(HtmlValMsg);
        }
        if (subevent === "Select") {
            toast.error("Please select sub event");
        }
        if (profilepic === "") {
            toast.error("Please select photo");
        }

        else {

            UpdateSpeakers();

        }
    }

    const UpdateSpeakers = async () => {
        try {

            const req = {
                speakerid: speakerid,
                name: name.trim(),
                subevent: subevent,
                orgn: orgn,
                designation: desig,
                profilepic: profilepic,
                linkedinurl: linkedinurl,
                twitterurl: twitterurl,
                facebookurl: facebookurl,
                instagramurl: instagramurl,
                biodata: biodata,
                sequence: sequence
            };
            UpdateSpeaker(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    window.$("#speakerform").modal('hide');
                    loadSpeakers();
                }
            })
                .catch()
        } catch (error) {

        }
    }

    let SubeventList = subeventlist.length > 0
        && subeventlist.map((item, i) => {
            return (
                <option value={item.id}>{item.Title}</option>
            )
        }, this)

    return (
        <div className="modal-dialog" style={{ width: "50%" }}>
            <div className="modal-content" style={{ height: "50%" }}>
                <div className="modal-header">
                    {popuptype === "editspeaker" ? <div className="col-md-6"><h3>Edit Speaker</h3></div> : <div className="col-md-6"><h3>Add Speaker</h3></div>}
                    {/* <div className="closeright"><a onClick={closepopup} aria-label="Close"><img src="images/close.svg" /></a></div> */}
                    <div className="closeright"><a type="button" onClick={clearFields} data-dismiss="modal" aria-label="Close"><img src="images/close.svg" /></a></div>
                </div>
                <form role="form" className="speakerform">
                    {isDataLoaded === false ?  <div className="loaderAdminInSidePopup"><img src="images/loader.gif" alt="" /></div> :
                        <div className="box-body">
                            <div className="form-group">
                                <label >Name<span style={{ color: "red" }}>*</span></label>
                                <input maxLength={50} type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} id="exampleInputEmail1" placeholder="Enter Name" />
                            </div>
                            <div className="form-group">
                                <label>Sub Event<span style={{ color: "red" }}>*</span></label>
                                <select className="form-control" value={subevent} onChange={(e) => setSubevent(e.target.value)}>
                                    <option value="Select">Select</option>
                                    {SubeventList}
                                </select>
                            </div>
                            <div className="form-group">
                                <label >Organization</label>
                                <input maxLength={200} type="text" className="form-control" value={orgn} onChange={(e) => setOrgn(e.target.value)} id="exampleInputPassword1" placeholder="Enter Organization" />
                            </div>
                            <div className="form-group">
                                <label >Designation</label>
                                <input maxLength={100} type="text" className="form-control" value={desig} onChange={(e) => setDesig(e.target.value)} id="exampleInputPassword1" placeholder="Enter Designation" />
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label >Photo<span style={{ color: "red" }}>*</span></label>
                                    <input type="file" className="form-control" value={contentimg} onChange={(e) => handleProfilepic(e)} placeholder="Seelect Profile Pic" />
                                </div>
                                <div className="form-group">

                                    <img src={profilepic} width="100px" />
                                </div>
                            </div>
                            <div className="form-group" style={{ height: "320px" }}>
                                <label >Profile</label>
                                <ReactQuill
                                    theme="snow"
                                    value={biodata}
                                    onChange={onProfile}
                                    placeholder={"Write something..."}
                                    style={{ height: "250px" }}
                                />
                            </div>

                        </div>

                    }

                </form>
                {popuptype === "editspeaker" ? <button className="btn btn-primary btn-sm" onClick={Submit_Update}>Update</button>
                    : <button className="btn btn-primary btn-sm" onClick={Submit_Save}>Add</button>}
            </div>
        </div>

    )
}

export default Speakersform

