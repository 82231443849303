import React, { useState } from 'react'
import { ShimmerEffect } from '../../../styleUI/ShimmerEffect'

export const SpeakerCard = ({ imagename, speakername, designation, onClick, index }) => {
    const [isImgLoaded, setIsImgLoaded] = useState(false)
    return (
        <>

            <div className="team_member">
                <a data-toggle="modal" data-target="#SpeakerDetailsModal" onClick={onClick}>
                    <div className="team_photo">
                        <img style={{ display: "none" }} src={imagename} onLoad={(e) => setIsImgLoaded(true)} onError={true} />
                        {isImgLoaded === false ?
                            <ShimmerEffect type="avatarImg" />
                            :
                            <img src={imagename} alt={imagename} className='spkphoto' />
                        }
                    </div>
                </a>

                <div className="team_detail">
                    <h4>{speakername}</h4>
                    <p>{designation}</p>
                </div>
            </div>

        </>
    )
}
