import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { toast } from 'react-toastify';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ReactHtmlParser from 'react-html-parser'
import { LoadList } from "../../services/LoadList";
import { EventAction } from '../../services/Event';
import { useSelector, useDispatch } from "react-redux";
import { increment } from "../../../features/Counter";
import { confirmAlert } from 'react-confirm-alert';

/* CreatedBy: Mayur
CreatedOn: 10 Jan 2022
Description : Events List
*/
const AdminEvents = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [eventList, seteventList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [number, setNumber] = useState(0);
    const [eventtab, setEventTab] = useState(0);
    const [menutype, setMenutype] = useState(0);

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])


    useEffect(() => {
        loadevents();
        setMenutype("list")
    }, [offset, number]);




    const loadevents = () => {
        setIsLoaded(false);
        LoadList("cms/event/list").then(result => {
            if (result !== undefined) {
                if (result.data === null) {
                    seteventList([])
                }
                else {
                    const data = result.data;
                    const slice = data.slice(offset, offset + perPage);
                    if (data.length > 0) {
                        seteventList(slice);
                        setPageCount(Math.ceil(data.length / perPage));
                    }
                }
            }
            else {
                seteventList([])
            }
            setIsLoaded(true);
        }).catch();
    }

    function FeatureEvent(eventid, status) {
        EventAction(eventid, status).then(res => {
            if (res !== undefined) {
                toast.success(res.data.message)
                loadevents();
            }
        })

        setNumber(number + 1);
    }

    function ChangeStatus(eventid, status) {
        var updatestatus = status ? "Deactivate" : "Activate"
        EventAction(eventid, updatestatus).then(res => {
            if (res !== undefined) {
                toast.success(res.data.message)
                loadevents();
            }
        })
        setNumber(number + 1);
    }


    const loadEventDetails = (itemid, eventTitle) => {
        localStorage.setItem("subtab", "eventdetails")
        localStorage.setItem("screentype", "edit");
        localStorage.setItem("EventName", eventTitle);
        localStorage.setItem("selectedeventid", itemid);
        dispatch(increment())
        navigate("/admin/event-add");
    }


    function Delete(eventid) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this speaker?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        EventAction(eventid, "delete").then(res => {
                            if (res !== undefined) {
                                toast.success(res.data.message)
                            }
                        })
                        loadevents();
                        setNumber(number + 1);
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadevents()
                }
            ]
        });
    }


    return (
        <>
            <div className="wrapper">
                <div className="content-wrapper cms">
                    <section className="content-header">
                        <h1>Event List</h1>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box box-primary">
                                    <div className="box-header with-border no-padding">
                                        <Tabs
                                            value={eventtab}
                                            textColor="primary"
                                            indicatorColor="primary"
                                            onChange={(event, newValue) => {
                                                setEventTab(newValue);
                                            }}
                                        >
                                            <Tab label="Active" style={{ fontSize: "14px" }} />
                                            <Tab label="Archived" style={{ fontSize: "14px" }} />
                                        </Tabs>
                                    </div>
                                    {eventtab === 0 ?
                                        <div className="box-body ">
                                            <div id="ActiveEvent" className=" ippftable">
                                                <div className="no-padding table-responsive">
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                {/* <th>S.No.</th> */}
                                                                {/* <th>Event ID</th> */}
                                                                <th>Title</th>
                                                                <th>Timings</th>
                                                                <th>Total registrations</th>
                                                                <th>Featured Event</th>
                                                                <th>Status</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                            {
                                                                isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            eventList.length === 0 ?
                                                                                <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                                :
                                                                                eventList.map((item, i) => (
                                                                                    (() => {
                                                                                        if (item.IsActive === true)
                                                                                            return <tr>
                                                                                                {/* <td>{(i + 1) + offset}</td> */}

                                                                                                <td className="titlelink" onClick={() => loadEventDetails(item._id, item.Title)}>{ReactHtmlParser(item.Title)}</td>
                                                                                                <td>{item.Timing}</td>
                                                                                                <td>{item.Registrations.length}</td>
                                                                                                <td><button onClick={(e) => FeatureEvent(item._id, "Feature")} className={item.FeatureEvent === true ? "btn btn-sm btn-info b-radius" : "btn btn-sm btn-danger b-radius "}>{item.FeatureEvent === true ? "True" : "False"}</button></td>
                                                                                                <td ><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className="btn btn-sm btn-info b-radius">{item.IsActive ? "Move to archive" : "Inactive"}</button></td>
                                                                                                <td><button className="btn-sm btn-danger b-radius" onClick={(e) => Delete(item._id)} >Delete</button></td>
                                                                                            </tr>
                                                                                    })()
                                                                                ))
                                                                        }
                                                                    </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="box-body ">
                                            <div id="ArchivedEvent" className=" ippftable">
                                                <div className="no-padding table-responsive">
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            <tr>

                                                                <th>Title</th>
                                                                <th>Timing</th>
                                                                <th>Total registration</th>
                                                                <th>Feature</th>
                                                                <th>Status</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                            {
                                                                isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            eventList.length === 0 ?
                                                                                <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                                :
                                                                                eventList.map((item, i) => (
                                                                                    (() => {
                                                                                        if (item.IsActive === false)
                                                                                            return <tr>
                                                                                                <td className="titlelink" onClick={(e) => loadEventDetails(item._id, item.Title)}>{ReactHtmlParser(item.Title)}</td>
                                                                                                <td>{item.Timing}</td>
                                                                                                <td>{item.Registrations.length}</td>
                                                                                                <td><button onClick={(e) => FeatureEvent(item._id, "Feature")} className={item.FeatureEvent === true ? "btn btn-sm btn-info b-radius" : "btn btn-sm btn-danger b-radius "}>{item.FeatureEvent === true ? "True" : "False"}</button></td>
                                                                                                <td ><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className="btn btn-sm btn-success b-radius">{item.IsActive ? "Move to archive" : "Move to active"}</button></td>
                                                                                                <td><button className="btn-sm btn-danger b-radius" onClick={(e) => Delete(item._id)} >Delete</button></td>
                                                                                            </tr>
                                                                                    })()
                                                                                ))
                                                                        }
                                                                    </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Outlet />
            </div>
        </>
    );

}
export default AdminEvents;