import React, { useEffect, useState } from "react"
import { useLocation, useParams } from 'react-router-dom';
import { LoadList } from "../../services/LoadList"
import { ShimmerEffect } from "../../styleUI/ShimmerEffect"
import { Footer } from "./commonSection/Footer"
import { Header } from "./commonSection/Header"
import { EventAgendaSection } from "./featureEvent/EventAgendaSection"
import { OrganizingTeam } from "./featureEvent/OrganizingTeam"
import { Section } from "./featureEvent/Section"
import { Speakers } from "./featureEvent/Speakers"
import { Sponsors } from "./featureEvent/Sponsors"

{/* CreatedBy: Mayur
 CreatedOn: 18 Jan 2022
 Description : About Archive Events
*/}

const ArchiveEventDetails = () => {
    const locationData = useParams();
    const [isLoaded, setIsLoaded] = useState(false)
    const [speakersData, setSpeakersData] = useState([])
    const [organizersData, setOrganizersData] = useState([])
    const [sessionsData, setSessionsData] = useState([])
    const [archiveEventsData, setArchiveEventsData] = useState([])
    const [activeEventsData, setActiveEventsData] = useState([])
    const [sponsersData, setSponsersData] = useState([])
    const [eventID, setEventID] = useState(locationData.id)

    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [eventDate, setEventDate] = useState("")
    const [aboutEvent, setAboutEvent] = useState("")
    const [location, setLocation] = useState("")
    const [headerLogo, setHeaderLogo] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [address, setAddress] = useState("")
    const [twitter, setTwitter] = useState("")
    const [facebook, setFacebook] = useState("")
    const [youtube, setYoutube] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [eventImage, setEventImage] = useState("")

    useEffect(() => {
        localStorage.setItem("PathName", locationData.id)
        if (locationData.id) {
            setIsLoaded(false)
            LoadList(`web/eventdetails?id=${locationData.id}`).then(result => {
                if (result.data) {
                    loadSepeakersList(locationData.id);
                    loadOrganizersList(locationData.id);
                    setHeaderLogo(result.data.SponsorLogo);
                    loadSessionsList(locationData.id);
                    loadSponsersList(locationData.id);
                    setTitle(result.data.Title)
                    setLocation(result.data.Location)
                    setEventDate(result.data.Timing)
                    setSubTitle(result.data.SubTitle)
                    setAboutEvent(result.data.AboutEvent)
                    setAddress(result.data.OfficeAddress)
                    setMobile(result.data.Mobile)
                    setEmail(result.data.Email)
                    setWebsite(result.data.LinkURL)
                    setTwitter(result.data.Twitter)
                    setFacebook(result.data.Facebook)
                    setYoutube(result.data.Youtube)
                    setLinkedin(result.data.Linkedin)
                    setEventImage(result.data.EventImage)
                } else {

                }
                setIsLoaded(true)
            })
        }
    }, [locationData.id])

    const loadSepeakersList = (eventID) => {
        LoadList(`web/speakers?eventid=${eventID}`).then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    setSpeakersData([])
                }
                else {
                    setSpeakersData(result.data)
                }
            } else {
                setSpeakersData([])
            }
            setIsLoaded(true);
        }).catch();
    }
    const loadOrganizersList = (eventID) => {
        LoadList(`web/organizers?eventid=${eventID}`).then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    setOrganizersData([])
                }
                else {
                    // setOrganizersData(result.data)
                    const filter = result.data.filter(e => e.IsActive === true)
                    setOrganizersData(filter)
                }
            } else {
                setOrganizersData([])
            }
            setIsLoaded(true);
        }).catch();
    }

    /* CreatedBy: Mayur
    CreatedOn: 05 Jan 2022
    Description :load Events 
    */
    const loadSessionsList = (eventID, name) => {
        LoadList(`web/eventdetails?id=${eventID}`).then(result => {

            if (result !== undefined) {
                if (!result.data) {
                    setSessionsData([])
                }
                else {
                    setSessionsData(result.data.Schedules)
                }
            } else {
                setSessionsData([])
            }
            setIsLoaded(true);
        }).catch();
    }
    const loadSponsersList = (eventID) => {
        LoadList(`cms/partner/list?eventid=${eventID}`).then(result => {
            if (result !== undefined) {
                if (!result.data) {
                    setSponsersData([])
                }
                else {
                    setSponsersData(result.data)
                }
            } else {
                setSponsersData([])
            }
            setIsLoaded(true);
        }).catch();

    };

    return (
        <div className="outerhomepage">
            <Header page="archive-event" headerLogo={headerLogo} />
            <div id="sectionField">
                <Section title={title} location={location} subTitle={subTitle} eventDate={eventDate} aboutEvent={aboutEvent} eventImage={eventImage} />
            </div>
            {isLoaded === false
                ?
                <ShimmerEffect type="bixWithUsers" text="Event Agenda" />
                :
                <EventAgendaSection loaded={isLoaded} data={sessionsData} />
            }

            {isLoaded === false
                ?
                <ShimmerEffect type="bixWithUsers" text="Meet our Conference Speakers" />
                :
                <Speakers data={speakersData} />
            }

            {isLoaded === false ?

                <ShimmerEffect type="bigBoxSponsers" text="Our Sponsors" />
                :
                <Sponsors isLoaded={isLoaded} data={sponsersData} />
            }
            {isLoaded === false ?

                <ShimmerEffect type="bixWithUsers" text="Organizing Team" />
                :
                <OrganizingTeam data={organizersData} />
            }
            <Footer type="archive-details" footerLogo={headerLogo} mobile={mobile} email={email} website={website} address={address} twitter={twitter} facebook={facebook} youtube={youtube} linkedin={linkedin} />
        </div>
    )
}

export default ArchiveEventDetails;

