import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { UploadFile } from '../../services/UploadFile';
import { CreateExhibition, UpdateExhibition, LoadExhibition } from '../../services/Event';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

/* CreatedBy: Mayur
CreatedOn: 10 Jan 2022
Description : Add Exhibition
*/
function AddExhibition() {
    const navigate = useNavigate();

    const [popuptype, setPopuptype] = useState(localStorage.getItem("popuptype"));
    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [selectedexhibitionid,setExhibitionid]=useState(localStorage.getItem("selectedexhibitionid"));
    const [errors, setError] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [video, setVideo] = useState("");
    const [boothimage, setBoothImage] = useState("");
    const [boothlink, setBoothLink] = useState("");
    const [boothemail, setBoothEmail] = useState("");
    const [boothfblink, setBoothFBLink] = useState("");
    const [isPopupLoaded, setIsPopupLoaded] = useState(false);


    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
                        toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
        
        if (popuptype === "createexhibition") {
            setIsPopupLoaded(true)
            clearFields()
        }
        else if(popuptype === "editexhibition") {
            loadexhibitiondetails()
            setIsPopupLoaded(true)
        }
       
    }, [popuptype]);

    const onDescription = (value) => {
        setDescription(value);
      } 

    const clearFields = () => {

        setTitle('');
        setDescription('');
        setImage('');
        setVideo('');
        setBoothEmail('');
        setBoothFBLink('');
        setBoothLink('');
        setBoothImage('');
        
        localStorage.setItem("popuptype", "createexhibition");
        localStorage.setItem('selectedexhibitionid', '');
    }



    const handleImage = (e) => {
        setIsPopupLoaded(false)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please Select Image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setImage(res.filepath)
                    setIsPopupLoaded(true)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const handleVideo = (e) => {
        setIsPopupLoaded(false)
        try {
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "mp4" || ext === "MP4") {

            }
            else {
                return toast.error('Please Select Video only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setVideo(res.filepath)
                    setIsPopupLoaded(true)
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const handleBoothImage = (e) => {
        setIsPopupLoaded(false)
        try {
            let multifile = [];
            const selectedFile = e.target.files;
            //for (let i = 0; i < selectedFile.length; i++) {
            const formData = new FormData();

            const ext = selectedFile[0].name.split('.').pop();
            if (ext === "png" || ext === "jpeg" || ext === "svg" || ext === "wpeg" || ext === "PNG" || ext === "JPEG" || ext === "JPG" || ext === "jpg" || ext === "SVG" || ext === "WPEG") {

            }
            else {
                return toast.error('Please Select Image only');
            }
            // Update the formData object 

            formData.append("filepath", selectedFile[0], selectedFile[0].name);
            const Request = {
                method: 'POST',
                headers: {},
                body: formData
            }
            UploadFile(Request).then(res => {
                if (res !== undefined) {
                    setBoothImage(res.filepath)
                    setIsPopupLoaded(true)
                }
            })
                .catch()
        } catch (error) {

        }
    }

   
    const loadexhibitiondetails = () => {
        LoadExhibition(selectedexhibitionid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    //setuserList([])
                }
                else
      
            
                setTitle(result.data.Title);
                setDescription(result.data.Description);
                setImage(result.data.Image);
                setVideo(result.data.Video);
                setBoothImage(result.data.BoothImage);
                setBoothLink(result.data.BoothLink);
                setBoothEmail(result.data.BoothEmail);
                setBoothFBLink(result.data.BoothFBLink);

            }
            else {
            }
        }).catch();

    }

    const SaveBtnClick = (e) => {
        if (title === "" || title === null) {
            return toast.error("Please enter title");
        }
        else {
            AddExhibition();
        }
    }

    const AddExhibition = async () => {

        try {

            var req = {};
            req = {
                eventid:selectedeventid,
                title: title,
                description: description,
                image: image,
                video: video,
                boothimage: boothimage,
                boothlink: boothlink,
                boothemail: boothemail,
                boothfblink: boothfblink
                
            }
    
            CreateExhibition(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    navigate("/admin/event-exhibitions");
        
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const UpdateBtnClick = (e) => {
        if (title === "" || title === null) {
            return toast.error("Please enter title");
        }
        else {
            UpdateEventClick();
        }
    }

    const UpdateEventClick = async () => {
        try {

            var req = {};
            req = {
                exhibitionid:selectedexhibitionid,
                title: title,
                description: description,
                image: image,
                video: video,
                boothimage: boothimage,
                boothlink: boothlink,
                boothemail: boothemail,
                boothfblink: boothfblink
            }
            UpdateExhibition(req).then(res => {
                if (res !== undefined) {
                    toast.success(res.data.message)
                    clearFields();
                    navigate("/admin/event-exhibitions");
     
                }
            })
                .catch()
        } catch (error) {

        }
    }

    const Cancelclick = (e) => {
        clearFields()
        navigate("/admin/event-exhibitions")
    }
    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">
                <div className="content-wrapper cms">
                    {/* <!-- Content Header (Page header) --> */}
                    <section className="content-header">
                        {popuptype==="editexhibition"?
                        <h1>Edit Exhibition Details</h1>:<h1>Add Exhibition Details</h1>}
                    </section>

                    {/* <!-- Main content --> */}
                    <section className="content">
                        <div ID="panelfiltergridview">
                            <div className="row" id="panelSearch">
                                <div className="col-md-12">
                                    <div className="box box-primary">
                                        <div className="box-header with-border">

                                        </div>
                                        <div className="form-horizontal">
                                            <div className="box-body">
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Title<span style={{ color: "red" }}>*</span></label>
                                                        <input type="text" id="txttitle" MaxLength="100" className="form-control" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12" style={{height:"320px"}}>
                                                    <div className="col-sm-12">
                                                        <label>Description<span style={{ color: "red" }}>*</span></label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={description}
                                                            onChange={onDescription}
                                                            placeholder={"Write something..."}
                                                            style={{height:"250px"}}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-6">
                                                        <label>Image</label>
                                                        <input type="file" id="fileeventimage" className="form-control" accept="image/*" onChange={(e) => handleImage(e)} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <img src={image} width="150px" />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-6">
                                                        <label> Video</label>
                                                        <input type="file" id="fileeventvideo" className="form-control" onChange={(e) => handleVideo(e)} />

                                                    </div>
                                                    <div className="col-sm-6">
                                                        {video !== "" ? <video width="150" height="100" controls>
                                                            <source src={video} type="video/mp4" /></video> : ""}
                                                    </div>
                                                </div>
                                               
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-6">
                                                        <label>Booth Image</label>
                                                        <input type="file" id="txtlogo" className="form-control" accept="image/*" onChange={(e) => handleBoothImage(e)} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <img src={boothimage} width="150px" />
                                                    </div>
                                                </div>
                                               
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Website</label>
                                                        <input type="text" id="linkurl" className="form-control" value={boothlink} onChange={(e) => setBoothLink(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Email</label>
                                                        <input type="text" id="linkurl" className="form-control" value={boothemail} onChange={(e) => setBoothEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="col-sm-12">
                                                        <label>Facebook URL</label>
                                                        <input type="text" id="linkurl" className="form-control" value={boothfblink} onChange={(e) => setBoothFBLink(e.target.value)} />
                                                    </div>
                                                </div>
                                               
                                                
                                            </div>
                                            <div className="box-footer">
                                                <div>
                                                {isPopupLoaded?<>{
                                                        popuptype === "createexhibition"
                                                            ?
                                                            <button onClick={SaveBtnClick} className="btn btn-primary b-radius pull-right">Save</button>
                                                            :
                                                            popuptype === "editexhibition"
                                                                ?
                                                                <button onClick={UpdateBtnClick} className="btn btn-primary b-radius pull-right">Update</button> :
                                                                ""
                                                            }</>:<div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>}
                                                   
                                                            <button onClick={Cancelclick} className="btn btn-danger b-radius "> Cancel</button> 
                                                       

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
export default AddExhibition;