import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { speakersApi } from '../features/LoadSepeakers'
import { sidebarSlice } from '../features/Counter'

export const Store = configureStore({
    reducer: {
        [speakersApi.reducerPath]: speakersApi.reducer,
        value: sidebarSlice.reducer
    },
    middleware: (getDefaultMiddlerware) =>
        getDefaultMiddlerware().concat(speakersApi.middleware),
})

setupListeners(Store.dispatch)