import React from 'react'
export const WelcomeSection = ({ aboutEvent, title }) => {
    const SecondEmergency = "2nd Emergency Medicine Online Masterclass 2020"
    const titleSecondEmergency = "Recorded video for Masterclass 2020:"
    const ThirdEmergency = "3rd Emergency Medicine ONLINE Clinical Masterclass"
    const titleThirdEmergency = "Recorded video for Masterclass 2021:"
    const sessionText = {
        session1: " (morning session)",
        session2: " (afternoon session)"
    }


    const VideoComponent = ({ title, link, link2, sessionText }) => {
        return (
            <>


                <div className='videoLinks'>
                    <a target="_blank" href={link}>{link}</a>
                    {sessionText &&
                        <h6>
                            {sessionText.session1}
                        </h6>
                    }
                </div>

                <div className='videoLinks'>
                    {link2 && <>
                        <a target="_blank" href={link2}>{link2}</a>
                        <h6>{sessionText.session2}</h6></>}
                </div>
                {/* <h4>Thank you for your support :)</h4> */}

            </>
        )
    }
    return (
        <div className="welcome-sec" >
            {/*Welcome*/}
            <div className="container">
                <div className="desc hidden-xxs" dangerouslySetInnerHTML={{ __html: aboutEvent }}></div>

                {title === SecondEmergency &&
                    <>
                        <div className='videoLinksMain'>
                            <h4>{title}</h4>

                            <VideoComponent title={titleSecondEmergency} link="https://vimeo.com/728089773/9f1bcc2a40﻿" />
                            {/* <VideoComponent title={titleSecondEmergency} link="https://vimeo.com/728089773/9f1bcc2a40﻿" /> */}
                            <h4>Thank you for your support :)</h4>
                        </div>
                    </>
                }
                {title === ThirdEmergency &&
                    <>
                        <div className='videoLinksMain'>
                            <h4>{title}</h4>
                            <VideoComponent title={titleThirdEmergency}
                                link="https://vimeo.com/731018249/92f7173e42"
                                link2="https://vimeo.com/731019342/88f4a5ed49"
                                sessionText={sessionText}
                            />
                            {/* <VideoComponent title={titleThirdEmergency}
                                link="https://vimeo.com/731018249/92f7173e42"
                                link2="https://vimeo.com/731019342/88f4a5ed49"
                                sessionText={sessionText}
                            /> */}
                            <h4>Thank you for your support :)</h4>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}