import { Link, useParams } from "react-router-dom";



const PageNotFound = () => {
    const { id } = useParams()
    useParams(() => {

    }, [])
    return (
        <div className="container pageNotFound">
            <div className="text-center" style={{ padding: "230px" }}>
                <h1>404 Page not found</h1>
                <p>This page does not exist.</p>
                <Link to="/" className="btn btn-success">Home</Link>
            </div>
        </div>
    )
}


export default PageNotFound;