export const accordianDataFaqData = [
    {
        id: 1,
        question: "About Singapore EM Sling?",
        answer: "It was formed by a couple of Emergency medicine physician buddies who decided to have a masterclass to exchange tips and tricks in clinical practices, not just to Singapore but international Emergency Medicine community as well. We have come to our 6th year now and transformed over time with more interesting activities."
    },
    {
        id: 2,
        question: "Is this Masterclass for me?",
        answer: "This Masterclass is specially designed for all healthcare workers and medical students who are interested or in active clinical practices in acute care settings locally and internationally."
    },
    {
        id: 3,
        question: "What will I learn?",
        answer: "This novel masterclass takes advantage of the direct sharing of experience by Emergency physicians or specialists who are expert in their fields and update of latest clinical practice. You can take the opportunity to explore and troubleshoot complications encountered during practice with these specialists. A diverse variety of themes within the scope of Emergency Medicine, with important take-home messages to glean will be shared."
    },
    {
        id: 4,
        question: "What is the duration of the Masterclass?",
        answer: "The Masterclass is an one-day event which aims to deliver hard-hitting material within the 30-minute session. There will be hands on session on Regional Blocks and FAST scan at the end of the Masterclass. [Video demonstration for virtual participants]"
    },
    {
        id: 5,
        question: "How is the Masterclass delivered?",
        answer: "This Masterclass is conducted via Hybrid. Participants joining in person will enjoy interactive session with our speakers and other participants, unlimited beverages, a wide range of food prepared, souvenirs etc. You can also enjoy the take a visit to booths during the break. Participants joining virtual session will enjoy virtual networking, online quizzes, prizes for online events. You will also be given 1 month to access the Masterclass materials."
    },
    {
        id: 6,
        question: "Can't attend in person? ",
        answer: "We offer virtual access, streamed live directly from the venue, so you can add your voice to the discussion. You will receive the invitation link to our virtual platform closer to the event.",
    },
    {
        id: 7,
        question: "How do I to register for the Masterclass? ",
        answer: <>
            <p>Click here to register  <a href="https://webstreamlive.com/live/ktph-021124-event/"> https://webstreamlive.com/live/ktph-021124-event/ </a>
            You will receive the payment methods after registration and receipt issued to your email after successful payment. Please allow us 3-5 working days to verify your payment.
            </p>
        </>,
    },
    {
        id: 8,
        question: "Am I eligible for a lower registration rate? ",
        answer: <>
            <span>Yes, we offer a lower rate at 60 SGD for
            </span>
            <ol style={{ listStyleType: 'none' }}>
                <li> (a) non-doctors e.g. nurses, medical students, allied health personnel etc, or</li>
                <li> (b) participants from developing countries</li>
            </ol>
           
        </>,
    },
    {
        id: 9,
        question: "Any early bird fee or discount for group booking?",
        answer: "Unfortunately, we do not provide early bird fee or discount for group booking.",
    },
    {
        id: 10,
        question: "When is the deadline for registration?",
        answer: <>
          18 October 2023, 6pm
        </>,
    },
    {
        id: 10,
        question: "I did not receive any email after registration or payment?",
        answer: <p>
            Kindly allow us 3-5 working days for verification. Please look up for our email{" "}
            <a href="mailto:singaporeemsling@gmail.com">singaporeemsling@gmail.com</a> (general enquires or event updates) or
            <a href="mailto:mohammedrashidin.rahimah@ktph.com.sg">{" "}mohammedrashidin.rahimah@ktph.com.sg</a>
            (payment) in your junk mail. Mark our
            email as Safe so it goes to your inbox.
        </p>,
    },
    {
        id: 11,
        question: "I am employed under MOH Singapore, can I use personal training fund (PTF) to claim the registration fee?",
        answer: "Yes. Please refer to the terms and conditions in MOH policy."
        ,
    },
    {
        id: 12,
        question: "Any Continuing Medical Education (CME) / Continuing Professional Education (CPE) points?",
        answer: "Yes, CME/CPE points will be accredited after attending the event.",
    },
   
    {
        id: 13,
        question: "Will I receive a certificate after attending the Masterclass?",
        answer: "Participants will be awarded an e-certificate of completion upon successful completion of the Masterclass.",
    },
    {
        id: 14,
        question: "Help guides?",
        answer: <p>
            For any general enquiries, please contact us at <a href="mailto:singaporeemsling@gmail.com" >singaporeemsling@gmail.com </a>
        </p>,
    }
]