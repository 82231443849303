import { createSlice } from '@reduxjs/toolkit'

const initialStateValue = {
  sidebar: 0,
}

export const sidebarSlice = createSlice({
  name: 'value',
  initialState: initialStateValue,
  reducers: {
    increment: (state) => {
      state.sidebar = 1
    },
    decrement: (state) => {
      state.sidebar = 0
    }
    
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement } = sidebarSlice.actions

export default sidebarSlice.reducer