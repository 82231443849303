import { toast } from 'react-toastify';
import axios from 'axios';
import { Navigate } from 'react-router-dom'

const API_Base_Url = process.env.REACT_APP_API_BASE_URL;

export const Loginfunc = async (email, pass, LoginButtonRef, SetIsLoadingBtn) => {
    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const data = {
            "email": email,
            "password": pass
        };


        const response = await axios.post(`${API_Base_Url}cms/login`, data, headers).then((response) => {
            return response
        })
            .catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.Error.ErrorMessage !== undefined) {
                        toast.error(error.response.data.Error.ErrorMessage)
                        LoginButtonRef.current.disabled = false;
                        SetIsLoadingBtn(false)
                        return false;
                    }
                }
                if (error.response.status === 404) {
                    if (error.response.data.Error.ErrorMessage !== undefined) {
                        toast.error(error.response.data.Error.ErrorMessage)
                        LoginButtonRef.current.disabled = false;
                        SetIsLoadingBtn(false)
                        return false;
                    }
                }
                if (error.response.status === 500) {
                    if (error.response.data.Error.ErrorMessage !== undefined) {
                        toast.error(error.response.data.Error.ErrorMessage)
                        LoginButtonRef.current.disabled = false;
                        SetIsLoadingBtn(false)
                        return false;
                    }
                }
            });
        return response.data
    } catch (error) {
        LoginButtonRef.current.disabled = false;
        SetIsLoadingBtn(false)
        return toast.error('Something went wrong , Please check your internet connection.')
    }

};
export const forgotPassService = async (email) => {
    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const data = {
            "email": email,
        };

        const response = await axios.post(`${API_Base_Url}cms/admin-forgot`, data, headers).then((response) => {
            return response
        }
        ).catch(error => {
            if (error.response.status === 400) {
                if (error.response.data.Error.ErrorMessage !== undefined) {
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
            if (error.response.status === 404) {
       
                if (error.response.statusText !== undefined) {
                 
                    return toast.error("Url not found")
                }
            }
            if (error.response.status === 500) {
                if (error.response.data.Error.ErrorMessage !== undefined) {
                    return toast.error(error.response.data.Error.ErrorMessage)
                }
            }
        });
        return response.data
    } catch (error) {
        return toast.error('Something went wrong , Please check your internet connection.')
    }
};

export const ChangePasswordApi = async (userid, password, oldpassword) => {
    try {
        const response = await fetch(API_Base_Url + "cms/changepassword", {
            // const response = await fetch("http://localhost:3006/api/cms/changepassword", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + localStorage.getItem("AccessToken")
            },
            body: JSON.stringify({
                "userid": userid,
                "password": password,
                "oldpassword": oldpassword
            })
        })
        const result = await response.json();
        if (response.ok) {
            return result;
        }
        else if (response.status === 400) {
            if (!result.status) {
                toast.error(result.Error.ErrorMessage)
            }
        }
        else if (response.status === 404) {
            if (!result.status) {
                toast.error(result.Error.ErrorMessage)
            }
        }
        else {
        }
    } catch (error) {
        toast.error('Something went wrong , Please try again later.')
    }

};
