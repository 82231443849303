import React, { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { PartnerList, LoadPartner, PartnerAction, DeleteDate } from '../../services/Event';
import Partnerform from './popup/AddPartner'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../features/Counter";


/* CreatedBy: Mayur
CreatedOn: 10 Jan 2022
Description : Partners 
*/
function Partners() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedeventid, setEventid] = useState(localStorage.getItem("selectedeventid"));
    const [selectedpartnerid, setPartnerid] = useState("");
    const [popuptype, setPopuptype] = useState("");
    const [partnerList, setPartnerList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [number, setNumber] = useState(0);
    const eventName = localStorage.getItem("EventName")

    useEffect(() => {
        const userLoggedInInformation = localStorage.getItem("AccessToken")
        if (!userLoggedInInformation || userLoggedInInformation === "") {
            toast.error("Your session has been expired ...kindly login again");
            navigate('/admin-login');
        }
    }, [])

    useEffect(() => {
        loadpartners();
    }, [offset, number]);


    const openpartnerform = () => {
        setPopuptype("createpartner");
        window.$("#partnerformAdd").modal('show');
    }



    const loadpartners = () => {
        setIsLoaded(false);
        PartnerList(selectedeventid).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setPartnerList([])
                }
                else {
                    const data = result.data;

                    if (data.length > 0) {
                        setPartnerList(result.data);
                    }
                }
            }
            else {
                setPartnerList([])
            }
            setIsLoaded(true);
        }).catch();
    }

    function ChangeStatus(partnerid, status) {
        var updatestatus = status ? "Deactivate" : "Activate"
        const req = {
            partnerid: partnerid,
            action: updatestatus

        };
        PartnerAction(req).then(res => {
            if (res !== undefined) {
                toast.success(res.message)
            }
        })
        loadpartners();
        setNumber(number + 1);
    }

    function Delete(partnerid) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure to delete this sponsor?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        const req = {
                            partnerid: partnerid,
                            action: "Delete"

                        };
                        PartnerAction(req).then(res => {
                            if (res !== undefined) {
                                toast.success("Sponsor successfully deleted")
                                loadpartners();
                            }
                        }).catch()
                        const timer = setTimeout(() => {
                            loadpartners();
                            setNumber(number + 1);
                        }, 300);
                        return () => clearTimeout(timer);
                    }
                },
                {
                    label: 'No',
                    onClick: () => loadpartners()
                }
            ]
        });
    }

    const loadPartnerDetails = (partnerid) => {
        setPartnerid(partnerid)
        setPopuptype("editpartner");
        window.$("#partnerformUpdate").modal('show');
    }

    const Cancelclick = (e) => {
        navigate("/admin-events")
        localStorage.setItem("screentype", "create");
        dispatch(decrement())
    }

    const gotoEventDetails = (e) => {
        navigate("/admin/event-add")
    }

    return (
        <div className="hold-transition skin-blue sidebar-mini">
            <div className="wrapper">

                <div className="content-wrapper cms">
                    <section className="content-header">
                        {/* <h1>Sponsors List</h1>         */}
                        <ol class="breadcrumbCustom">
                            <li><button onClick={Cancelclick}><i class="fa fa-dashboard"></i>Event List</button></li>
                            <li><button onClick={gotoEventDetails}>{eventName} </button></li>
                            <li><button >Sponsors</button></li>
                        </ol>                 
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="box box-primary">
                            <div className="box-header with-border">
                            <h3 class="box-title">Sponsors List</h3>
                            <button className="btn btn-sm btn-primary pull-right" onClick={openpartnerform}><i className="fa fa-plus"></i> Add New</button>
                            </div>
                            <div className="box-body">
                                <div id="partners" className=" ippftable">
                                    <div className=" no-padding table-responsive">
                                        <table className="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Photo</th>
                                                   
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                {
                                                    isLoaded === false ? <div className="loaderAdmin"><img src="images/loader.gif" alt="" /></div>
                                                        :
                                                        <>
                                                            {
                                                                partnerList.length === 0 ?
                                                                    <tr><td colSpan='5'>No Record Found.</td></tr>
                                                                    :
                                                                    partnerList.map((item, i) => (
                                                                        <tr>
                                                                            <td className="titlelink" onClick={(e) => loadPartnerDetails(item._id)}>{item.Name}</td>
                                                                            <td> <img width="100px" src={item.Photo}/></td>
                                                                            {/* <td>{item.URL}</td> */}
                                                                            {/* <td></td> */}
                                                                            <td><button onClick={(e) => ChangeStatus(item._id, item.IsActive)} className={item.IsActive ? "btn btn-xs btn-info b-radius" : "btn btn-xs btn-success b-radius"}>{item.IsActive ? "Active" : "Inactive"}</button></td>
                                                                            <td><button onClick={(e) => Delete(item._id)} className="btn btn-xs btn-danger">Delete</button></td>
                                                                        </tr>

                                                                    ))
                                                            }
                                                        </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                </div>
                            </div>

                            </div>

                        </div>

                    </section>
                    {/* <Footer total={speakerList.length} setOffset={setOffset} perpage={perPage} pageCount={pageCount} /> */}
                </div>

            </div>

            <div id="partnerformAdd" className="modal fade" style={{ height: "100%" }}>
                <Partnerform eventid={selectedeventid} popuptype={popuptype} partnerid={selectedpartnerid} loadpartners={loadpartners} />
            </div>
            <div id="partnerformUpdate" className="modal fade" style={{ height: "100%" }}>
                <Partnerform eventid={selectedeventid} popuptype={popuptype} partnerid={selectedpartnerid} loadpartners={loadpartners} />
            </div>
        </div>


    );

}
export default Partners;