import React, { useEffect, useRef, useState } from "react";
import { LoaderSmall, AdminLogin, RegistrationPage, AdminEvents } from "../components"
import AddEvent from "../components/pages/admin/AddEvent";
import Schedule from "../components/pages/admin/Schedule";
import SpeakersAdmin from "../components/pages/admin/Speakers";
import Partners from "../components/pages/admin/Partners";
import AdminExhibitions from "../components/pages/admin/Exhibition";
import AddExhibition from "../components/pages/admin/AddExhibition";
import SideEvents from "../components/pages/admin/SideEvents";
import Registration from "../components/pages/admin/Registration";
import ChangePassword from "../components/pages/admin/ChangePassword";
import OrganizersTeamMember from "../components/pages/admin/OrganizersTeamMember";
import AboutUs from "../components/pages/public/About/AboutUs";
import ContactUs from "../components/pages/public/ContactUs";
import ArchiveEvents from "../components/pages/public/ArchiveEvents";
import ArchiveEventDetails from "../components/pages/public/ArchiveEventDetails";
import ContactUsAdmin from "../components/pages/admin/ContactUs";
import AdminPaymentsList from "../components/pages/admin/PaymentsList";
import SuccesPage from "../components/pages/public/commonSection/Success";
import HomePage from "../components/pages/public/homepage/HomePage";
import FeatureEvent from "../components/pages/public/featureEvent/FeatureEvent";
import IdleTimer from "react-idle-timer";
import { Navigate, Route, Routes, useLocation, Redirect, useParams } from 'react-router-dom';
import PageNotFound from "../components/pages/PageNotFound";
import AdminTopBar from "../components/pages/admin/navBar/Topbar";
import AdminSideBar from "../components/pages/admin/navBar/Sidebar";
import TermsandConditions from "../components/pages/public/registration/TermsandConditions";
import NewsList from "../components/pages/admin/news/List";
import Newsform from "../components/pages/admin/news/Add";
import GalleryList from "../components/pages/admin/gallery/Gallery";
import NewsViewAll from "../components/pages/public/AllNewsList";
import FeedbackAdmin from "../components/pages/admin/Feedback";
import OrganizersListFunc from "../components/pages/admin/organizers/List";
import { PaidPaymentPage } from "../components/pages/public/registration/payment/PaidPaymentPage";

export const AllRouters = () => {
    const location = useLocation()
    const [loaded, setLoaded] = useState(false);
    const idelTimerRef = useRef(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)

    const onIdle = () => {
        localStorage.setItem("AccessToken", '')
        localStorage.setItem("selectedeventid", '')
        localStorage.setItem("popuptype", '')
        localStorage.setItem("userid", '')
        localStorage.setItem("usertype", '')
        localStorage.setItem("email", '')
        localStorage.setItem("username", '')
        localStorage.setItem("phone", '')
        localStorage.setItem("landpage", '')
        localStorage.setItem("subtab", '')
    }

    useEffect(() => {
        if (localStorage.getItem("AccessToken"))
            setIsUserLoggedIn(true)
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loaded === false ? <LoaderSmall />
                :
                <IdleTimer ref={idelTimerRef} timeout={5 * 300000} onIdle={onIdle}>
                    <>
                        {/* Public Route */}
                        <Routes>
                            <Route exact path="/" element={<Navigate to="/home" />} />
                            <Route exact path="/home" element={<HomePage />} />
                            <Route exact path="/feature-event" element={<FeatureEvent />} />
                            <Route exact path="/archive-events" element={<ArchiveEvents />} />
                            <Route exact path="/event/:id" element={<ArchiveEventDetails />} />
                            <Route exact path="/newslist" element={<NewsViewAll />} />
                            <Route exact path="/registration" element={<RegistrationPage />} />
                            <Route exact path="/about" element={<AboutUs />} />
                            <Route exact path="/contact" element={<ContactUs />} />
                            <Route exact path="/events" element={<AdminEvents />} />
                            <Route exact path="/addevents" element={<AddEvent />} />
                            <Route exact path="/success" element={<SuccesPage />} />
                            <Route exact path="/pay/:encryptedToken" element={<PaidPaymentPage />} />
                            <Route exact path="/termsconditions" element={<TermsandConditions />} />
                            <Route exact path="/admin-login" element={<AdminLogin />} />
                            <Route exact path="/admin-events"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><AdminEvents /></div>}

                            />
                            <Route exact path="/admin/event-add"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><AddEvent /></div>}

                            />
                            <Route exact path="/admin/event-schedule"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><Schedule /></div>}

                            />
                            <Route exact path="/admin/event-speakers"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><SpeakersAdmin /></div>}

                            />
                            <Route exact path="/admin/event-partners"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><Partners /></div>}

                            />
                            <Route exact path="/admin/event-exhibitions"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><AdminExhibitions /></div>}

                            />
                            <Route exact path="/admin/exhibition-add"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><AddExhibition /></div>}

                            />
                            <Route exact path="/admin/side-events"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><SideEvents /></div>}

                            />
                            <Route exact path="/admin/event-organizer"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><OrganizersTeamMember /></div>}

                            />
                            <Route exact path="/admin/event-registration"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><Registration /></div>}

                            />
                            <Route exact path="/admin/changepassword"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><ChangePassword /></div>}

                            />
                            <Route exact path="/admin/events-contacts"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><ContactUsAdmin /></div>}

                            />
                            <Route exact path="/admin/events-payments"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><AdminPaymentsList /></div>}

                            />

                            <Route exact path="/admin/events-news"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><NewsList /></div>}
                            />
                            <Route exact path="/admin/events-gallery"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><GalleryList /></div>}
                            />
                            <Route exact path="/admin/events-feedback"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><FeedbackAdmin /></div>}
                            />
                            <Route exact path="/admin/events-organizers"
                                element={<div className="hold-transition skin-blue sidebar-mini"><AdminTopBar /><AdminSideBar /><OrganizersListFunc /></div>}
                            />


                            <Route path="*" exact={true} element={<PageNotFound />} />

                        </Routes>
                    </>
                </IdleTimer>
            }
        </>
    );

}

