import { Footer } from "../commonSection/Footer"
import { Header } from "../commonSection/Header"
import { Banner } from "../registration/Banner"
import { useEffect } from "react"
import WelcomeSection from "./WelcomeSection"
import VisionMission from "./VisionMissionCard"
import { useState } from "react"
import { LoadList } from "../../../services/LoadList"
import  SEO from "../commonSection/seo"


const AboutUs = () => {
    const [name, setName] = useState('')
    const [designation, setdesignation] = useState('')
    const [details, setdetails] = useState('')
    const [image, setimage] = useState('')
    const [Isloaded, setIsLoaded] = useState(false)
    const [organizers, setOrganizersList] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        loadOrganizer()
    }, [])
    const showDeails = (image, name, designation, details) => {
        setName(name)
        setdesignation(designation)
        setdetails(details)
        setimage(image)
        window.$("#BoardMemberDetails").modal('show');
    }



    const loadOrganizer = () => {
        setIsLoaded(false);
        const url = "organizer/list"
        LoadList(url).then(res => {
            const result = res;
            if (result !== undefined) {
                if (result.data === null) {
                    setOrganizersList([])
                }
                else {
                    const data = result.data;
                    if (data) {
                        if (data.length > 0) {
                            setOrganizersList(data.filter(item => item.IsActive === true));
                        } else {
                            setOrganizersList(data);
                        }
                    }
                }
            }
            else {
                setOrganizersList([])
            }
            setIsLoaded(true);
        }).catch();

    }

    return (
        <>
        <SEO
        title='About EM Sling - Shaping the Future of Emergency Medicine'
        description='Singapore EM Sling was formed by a couple of Emergency medicine physicians ,over a meal and a few drinks.'
        keywords='Emergency medical Upskilling with networking'/>

            <div className="outerhomepage">
                <Header />
                <Banner bannerName="About" />

                <WelcomeSection />

                <section class="padding100 section-bg">
                    <div class="container">
                        <VisionMission />
                    </div>
                </section>

                {/* Members of the Board */}
                <section className="boardmember padding100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title text-center">
                                    <h2>Organizers</h2>
                                </div>
                            </div>
                        </div>
                        <div className="insight-list">
                            <div className="row">
                                {
                                    organizers.length === 0 ? <h3>No organizers found</h3>
                                        :
                                        organizers.map(item => (
                                            <div onClick={() => showDeails(item.Image, item.Title, item.SubTitle, item.Details)}
                                                style={{ cursor: "pointer" }}
                                                className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="insight-box">
                                                    <div className="widget-imgbox">
                                                        <img src={item.Image} width="100%" />
                                                    </div>
                                                    <div className="widget-textbox text-center">
                                                        <h4 className="widget-title">{item.Title}</h4>
                                                        <p>{item.SubTitle}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))

                                }



                            </div>
                        </div>
                    </div>
                </section>


                <div className="speaker_disc_model">
                    <div className="modal fade modal-fullscreen  footer-to-bottom" id="BoardMemberDetails" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div className="modal-body">
                                    <div className="container">
                                        <div className="row">
                                            <div className="secondary_section">
                                                <div className=" col-md-4">
                                                    <div className="profile_single_photo_About">
                                                        <img style={{ borderRadius: "unset" }} src={image} alt="organizer" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="newspostdetail">
                                                        <div className="xbold">
                                                            <h1 className="post_title">{name}</h1>
                                                            <p className="post_subtitle">{designation}</p>
                                                        </div>
                                                        <div className="post_desc" dangerouslySetInnerHTML={{ __html: details }}>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Footer />
            </div >

        </>
    )
}

export default AboutUs;



